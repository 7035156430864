import { useState } from "react";
import styles from "./style.module.css";
import { ReactComponent as LeftArrow } from "../../../assets/leftredarrow.svg";
import { ReactComponent as RightArrow } from "../../../assets/rightredarrow.svg";
import { Description, Title } from "../../index";
import { Link } from "react-router-dom";

const DataPagesBox = ({ children, data, className, ...props }) => {
  const [superAdmin, setSuperAdmin] = useState(true); //Fake Data

  const [selectedBusinessIndex, setSelectedBusinessIndex] = useState(0);

  const handleLeftArrowClick = () => {
    setSelectedBusinessIndex((selectedBusinessIndex - 1 + data.length) % data.length);
  };

  const handleRightArrowClick = () => {
    setSelectedBusinessIndex((selectedBusinessIndex + 1) % data.length);
  };

  return (
    <div className={[styles.box, className].join(" ")} {...props}>
      {superAdmin && (
        <div className={styles.titlearea}>
          <LeftArrow onClick={handleLeftArrowClick} className={[styles.arrow, "cursor-pointer"].join(" ")} />
          <Title className="text-[34px] font-[400] text-[#212529] text-center mb-0 max-xss:text-[24px]">
            {data[selectedBusinessIndex]?.name}
          </Title>
          <RightArrow onClick={handleRightArrowClick} className={[styles.arrow, "cursor-pointer"].join(" ")} />
        </div>
      )}

      {!superAdmin && (
        <div className={styles.titlearea1}>
          <Title className="text-[34px] font-[400] text-[#212529] text-center mb-0 max-xss:text-[24px]">{data?.name}</Title>
        </div>
      )}

      <div className="w-full flex flex-row flex-wrap items-center justify-center gap-5">
        <Link
          className={styles.buttonbox}
          to={`/admin/personel-veritabanı/${data.id ? data?.id : data[selectedBusinessIndex]?.id}`}
        >
          <Description>Personel Veritabanı</Description>
          <Link
            className={styles.button}
            to={`/admin/personel-veritabanı/${data.id ? data?.id : data[selectedBusinessIndex]?.id}`}
          >
            Seç
          </Link>
        </Link>
        <Link className={styles.buttonbox} to={`/admin/personel-ekle/${data.id ? data?.id : data[selectedBusinessIndex]?.id}`}>
          <Description>Personel Ekle</Description>
          <Link className={styles.button} to={`/admin/personel-ekle/${data.id ? data?.id : data[selectedBusinessIndex]?.id}`}>
            Seç
          </Link>
        </Link>
        <Link
          className={styles.buttonbox}
          to={`/admin/magaza-veritabanı/${data.id ? data?.id : data[selectedBusinessIndex]?.id}`}
        >
          <Description>İşletme Veritabanı</Description>
          <Link className={styles.button} to={`/admin/magaza-veritabanı/${data.id ? data?.id : data[selectedBusinessIndex]?.id}`}>
            Seç
          </Link>
        </Link>
        <Link
          className={styles.buttonbox}
          to={`/admin/teknik-kontrol-anketi/${data.id ? data?.id : data[selectedBusinessIndex]?.id}`}
        >
          <Description>İşletme Teknik Kontroller</Description>
          <Link
            className={styles.button}
            to={`/admin/teknik-kontrol-anketi/${data.id ? data?.id : data[selectedBusinessIndex]?.id}`}
          >
            Seç
          </Link>
        </Link>
        <Link className={styles.buttonbox} to={`/admin/agenta-ekle/${data.id ? data?.id : data[selectedBusinessIndex]?.id}`}>
          <Description>Agenta İşlemleri</Description>
          <Link className={styles.button} to={`/admin/agenta-ekle/${data.id ? data?.id : data[selectedBusinessIndex]?.id}`}>
            Seç
          </Link>
        </Link>
        <Link className={styles.buttonbox} to={`/admin/ucretsiz-kampanya-kodu-olustur/`}>
          <Description>Ücretsiz Kampanya Kodu Oluştur</Description>
          <Link className={styles.button} to={`/admin/ucretsiz-kampanya-kodu-olustur/`}>
            Seç
          </Link>
        </Link>
        <Link className={styles.buttonbox} to={`/admin/toplam-ciro/${data.id ? data?.id : data[selectedBusinessIndex]?.id}`}>
          <Description>Seçili Şubedeki Toplam Ciro</Description>
          <Link className={styles.button} to={`/admin/toplam-ciro/${data.id ? data?.id : data[selectedBusinessIndex]?.id}`}>
            Seç
          </Link>
        </Link>
      </div>
    </div>
  );
};

export default DataPagesBox;
