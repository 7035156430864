import styles from "./style.module.css";
import edit from "../../../assets/edit.svg";
import AuthInput from "../AuthInput";
import { Link } from "react-router-dom";

const AuthInputwithEditLink = ({ className, link, ...props }) => {
  return (
    <div className={[styles.authinputwithlink, className].join(" ")}>
      <AuthInput className={styles.authInput} placeholder="" {...props} />

      <Link to={link}>
        <img src={edit} className="absolute xss:-right-10 right-4 top-3"></img>
      </Link>
    </div>
  );
};

export default AuthInputwithEditLink;
