import { useState, useEffect } from "react";
import styles from "./style.module.css";
import okkologo from "../../../assets/okkologo.svg";
import userlogo from "../../../assets/userlogo.svg";
import menu from "../../../assets/menu.svg";
import logo1 from "../../../assets/okkologo.svg";
import {
  Header,
  Title,
  Description,
  BuyTicketUpperBox,
  TechnicalControlsBox,
  Container,
  TechnicianDatabaseTable,
} from "../../../components";
import { getServerErrorMessage } from "../../../helpers/auth";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { API_URL } from "../../../utils/url";
import { Link } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

const PersonelHome = () => {
  const authAxiosClient = useAxiosClient();

  const [business, setBusiness] = useState(null);
  const [technicialControl, setTechnicialControl] = useState(null);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [searching, setSearching] = useState("all");
  const [technicalData, setTechnicialData] = useState([]);

  const { auth } = useAuth();

  useEffect(() => {
    getBranchDetail();
    getControls();
  }, []);

  useEffect(() => {
    getControls();
    if (searching == "") {
      setSearching("all");
    }
  }, [searching]);

  const getBranchDetail = async () => {
    try {
      const response = await authAxiosClient.get("/personel/get-branch-detail");
      setBusiness(response?.data?.data);
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  const getControls = async () => {
    try {
      const response = await authAxiosClient.get(`/personel/get_technical_control_branch/page-num=1&page-size=10&searching=${searching}`);

      setTechnicialControl(response?.data?.data?.branch_requirements);
      setTechnicialData(response?.data?.data?.all_requirements);
      setTotalPageCount(response?.data?.data?.total_page_count);
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  const getHandlePageChange = async (page) => {
    try {
      const response = await authAxiosClient.get(
        `/personel/get_technical_control_branch/page-num=${page}&page-size=10&searching=${searching}`
      );

      setTechnicialData(response?.data?.data?.all_requirements);
      setTotalPageCount(response?.data?.data?.total_page_count);
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
    }
  };

  // const getRoleName = () => {
  //   if (auth.role === "technical_support") return "Teknik Sorumlu";
  //   if (auth.role === "personel") return "Kasiyer";
  //   if (auth.role === "personel_lead") return "Personel Lideri";
  // };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/personel"} />
      <Container className={styles.container}>
        {auth.role !== "technical_support" && (
          <div className="flex flex-row flex-wrap justify-center gap-3 sm:gap-6 md:gap-8 w-full">
            {auth.role !== "personel_lead" && (
              <Link className={styles.routeBox} to={`/personel/randevu-olustur`}>
                <Description>Bilet Satış</Description>
                <Link className={styles.selectButton} to={`/personel/randevu-olustur/`}>
                  Seç
                </Link>
              </Link>
            )}

            <Link className={styles.routeBox} to={`/personel/pnr-sorgula/`}>
              <Description className="text-center">PNR & Randevu & Check-In</Description>
              <Link className={styles.selectButton} to={`/personel/pnr-sorgula`}>
                Seç
              </Link>
            </Link>

            {/* <Link className={styles.routeBox} to={`/personel/check-in`}>
              <Description>Check-in Yap</Description>
              <Link className={styles.selectButton} to={`/personel/check-in/`}>
                Seç
              </Link>
            </Link> */}

            <Link className={styles.routeBox} to={`/personel/kasa-kapat/`}>
              <Description>Kasa İcmali</Description>
              <Link className={styles.selectButton} to={`/personel/kasa-kapat/`}>
                Seç
              </Link>
            </Link>

            <Link className={[styles.routeBox, "mt-0"].join(" ")} to={`/personel/faaliyet-gecmisi/`}>
              <Description>İşlem Geçmişi</Description>
              <Link className={styles.selectButton} to={`/personel/faaliyet-gecmisi/`}>
                Seç
              </Link>
            </Link>

            <Link className={[styles.routeBox, "mt-0"].join(" ")} to={`/personel/aylik-ciro`}>
              <Description>Aylık Ciro Dökümü</Description>
              <Link className={styles.selectButton} to={`/personel/faaliyet-gecmisi/`}>
                Seç
              </Link>
            </Link>

            <Link className={[styles.routeBox, "mt-0"].join(" ")} to={`/personel/kamera-satislari`}>
              <Description>Kamera Satışları</Description>
              <Link className={styles.selectButton} to={`/personel/kamera-satislari/`}>
                Seç
              </Link>
            </Link>
          </div>
        )}

        {auth.role !== "personel" && (
          <>
            <Title className={styles.midTitle}>Teknik Kontroller</Title>
            {/* 
              <Description className={styles.desc}>
                Tamamlanmayan Teknik Kontroller
              </Description> 
            */}
            <div className="flex flex-col sm:flex-row gap-2 sm:gap-8 px-4">
              {technicialControl ? (
                <TechnicalControlsBox
                  className={[styles.technicalControlsBox, technicialControl?.status ? "bg-lightGreen" : ""].join(" ")}
                  children={technicialControl?.branch_name}
                  logo={technicialControl?.branch_image}
                  active={technicialControl?.status}
                  //isletmeId={technicialControl?.requirements_id}
                />
              ) : (
                <Description className={styles.desc}>Tamamlanmayan teknik kontrol bulunmamaktadır...</Description>
              )}
            </div>

            <TechnicianDatabaseTable
              searching={searching}
              setSearching={setSearching}
              getHandlePageClick={getHandlePageChange}
              totalPageCount={totalPageCount}
              className={styles.table}
              technicians={technicalData}
            />
          </>
        )}
      </Container>
    </div>
  );
};

export default PersonelHome;
