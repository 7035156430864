import { useState, useEffect } from "react";
import {
  Header,
  Container,
  Description,
  Title,
  BuyTicketUpperBox,
  Footer,
  AuthBox,
  AddBusinessesInput,
  AuthButton,
  AddPersonelBox,
  PersonelPermissionsCheckBox,
} from "../../../components/index";
import styles from "./style.module.css";
import okkologo from "../../../assets/okkoadminlogo.svg";
import userlogo from "../../../assets/userlogowhite.svg";
import menu from "../../../assets/menu.svg";
import logo1 from "../../../assets/okkologo.svg";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { errorMessage, successMessage } from "../../../helpers/toast";
import { API_URL } from "../../../utils/url";

const AddPersonel = () => {
  const { isletmeId } = useParams();
  const navigate = useNavigate();
  const axiosClient = useAxiosClient();

  const [isPersonel, setIsPersonel] = useState(false);
  const [isTecnicalSupport, setIsTecnicalSupport] = useState(false);
  const [isSalesRepresentative, setIsSalesRepresentative] = useState(false);
  const [businessData, setBusinessData] = useState({});

  const [usernameSelected, setUsernameSelected] = useState(false);
  const [username, setUsername] = useState();
  const [personelData, setPersonelData] = useState({
    id: 1,
    username: "",
    name: "",
    phone: "",
    gender: "",
    date: "",
    permissions: {
      technicalSupport: false,
      salesRepresentative: false,
      reception: false,
      cleaningStaff: false,
    },
    jobrole: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getBusinesses();
  }, []);

  const getBusinesses = async () => {
    try {
      const response = await axiosClient.get(`/superuser/get-branch/branch-id=${isletmeId}`);
      if (response.status === 200) {
        setBusinessData(response?.data?.data?.branch);
      }
    } catch (error) {
      errorMessage("İşletmeler getirilirken bir hata oluştu.");
    }
  };

  const addPersonelToBusiness = async () => {
    try {
      const response = await axiosClient.post(
        `/superuser/add-personel/branch-id=${isletmeId}`,
        JSON.stringify({
          username: username,
          is_personel: isSalesRepresentative, //kasa gorevlisi
          is_technical_support: isTecnicalSupport, //teknik destek personeli
          is_personel_lead: isPersonel, //personel sorumlusu
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        successMessage("Personel başarıyla eklendi.");
        navigate(`/admin`);
      }
    } catch (error) {
      errorMessage("Personel eklenirken bir hata oluştu.");
    }
  };

  const deletePersonelFromBusiness = async () => {
    try {
      const response = await axiosClient.post(
        "/superuser/delete-personel-from-branch",
        JSON.stringify({
          username: username,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        successMessage("Personel başarıyla silindi.");
        navigate(`/admin`);
      }
    } catch (error) {
      errorMessage("Personel silinirken bir hata oluştu.");
    }
  };

  const getPersonel = async () => {
    try {
      const response = await axiosClient.post(`/superuser/search-personel`, {
        username: username,
      });
      if (response.status === 200) {
        setPersonelData(response?.data?.data);
        setUsernameSelected(true);
        if (response?.data?.data?.user_type == "personel_lead") {
          setIsPersonel(true);
          setIsTecnicalSupport(false);
          setIsSalesRepresentative(false);
        } else if (response?.data?.data?.user_type == "technical_support") {
          setIsTecnicalSupport(true);
          setIsPersonel(false);
          setIsSalesRepresentative(false);
        } else if (response?.data?.data?.user_type == "personel") {
          setIsSalesRepresentative(true);
          setIsPersonel(false);
          setIsTecnicalSupport(false);
        }
      }
    } catch (error) {
      errorMessage("Personel bulunamadı.");
    }
  };

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      getPersonel();
    }
  };

  return (
    <div className="md:grid grid-rows-layoutAuth">
      <Header
        className="bg-primary"
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink={"/profil"}
        homeLink={"/admin"}
      />
      <Container className={styles.container}>
        {/* <Title className={styles.upperTitle}>Macera, Adrenalin, Mutluluk...</Title>
        <Description className={styles.description}>Eğlencenin merkezine hoş geldin!</Description> */}

        <Title className={[styles.upperTitle, "mb-6"].join(" ")}>{businessData?.name}</Title>

        {/* <BuyTicketUpperBox
          logo={logo1}
          photo={API_URL + businessData?.image}
          description={businessData?.description}
          imageText={businessData?.name}
          className="mb-20"
        /> */}

        <Title className={styles.midTitle}>Personel Ekleme</Title>

        <AuthBox className={styles.authBox1}>
          <Title className={styles.midTitle}>Kişi Bilgileri</Title>
          <Description className={styles.desc}>Sisteme kayıtlı kullanıcı adı:</Description>
          <AddBusinessesInput
            placeholder="Kullanıcı adı giriniz."
            className="w-full h-[45px] text-[16px]"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            onKeyPress={handleKeypress}
          />
          <AuthButton
            className={styles.button}
            clickButton={() => {
              getPersonel();
            }}
          >
            Ara
          </AuthButton>

          {usernameSelected && (
            <AddPersonelBox
              personelData={personelData}
              personelExist={personelData.branch_id === isletmeId}
              addPersonelClick={() => addPersonelToBusiness()}
              deletePersonelFromBusiness={() => deletePersonelFromBusiness()}
            />
          )}
        </AuthBox>

        {usernameSelected && (
          <>
            <Title className={styles.midTitle}>Yetkiler ve İzinler</Title>
            <AuthBox className={styles.authBox}>
              <PersonelPermissionsCheckBox
                data={{
                  technicalSupport: true,
                  salesRepresentative: false,
                  reception: false,
                  cleaningStaff: false,
                }}
                //isPersonel, setIsPersonel, isTecnicalSupport, setIsTecnicalSupport, isSalesRepresentative, setIsSalesRepresentative
                isPersonel={isPersonel}
                setIsPersonel={setIsPersonel}
                isTecnicalSupport={isTecnicalSupport}
                setIsTecnicalSupport={setIsTecnicalSupport}
                isSalesRepresentative={isSalesRepresentative}
                setIsSalesRepresentative={setIsSalesRepresentative}
              ></PersonelPermissionsCheckBox>

              <AuthButton
                className={styles.button}
                onClick={() => {
                  if (personelData.branch_id == isletmeId) {
                    deletePersonelFromBusiness();
                  } else {
                    addPersonelToBusiness();
                  }
                }}
              >
                {personelData.branch_id == isletmeId ? "Şubeden Çıkar" : "Şubeye Ekle"}
              </AuthButton>
            </AuthBox>{" "}
          </>
        )}
      </Container>

      <Footer />
    </div>
  );
};

export default AddPersonel;
