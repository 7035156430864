import {
  BgBox,
  AuthBox,
  Header,
  Logo,
  Container,
  Title,
  OtpInput,
  RegisterLink,
  ForgotLink,
  Footer,
} from "../../../../../components";
import styles from "./style.module.css";
import redsplash from "../../../../../assets/splash/redsplash.svg";
import bluesplash from "../../../../../assets/splash/bluesplash2.svg";
import okkologo from "../../../../../assets/okkologo.svg";
import userlogo from "../../../../../assets/userlogo.svg";
import menu from "../../../../../assets/menu.svg";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getServerErrorMessage } from "../../../../../helpers/auth";

const NewTelOtp = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const phone = searchParams.get("phone") || "";

  const controlOtpTel = async (otp) => {
    try {
      /*
      const response = await axiosClient.post(
        "/users/verify-phone-new",
        JSON.stringify({ phone, otp })
      );
      console.log("control otp: ", response);
      */
      navigate({
        pathname: "/profil/telefon-degistir/telefon-degistirildi",
        search: `?phone=${phone}`,
      });
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  const getTelCodeAgain = () => {};

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink="/profil"
        homeLink={"/"}
      />
      <Container className={styles.container}>
        <BgBox
          className={styles.bgBox}
          img1={bluesplash}
          img2={redsplash}
          img1Style="bottom-[13%] left-[52%] rotate-[240deg]"
          img2Style="bottom-[51%] right-[57%] "
          windowsize={900}
        >
          <AuthBox className={styles.authBox}>
            <Logo className={styles.logo} />
            <Title className={styles.title}>{t("verifyPhoneNumber")}</Title>
            <OtpInput
              type="tel"
              //linkText="Farklı telefon numarası ile kayıt ol."
              getCodeAgain={() => {
                getTelCodeAgain();
              }}
              controlOtp={(otp) => {
                controlOtpTel(otp);
              }}
            />
          </AuthBox>
          <RegisterLink link="Giriş Yap" to="/giris">
            {t("alreadyHaveAccount")}
          </RegisterLink>

          <ForgotLink to="/sifremi-unuttum">{t("forgotPassword")}</ForgotLink>
        </BgBox>
      </Container>
      <Footer />
    </div>
  );
};

export default NewTelOtp;
