import { useState, useEffect } from "react";
import styles from "./style.module.css";
import AuthInput from "../../Input/AuthInput";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { getServerErrorMessage } from "../../../helpers/auth";
import { API_URL } from "../../../utils/url";
import { useNavigate, useParams } from "react-router-dom";
import { PhotoView } from "react-photo-view";
import ExportPdf from "../../ExportPdf";

const BusinessRequirementsFormAdminReq = ({ children, answers, className, date, time, ...props }) => {
  const authAxiosClient = useAxiosClient();

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);
  const [requirements, setRequirements] = useState([]);

  const { isletmeId } = useParams();

  const navigation = useNavigate();

  useEffect(() => {
    const handleClick = (event) => {
      if (event.target.classList.contains("not")) {
        const clickedQuestionIndex = parseInt(event.target.getAttribute("data-index"));

        setActiveQuestionIndex(activeQuestionIndex === clickedQuestionIndex ? null : clickedQuestionIndex);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [activeQuestionIndex]);

  useEffect(() => {
    getRequirements();
  }, [isletmeId]);

  const getRequirements = async () => {
    try {
      const response = await authAxiosClient.get(`/superuser/get-requirements-for-branch/branch-id=${isletmeId}`);
      setRequirements(response?.data?.data);
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
    }
  };

  const checkControl = (articleObj) => {
    // if (isCheckedArray[id] === true) {
    //   return "bg-black";
    // } else {
    //   return "bg-white";
    // }

    if (articleObj.status === true) {
      return "bg-black";
    }
  };

  const checkBorderControl = (status) => {
    if (status === true) {
      return "border-green-400";
    } else {
      return "border-[#F80A3A]";
    }
  };

  const register = () => {};

  return (
    <div className={[styles.sssbox, className].join(" ")}>
      <ExportPdf
        className="ml-auto"
        fileName="teknik-kontrol-anketi.pdf"
        fileTitle="Teknik Kontrol Anketi"
        data={requirements.map((item) => [
          item.rule,
          item.status === true ? "Uygun" : "Uygun Değil",
          item.note == "null" ? "-" : item.note,
        ])}
        headData={[["Kural", "Durum", "Not"]]}
      />

      <div className="flex flex-col gap-4">
        {requirements?.map((requirement, index) => (
          <div
            key={index}
            className={`border rounded-xl fill-transparent-[5%]  text-[#959595] ${checkBorderControl(requirement?.status)} `}
          >
            <div
              className="question flex sm:flex-row flex-col border border-gray-400  rounded-xl  text-[24px] drop-shadow-md bg-white   py-3 px-3 cursor-pointer md:px-[72px]"
              data-index={index}
            >
              <AuthInput
                placeholder="İşletme kuralları, işletme gereksinimi,"
                className={"w-full flex max-w-full drop-shadow-xl mr-2 "}
                register={register}
                value={requirement?.rule}
                readOnly
                //readOnly={index >= requirements.length ? false : true}
              />
              <input type="file" accept="image/*" style={{ display: "none" }} id={`file${index}`} />

              <div>
                <PhotoView src={`${API_URL}${requirement?.image}`}>
                  <img className="w-[35px] h-[35px] m-2 mr-10" src={`${API_URL}${requirement?.image}`} alt="görsel" />
                </PhotoView>
              </div>

              <button
                className="not bg-white drop-shadow-xl rounded-xl m-2 px-4"
                data-index={index} // Add data-index attribute to identify the clicked question
              >
                Not
              </button>
              <div className="h-full pt-3 "></div>
              <div className="flex items-center gap-2 ml-4">
                <button
                  disabled
                  className={`py-1 px-2 w-20 text-sm rounded-full ${
                    requirements[index].priority == 1 ? "bg-red-500 text-white" : "bg-gray-200"
                  }`}
                >
                  Kırmızı Öncelik
                </button>

                <button
                  disabled
                  className={`py-1 px-2 w-20 text-sm rounded-full ${
                    requirements[index].priority == 2 ? "bg-yellow-500 text-white" : "bg-gray-200"
                  }`}
                >
                  Sarı Öncelik
                </button>
              </div>
            </div>

            {activeQuestionIndex === index && (
              <div className="flex sm:flex-row flex-col bg-white rounded-b-xl answer py-3 px-3 xss:px-[72px] text-[16px] ">
                <AuthInput
                  placeholder="Not."
                  className={"w-full flex max-w-full drop-shadow-xl mx-2 "}
                  register={register}
                  value={requirement?.note == "null" ? "" : requirement?.note}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BusinessRequirementsFormAdminReq;
