import styles from "./style.module.css";

const AddressMap = ({ location }) => {
  return (
    // <iframe
    //   src={location}
    //   width="100%"
    //   height="100%"
    //   style={{ border: 0, borderRadius: "40px" }}
    //   allowFullScreen
    //   loading="lazy"
    //   referrerPolicy="no-referrer-when-downgrade"
    // />
    <div className={styles.iframeContainer}>
      <iframe
        src={location}
        className={styles.responsiveIframe}
        // width="600"
        height="450"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default AddressMap;

{
  /* backend hata */
}
/*
https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1501.5013113236798!2d29.6156207!3d41.17811!3m2!
https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1501.5013113236798!2d29.6156207!3d41.17811!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x409e33b3c0a9eea9%3A0xc8f28dbd6960d58a!2sZippline%20%C5%9Eile%20Feneri!5e0!3m2!1str!2str!4v1691400766411!5m2!1str!2str
*/
