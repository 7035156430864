export const isUnder18 = (birthdate) => {
  // Parse the birthdate string to a Date object
  var dob = new Date(birthdate);

  // Get the current date
  var currentDate = new Date();

  // Calculate the age
  var age = currentDate.getFullYear() - dob.getFullYear();

  // Check if the birthday has occurred this year
  if (
    currentDate.getMonth() < dob.getMonth() ||
    (currentDate.getMonth() === dob.getMonth() &&
      currentDate.getDate() < dob.getDate())
  ) {
    age--;
  }

  // Check if the age is less than 18
  return age < 18;
};
