import { BgBox, AuthBox, Header, Logo, Container, Title, OtpInput, RegisterLink } from "../../../../components";
import styles from "./style.module.css";
import purplesplash from "../../../../assets/splash/purplesplash.svg";
import orangesplash from "../../../../assets/splash/orangesplash2.svg";
import okkologo from "../../../../assets/okkologo.svg";
import userlogo from "../../../../assets/userlogo.svg";
import menu from "../../../../assets/menu.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosClient } from "../../../../api/config";
import { successMessage } from "../../../../helpers/toast";

const ForgotPasswordOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const phone = searchParams.get("phone") || "";

  const controlOtpTel = async (otp) => {
    try {
      const response = await axiosClient.post("/users/forgot-password-verification", JSON.stringify({ phone, otp }));

      navigate({
        pathname: "/sifremi-unuttum/otp/sifremi-yenile",
        search: `?phone=${phone}`,
      });
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  const getTelCodeAgain = async () => {
    try {
      const response = await axiosClient.post("/users/forgot-password", JSON.stringify({ phone }));

      successMessage("Doğrulama kodu tekrar gönderildi");
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/"} />
      <Container className={styles.container}>
        <BgBox
          className={styles.bgBox}
          img1={orangesplash}
          img2={purplesplash}
          img1Style="bottom-[16%] left-[52%]"
          img2Style="bottom-[48%] right-[57%] "
          windowsize={768}
        >
          <AuthBox className={styles.authBox}>
            <Logo className={styles.logo} />
            <Title className={styles.title}>Mailinize gelen kodu giriniz.</Title>
            <OtpInput
              linkText=""
              getCodeAgain={() => {
                getTelCodeAgain();
              }}
              controlOtp={(otp) => {
                controlOtpTel(otp);
              }}
            />
          </AuthBox>
          <RegisterLink link="Giriş Yap" to="/giris">
            Zaten hesabım var?
          </RegisterLink>
          <RegisterLink link="Kayıt Ol!" to="/kayit-ol">
            Yeni misin?
          </RegisterLink>
        </BgBox>
      </Container>
    </div>
  );
};

export default ForgotPasswordOtp;
