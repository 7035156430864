import {
  Header,
  Container,
  Description,
  Title,
  BuyTicketUpperBox,
  Footer,
  AuthBox,
  AddBusinessesInput,
  AuthButton,
  AddPersonelBox,
  AddAgentaBox,
  PersonelPermissionsCheckBox,
} from "../../../components/index";
import styles from "./style.module.css";
import fakeImage1 from "../../../assets/fakeImages/photo1.png";
import okkologo from "../../../assets/okkoadminlogo.svg";
import userlogo from "../../../assets/userlogowhite.svg";
import menu from "../../../assets/menu.svg";
import logo1 from "../../../assets/okkologo.svg";
import photo1 from "../../../assets/fakeImages/photo4.png";
import { useState, useContext } from "react";
import StoreContext from "../../../context/store";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { errorMessage, successMessage } from "../../../helpers/toast";
import { useEffect } from "react";
import { API_URL } from "../../../utils/url";

const AddAgenta = () => {
  const { isletmeId } = useParams();

  const navigate = useNavigate();

  const axiosClient = useAxiosClient();

  const [businessData, setBusinessData] = useState({});
  const [usernameSelected, setUsernameSelected] = useState(false);
  const [username, setUsername] = useState();
  const [agentaName, setAgentaName] = useState();
  const [agentaData, setAgentaData] = useState({});
  const [discartRate, setDiscartRate] = useState();
  const [discartLimit, setDiscartLimit] = useState();
  const [personelExist, setPersonelExist] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAgenta = async () => {
    try {
      const response = await axiosClient.post(`/superuser/search-agenta`, {
        username: username,
      });
      if (response.status === 200) {
        setAgentaData(response?.data?.data);
        setAgentaName(response?.data?.data?.agenta_name);
        setDiscartRate(response?.data?.data?.discart_rate);
        setDiscartLimit(response?.data?.data?.discart_limit);
        setUsernameSelected(true);
        setPersonelExist(response?.data?.data?.agenta_name !== null);
      }
    } catch (error) {
      errorMessage("Agenta bulunurken bir hata oluştu.");
    }
  };

  const getBusinesses = async () => {
    try {
      const response = await axiosClient.get(`/superuser/get-branch/branch-id=${isletmeId}`);
      if (response.status === 200) {
        setBusinessData(response?.data?.data?.branch);
      }
    } catch (error) {
      errorMessage("İşletmeler getirilirken bir hata oluştu.");
    }
  };

  const addAgenta = async () => {
    try {
      const response = await axiosClient.post("/superuser/add-agenta", {
        username: username,
        agenta_name: agentaName,
        discart_rate: parseFloat(discartRate),
        discart_limit: parseInt(discartLimit),
      });
      if (response.status === 200) {
        successMessage("Agenta başarıyla eklendi.");
        navigate("/admin");
      }
    } catch (error) {
      errorMessage("Agenta eklenirken bir hata oluştu.");
    }
  };

  const deleteAgenta = async () => {
    try {
      const response = await axiosClient.post("/superuser/delete-agenta", {
        username: username,
      });
      if (response.status === 200) {
        successMessage("Agenta başarıyla silindi.");
        navigate("/admin");
      }
    } catch (error) {
      errorMessage("Agenta silinirken bir hata oluştu.");
    }
  };

  useEffect(() => {
    getBusinesses();
  }, []);

  return (
    <div className="md:grid grid-rows-layoutAuth">
      <Header
        className="bg-primary"
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink={"/profil"}
        homeLink={"/admin"}
      />
      <Container className={styles.container}>
        {/* <Title className={styles.upperTitle}>Macera, Adrenalin, Mutluluk...</Title>
        <Description className={styles.description}>Eğlencenin merkezine hoş geldin!</Description> */}

        <Title className={[styles.upperTitle, "mb-6"].join(" ")}>{businessData?.name}</Title>

        {/* <BuyTicketUpperBox
          logo={logo1}
          photo={API_URL + businessData?.image}
          description={businessData?.description}
          imageText={businessData?.name}
          className="mb-20"
        /> */}

        <Title className={styles.midTitle}>Agenta Ekleme</Title>

        <AuthBox className={styles.authBox1}>
          <Title className={styles.midTitle}>Hesap Bilgisi</Title>
          <Description className={styles.desc}>Sisteme kayıtlı kullanıcı adı:</Description>
          <AddBusinessesInput
            placeholder="Kullanıcı adı giriniz."
            className="w-full h-[45px] text-[16px]"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
          <AuthButton className={styles.button} clickButton={() => getAgenta()}>
            Ara
          </AuthButton>

          {usernameSelected && (
            <AddAgentaBox
              personelData={agentaData}
              personelExist={personelExist}
              addAgenta={addAgenta}
              deleteAgenta={deleteAgenta}
              agentaName={agentaName}
              setAgentaName={setAgentaName}
              discartRate={discartRate}
              setDiscartRate={setDiscartRate}
              discartLimit={discartLimit}
              setDiscartLimit={setDiscartLimit}
            />
          )}
        </AuthBox>
      </Container>

      <Footer />
    </div>
  );
};

export default AddAgenta;
