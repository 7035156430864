import styles from "./style.module.css";

const AuthButton = ({
  children,
  className,
  clickButton = () => {},
  ...props
}) => {
  return (
    <div className="w-full flex justify-center">
      <button
        className={[styles.authbutton, className].join(" ")}
        onClick={(e) => {
          e.preventDefault();
          clickButton();
        }}
        {...props}
      >
        {children}
      </button>
    </div>
  );
};

export default AuthButton;
