import {
  Header,
  Container,
  Description,
  Title,
  BuyTicketUpperBox,
  Footer,
  TicketButtonBox,
  AuthBox,
  ChooseHourBox,
  AuthButton,
  ChooseAllHourBox,
} from "../../../../components";
import styles from "./style.module.css";
import fakeImage1 from "../../../../assets/fakeImages/photo1.png";
import okkologo from "../../../../assets/okkologo.svg";
import userlogo from "../../../../assets/userlogo.svg";
import menu from "../../../../assets/menu.svg";
import logo1 from "../../../../assets/okkologo.svg";
import photo1 from "../../../../assets/fakeImages/photo4.png";
import Calendar from "react-calendar";
import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as RedTriangle } from "../../../../assets/redTriangle.svg";
import { ReactComponent as BlueCircle } from "../../../../assets/blueCircle.svg";
import { ReactComponent as GrayCircle } from "../../../../assets/grayCircle.svg";
import { ReactComponent as RedCircle } from "../../../../assets/redCircle.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getServerErrorMessage } from "../../../../helpers/auth";
import MiniLoading from "../../../../components/Loading/MiniLoading";
import { axiosClient } from "../../../../api/config";
import BusinessContext from "../../../../context/business";
import { API_URL } from "../../../../utils/url";
import moment from "moment";
import { convertMonthToTurkish } from "../../../../helpers/months";

const ChooseHour = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { setSelectedSection } = useContext(BusinessContext);
  const { isletmeId, biletId } = useParams();

  const selectedDate = searchParams.get("tarih") || "";

  const [apiLoading, setApiLoading] = useState(false);
  const [branch, setBranch] = useState(null);
  const [ticket, setTicket] = useState(null);
  const [calendarDetail, setCalendarDetail] = useState(null);

  const [selectedHours, setSelectedHours] = useState([]);

  useEffect(() => {
    if (isletmeId && biletId && selectedDate.length > 0) {
      getMultipleResponse();
    }

    return () => {
      setBranch(null);
      setCalendarDetail(null);
    };
  }, [isletmeId, biletId, selectedDate]);

  const getMultipleResponse = async () => {
    setApiLoading(true);
    try {
      const [branchInfoResponse, ticketInfoResponse, calendarDetailResponse] = await Promise.all([
        axiosClient.get(`/users/get-branch/branch=${isletmeId}`),
        axiosClient.get(`/users/get-ticket/${biletId}`),
        axiosClient.post(`/users/get-calendar-day-detail/ticket=${biletId}`, JSON.stringify({ date: selectedDate })),
      ]);

      setBranch(branchInfoResponse?.data?.data?.branch);
      setTicket(ticketInfoResponse.data.data);
      setCalendarDetail(calendarDetailResponse?.data?.data);
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
      setApiLoading(false);
    }
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/"} />
      <Container className={styles.container}>
        {/* <Title className={styles.upperTitle}>
          Macera, Adrenalin, Mutluluk...
        </Title>
        <Description className={styles.description}>
          Eğlencenin merkezine hoş geldin!
        </Description> */}
        {apiLoading && <MiniLoading className="mb-8" />}
        {/* <BuyTicketUpperBox
          logo={okkologo}
          photo={`${API_URL}` + branch?.image}
          description={branch?.description}
          imageText={branch?.name}
        /> */}
        <Title className={styles.midTitle}>Bilet Detayları</Title>
        <TicketButtonBox className={styles.ticketButtonBox}>
          <Description className={styles.ticketButtonBoxTitle}>{ticket?.name}</Description>
        </TicketButtonBox>
        <AuthBox className="bg-white border-none">
          <Title className={styles.dateTitle}>
            <RedTriangle className={styles.redTriangle} />
            {moment(selectedDate).format("DD")}
            <span className="mx-2">{convertMonthToTurkish(moment(selectedDate).format("MMMM"))}</span>
            {moment(selectedDate).format("YYYY")}
            <RedTriangle className={styles.redTriangle1} />
          </Title>
          <Title className={styles.whichDateTitle}>Hangi saatlerde randevu almak istersiniz?</Title>
          {/* <Description className={styles.hoursTitle}>
            Her randevu 30 dakika uzunluğundadır.
          </Description> */}
        </AuthBox>

        <AuthBox className={styles.authBox2}>
          <ChooseAllHourBox
            className={styles.chooseAllHourBox}
            selectedDate={selectedDate}
            calendarDetail={calendarDetail}
            selectHours={(hours) => {
              setSelectedHours(hours);
            }}
          />

          <AuthButton
            onClick={() => {
              // setSelectedSection({ id: 2 });

              navigate({
                pathname: `/isletmeler/${isletmeId}/${biletId}/saat-sec/iletisim-bilgileri`,
                search: `?tarih=${selectedDate}&saat=${selectedHours}`,
              });
            }}
            className={styles.authButton}
            disabled={selectedHours.length === 0}
          >
            Seç ve Devam Et
          </AuthButton>
        </AuthBox>
      </Container>
      <Footer />
    </div>
  );
};

export default ChooseHour;
