import { useState, useEffect } from "react";
import styles from "./style.module.css";
import { ChooseHourBox, Title, AuthInput, Description, TelephoneInput } from "../../index";
import { ReactComponent as Phone } from "../../../assets/icons/phone.svg";
import { ReactComponent as ArrowDown } from "../../../assets/arrowdown.svg";
import InputMask from "react-input-mask";
import PhoneInput from "react-phone-number-input";

const UserInformationBoxContainer = ({ data, className, handleUsers = () => {}, ...props }) => {
  const [hours, setHours] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    let tempHours = data.split(",");
    setHours(tempHours);
  }, [data]);

  useEffect(() => {
    handleUsers(users);
  }, [users]);

  const register = () => {};

  const handleInputChange = (index, field, value) => {
    setUsers((prevUsers) => {
      const updatedUsers = [...prevUsers];
      updatedUsers[index] = { ...updatedUsers[index], [field]: value };
      return updatedUsers;
    });
  };

  return (
    <div className="w-full">
      {hours?.map((item, index) => (
        <div key={index}>
          <ChooseHourBox className={[styles.chooseHourBox].join(" ")}>
            {index + 1}. Kişi {item.split("-")[0].slice(0, -3)} - {item.split("-")[1].slice(0, -3)}
            <ArrowDown className={styles.arrowDown} />
          </ChooseHourBox>
          <div className={styles.authBox}>
            <Title className={styles.authBoxTitle}>
              <Phone className={styles.phoneIcon} fill={"white"} stroke={"#454545"} />
              İletişim Bilgisi
            </Title>
            <Description className={[styles.authBoxDescription, "text-lg"].join(" ")}>İsim Soyisim:</Description>
            <div className={styles.inputContainer}>
              <AuthInput
                name={`namesurname-${index}`}
                className={styles.inputBox}
                placeholder="İsim Soyisim"
                register={register}
                value={users[index]?.namesurname || ""}
                onChange={(e) => handleInputChange(index, "namesurname", e.target.value)}
              />
            </div>
            <div className="w-full flex md:flex-row flex-col justify-between mt-8 mx-0 px-0 gap-4">
              <div className="w-full">
                <Description className={[styles.authBoxDescription, "text-lg"].join(" ")}>Telefon Numarası:</Description>
                <div className={styles.inputContainer}>
                  {/* <AuthInput
                    name={`phone-${index}`}
                    className={styles.inputBox}
                    placeholder="+90"
                    register={register}
                    value={users[index]?.phone || ""}
                    onChange={(e) => handleInputChange(index, "phone", e.target.value)}
                  /> */}
                  {/* <InputMask
                    className={[styles.inputBox, "h-[52px] rounded-xl mt-1 pl-4 outline-none"].join(" ")}
                    name={`phone-${index}`}
                    placeholder="Telefon Numarası"
                    value={users[index]?.phone || ""}
                    onChange={(e) => handleInputChange(index, "phone", e.target.value)}
                    mask="(999) 999 99 99"
                  /> */}

                  <PhoneInput
                    className={[styles.inputBox, "meetingPhone h-[52px] rounded-xl mt-1 pl-4 outline-none"].join(" ")}
                    name={`phone-${index}`}
                    defaultCountry="TR"
                    placeholder="Telefon Numarası"
                    value={users[index]?.phone || ""}
                    onChange={(e) => handleInputChange(index, "phone", e)}
                  />
                </div>
              </div>
              <div className="w-full">
                <Description className={[styles.authBoxDescription, "text-lg"].join(" ")}>E-Posta:</Description>

                <div className={styles.inputContainer}>
                  <AuthInput
                    name={`email-${index}`}
                    className={styles.inputBox}
                    placeholder="E-Posta"
                    register={register}
                    value={users[index]?.email || ""}
                    onChange={(e) => {
                      handleInputChange(index, "email", e.target.value);
                      handleInputChange(index, "tcno", "11111111111");
                    }}
                  />
                </div>
              </div>
              {/* 
              <div className="w-full">
                <Description
                  className={[styles.authBoxDescription, "text-lg"].join(" ")}
                >
                  TC | Pasaport No:
                </Description>
                <div className={styles.inputContainer}>
                  <AuthInput
                    name={`tcno-${index}`}
                    className={styles.inputBox}
                    placeholder="TC Kimlik No | Pasaport No"
                    register={register}
                    value={users[index]?.tcno || ""}
                    onChange={(e) =>
                      handleInputChange(index, "tcno", e.target.value)
                    }
                  />
                </div>
              </div>
              */}
            </div>
            <Description className={styles.authBoxDescription2}>Biletiniz SMS ve e-posta yoluyla ücretsiz gönderilecektir.</Description>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserInformationBoxContainer;
