import {
  Header,
  Container,
  Description,
  Title,
  BuyTicketUpperBox,
  Footer,
  AuthBox,
  AddBusinessesInput,
  AuthButton,
  PersonelDatabaseTable,
} from "../../../components/index";
import styles from "./style.module.css";
import fakeImage1 from "../../../assets/fakeImages/photo1.png";
import okkologo from "../../../assets/okkoadminlogo.svg";
import userlogo from "../../../assets/userlogowhite.svg";
import menu from "../../../assets/menu.svg";
import logo1 from "../../../assets/okkologo.svg";
import photo1 from "../../../assets/fakeImages/photo4.png";
import { useEffect, useState } from "react";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { useParams } from "react-router-dom";
import { API_URL } from "../../../utils/url";
import { getServerErrorMessage } from "../../../helpers/auth";
import moment from "moment";

const PersonelDatabase = () => {
  const { isletmeId } = useParams();

  const axiosClient = useAxiosClient();

  const [date, setDate] = useState(moment(new Date()).format("DD-MM-YYYY")); // today date
  const [personelData, setPersonelData] = useState([]);
  const [personelDataLoading, setPersonelDataLoading] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [searching, setSearching] = useState("all");
  const [businessData, setBusinessData] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getBusinesses();
  }, []);

  useEffect(() => {
    if (searching == "" || searching == "all") {
      setSearching("all");
    }
    getPersonelData();
  }, [searching, date]);

  const getPersonelData = async () => {
    try {
      const response = await axiosClient.get(
        `/superuser/get-personel-documents-by-date/branch-id=${isletmeId}&date=${date}&searching=${searching}&page=${1}&size=${10}`
      );

      setPersonelData(response?.data?.data?.documents_list);
      setTotalPageCount(response?.data?.data?.total_page_count);
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
      setPersonelDataLoading(false);
    }
  };

  const getHandlePageChange = async (page) => {
    try {
      const response = await axiosClient.get(
        `/superuser/get-personel-documents-by-date/branch-id=${isletmeId}&date=${date}&searching=${"all"}&page=${page}&size=${10}`
      );

      setPersonelData(response?.data?.data?.documents_list);
      setTotalPageCount(response?.data?.data?.total_page_count);
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
      setPersonelDataLoading(false);
    }
  };

  const getBusinesses = async () => {
    try {
      const response = await axiosClient.get(`/superuser/get-branch/branch-id=${isletmeId}`);
      if (response.status === 200) {
        setBusinessData(response?.data?.data?.branch);
      }
    } catch (error) {
      errorMessage("İşletmeler getirilirken bir hata oluştu.");
    }
  };

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      getPersonelData();
    }
  };

  return (
    <div className="md:grid grid-rows-layoutAuth">
      <Header
        className="bg-primary"
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink={"/profil"}
        homeLink={"/admin"}
      />
      <Container className={styles.container}>
        {/* <Title className={styles.upperTitle}>Macera, Adrenalin, Mutluluk...</Title>
        <Description className={styles.description}>Eğlencenin merkezine hoş geldin!</Description> */}

        <Title className={[styles.upperTitle, "mb-6"].join(" ")}>{businessData?.name}</Title>
        {/* 
        <BuyTicketUpperBox
          logo={logo1}
          photo={API_URL + businessData?.image}
          description={businessData?.description}
          imageText={businessData?.name}
          className="mb-20"
        /> */}

        <Title className={styles.midTitle}>Personel Veritabanı</Title>

        <AuthBox className={styles.authBox}>
          <Title className={styles.midTitle}>Tarih Seçiniz</Title>
          <Description className={styles.desc}>Tarih:</Description>
          <AddBusinessesInput
            placeholder="Tarih Seçiniz  --/--/----"
            className="w-full h-[45px] text-[16px]"
            value={date}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.length === 2 || inputValue.length === 5) {
                if (inputValue.length > date.length) {
                  setDate(inputValue + "-");
                } else {
                  setDate(inputValue.slice(0, -1));
                }
              } else {
                setDate(inputValue);
              }
            }}
            onKeyPress={handleKeypress}
          />
          <AuthButton className={styles.button} clickButton={() => getPersonelData()}>
            Ara
          </AuthButton>
        </AuthBox>

        <Title className={styles.midTitle}>Personel Detayı</Title>
        <PersonelDatabaseTable
          searching={searching}
          setSearching={setSearching}
          getHandlePageClick={getHandlePageChange}
          totalPageCount={totalPageCount}
          className={styles.table}
          personels={personelData}
        />
      </Container>

      <Footer />
    </div>
  );
};

export default PersonelDatabase;
