import { useState, useEffect } from "react";
import Container from "../Container";
import styles from "./style.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import flagEN from "../../assets/flag-en.png";
import flagTR from "../../assets/flag-tr.png";
import useAuth from "../../hooks/useAuth";
import useLogout from "../../hooks/useLogout";
import { useNavigate } from "react-router-dom";
import useAxiosClient from "../../hooks/useAxiosClient";
import { getServerErrorMessage } from "../../helpers/auth";

const Header = ({ className, okkoLogo, userIcon, profileLink, homeLink }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const authAxiosClient = useAxiosClient();
  const { auth } = useAuth();

  const logout = useLogout();

  const [business, setBusiness] = useState(null);

  useEffect(() => {
    if (auth.token) {
      getBranchDetail();
    }
  }, []);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const getRoleName = () => {
    if (auth.role === "technical_support") return "Teknik Sorumlu";
    if (auth.role === "personel") return "Kasiyer";
    if (auth.role === "personel_lead") return "Personel Lideri";
    if (auth.role === "admin") return "Admin";
  };

  const getHomeLink = () => {
    if (auth.role === "personel" || auth.role === "personel_lead" || auth.role === "technical_support") {
      return "/personel";
    } else if (auth.role === "admin") {
      return "/admin";
    } else {
      return "/";
    }
  };

  const getBranchDetail = async () => {
    try {
      const response = await authAxiosClient.get("/personel/get-branch-detail");
      setBusiness(response?.data?.data);
    } catch (err) {
      //getServerErrorMessage(err);
    }
  };
  //  is_indirim true -> yüzde
  //  is_indirim false -> sayi

  //  indirim_rate = yüzde veya sayı

  return (
    <div className={[styles.header, className].join(" ")}>
      <Container className={styles.container}>
        <div className={styles.leftside}>
          <Link to={getHomeLink()}>
            <img className={styles.logo} src={okkoLogo} alt=""></img>
          </Link>
          {auth.token && (
            <div className={styles.userInfo}>
              <div className={styles.branchName}>
                <span>{business?.branch_name}</span>
              </div>
              <span className={styles.roleName}>{getRoleName()}</span>
            </div>
          )}
        </div>
        <div className={styles.rightside}>
          <div className="relative">
            {/* <button className={styles.dropdownButton} onClick={toggleDropdown}>
              {i18n.language === "en" ? (
                <img src={flagEN} alt="EN Flag" className={styles.flagIcon} />
              ) : (
                <img src={flagTR} alt="TR Flag" className={styles.flagIcon} />
              )}
            </button> */}
            {/*isDropdownOpen && (
              <div className={styles.dropdownContent}>
                <button
                  onClick={() => changeLanguage("en")}
                  className="flex items-center w-full px-4 py-2 min-w-[100px] hover:bg-gray-100"
                >
                  <img src={flagEN} alt="EN Flag" className={styles.flagIcon} />
                  English
                </button>
                <button
                  onClick={() => changeLanguage("tr")}
                  className="flex items-center w-full px-4 py-2 min-w-[100px] hover:bg-gray-100"
                >
                  <img src={flagTR} alt="TR Flag" className={styles.flagIcon} />
                  Türkçe
                </button>
              </div>
            )*/}
          </div>

          <button
            onClick={() => {
              if (auth?.token?.length > 0) {
                navigate(profileLink);
              } else {
                navigate("/giris");
              }
            }}
          >
            <div className="flex items-center gap-2">
              <img src={userIcon} alt=""></img>
              {(!auth?.token || auth?.token?.length == 0) && <p className="text-black">Giriş Yap</p>}
            </div>
          </button>

          {auth?.token?.length > 0 && (
            <button onClick={logout} className="text-white rounded-md px-1 md:px-3 py-2 bg-red-500 font-semibold">
              Çıkış Yap
            </button>
          )}

          {/* <button>
            <img src={menuIcon} alt=""></img>
          </button> */}
        </div>
      </Container>
    </div>
  );
};

export default Header;
