import {
  BgBox,
  AuthBox,
  Header,
  Logo,
  Container,
  Title,
  Footer,
  AuthInput,
  ForgotLink,
  RegisterLink,
  SubmitButton,
  InputError,
} from "../../../../../components/";
import styles from "./style.module.css";
import redsplash from "../../../../../assets/splash/redsplash.svg";
import bluesplash from "../../../../../assets/splash/bluesplash2.svg";
import okkologo from "../../../../../assets/okkologo.svg";
import userlogo from "../../../../../assets/userlogo.svg";
import menu from "../../../../../assets/menu.svg";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage, successMessage } from "../../../../../helpers/toast";
import { useNavigate } from "react-router-dom";
import { getServerErrorMessage } from "../../../../../helpers/auth";
import useAxiosClient from "../../../../../hooks/useAxiosClient";

const emailScheme = yup
  .object({
    email: yup.string().email("Geçerli bir e-posta adresi girin").required("Bu alan gerekli"),
  })
  .required();

const NewMail = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(emailScheme),
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const authAxiosClient = useAxiosClient();

  const onSubmit = (data) => {
    try {
      let response = authAxiosClient.post("/users/change-email", {
        new_email: data.email,
        new_email_again: data.email,
      });

      if (response.error) {
        errorMessage("Bir hata oluştu!");
      } else {
        successMessage("Email doğrulama kodu gönderildi!");

        navigate({
          pathname: "/profil/mail-degistir/mail-dogrula",
          search: `?email=${data.email}`,
        });
      }
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  return (
    <div className="grid grid-rows-layoutAuth ">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/"} />
      <Container className={styles.container}>
        <BgBox
          className={styles.bgBox}
          img1={bluesplash}
          img2={redsplash}
          img1Style="top-[30%] left-[52%] rotate-[240deg]"
          img2Style="bottom-[42%] right-[57%]"
          windowsize={900}
        >
          <AuthBox className={styles.authBox}>
            <Logo className={styles.logo} />
            <form onSubmit={handleSubmit(onSubmit)} className={styles.formContainer}>
              <Title className={styles.title}>{t("enterNewEmail")}</Title>
              <div className={styles.inputContainer}>
                <AuthInput className={styles.authInput} name="email" placeholder={t("emailPlaceholder")} register={register} />
                <InputError text={errors.email?.message} />
              </div>

              <SubmitButton text={t("continueButton")} />
            </form>
          </AuthBox>
        </BgBox>
        <RegisterLink link={t("signIn")} to="/giris">
          {t("alreadyHaveAccount")}
        </RegisterLink>
        <ForgotLink to="/sifremi-unuttum">{t("forgotPassword")}</ForgotLink>
      </Container>
      <Footer />
    </div>
  );
};

export default NewMail;
