import Modal from "react-modal";
import AuthButton from "../Button/AuthButton";
import { ReactComponent as Exit } from "../../assets/icons/exit.svg";
import styles from "./style.module.css";

const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.50)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #EEEEEE",
    background: "#fff",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "8px",
    outline: "none",
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "30px",
    paddingBottom: "30px",
  },
};

Modal.setAppElement("#root");

const OkkoModal = ({
  modalIsOpen,
  closeModal = () => {},
  title = "",
  altText = "",
  declineButtonText = "REDDET",
  approveButtonText = "ONAYLA",
  approve = () => {},
  isLoading = false,
}) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Onay Modalı"
    >
      <button onClick={closeModal} className={styles.closeButton}>
        <Exit width="22" height="22" />
      </button>

      <div className={styles.textsContainer}>
        <h2 className={styles.modalTitle}>{title}</h2>

        <p className={styles.altText}>{altText}</p>
      </div>

      <div className={styles.buttonsContainer}>
        <AuthButton
          className={[styles.button, styles.declineButton].join(" ")}
          onClick={() => {
            closeModal();
          }}
        >
          {declineButtonText}
        </AuthButton>

        <AuthButton
          className={[styles.button, styles.approveButton].join(" ")}
          onClick={() => {
            approve();
            closeModal();
          }}
          disabled={isLoading}
        >
          {approveButtonText}
        </AuthButton>
      </div>
    </Modal>
  );
};

export default OkkoModal;
