import { useState, useEffect, createContext } from "react";
import { axiosClient } from "../api/config";
import { getServerErrorMessage } from "../helpers/auth";
import { successMessage } from "../helpers/toast";

const BusinessContext = createContext(null);

export const BusinessProvider = ({ children }) => {
  // BRANCH INFO
  const [selectedBranchId, setSelectedBranchId] = useState(null);
  const [branchLoading, setBranchLoading] = useState(false);
  const [branchInfo, setBranchInfo] = useState(null);
  // TICKET
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);

  useEffect(() => {
    getBranch();

    return () => {
      setSelectedBranchId(null);
      setBranchInfo(null);
    };
  }, [selectedBranchId]);

  const getBranch = async () => {
    try {
      const response = await axiosClient.get(`/users/get-branch/branch=${selectedBranchId}`);
    } catch (error) {
      getServerErrorMessage(error);
    }
  };

  const completePurchase = async (ticketId, users, cardInfos, selectedDate, isCampaign, couponCode, isCash) => {
    try {
      const response = await axiosClient.post(
        `/users/complete-purchase/ticket=${ticketId}`,
        JSON.stringify({
          users: users,
          kapıda_ödeme: isCash,
          card_holder_name: cardInfos?.card_holder_name || "",
          card_number: cardInfos?.card_number || "",
          card_expiration_month: cardInfos?.card_expiration_month || "",
          card_expiration_year: cardInfos?.card_expiration_year || "",
          card_cvv: cardInfos?.card_cvv || "",
          date: selectedDate,
          is_campaign: isCampaign,
          coupon_code: couponCode,
        })
      );
      // successMessage(
      //   "Satın alma işlemi başarılı. Mailinizi ve telefonunuzu kontrol ediniz."
      // );
      return true;
    } catch (err) {
      getServerErrorMessage(err);
      return false;
    }
  };

  return (
    <BusinessContext.Provider
      value={{
        selectedBranchId,
        setSelectedBranchId,
        branchLoading,
        setBranchLoading,
        branchInfo,
        setBranchInfo,

        selectedDate,
        setSelectedDate,

        selectedSection,
        setSelectedSection,

        completePurchase,
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
};

export default BusinessContext;
