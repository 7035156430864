import styles from "./style.module.css";
import { ReactComponent as Plus } from "../../../../assets/plus.svg";
import { API_URL } from "../../../../utils/url";

const AddBusinessCardBox = ({
  photo,
  setPhoto,
  businessName,
  setBusinessName,
  children,
  className,
  ...props
}) => {
  return (
    <div className={[styles.businessesbox, className].join(" ")} {...props}>
      {photo && (
        <img
          src={
            typeof photo === "string"
              ? API_URL + photo
              : URL.createObjectURL(photo)
          }
          className={styles.photo}
          onClick={() => {
            setPhoto();
          }}
        ></img>
      )}

      {!photo && (
        <label className={styles.photo}>
          <input
            type="file"
            name="photo"
            id="upload-photo"
            onChange={(event) => {
              setPhoto(event.target.files[0]);
            }}
            className="hidden"
            value={photo}
          ></input>{" "}
          <Plus className="w-3 h-3" />
        </label>
      )}

      <div className={styles.lowerdescarea}>
        <input
          className={styles.input}
          placeholder="İşletme Adı Girin."
          value={businessName}
          onChange={(event) => {
            setBusinessName(event.target.value);
          }}
        ></input>
      </div>
    </div>
  );
};

export default AddBusinessCardBox;
