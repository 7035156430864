import styles from "./style.module.css";
import timeLogo from "../../../assets/time-icon.svg";

const TimeIcon = ({ children, className, ...props }) => {
  return (
    <img
      src={timeLogo}
      className={[styles.logo, className].join(" ")}
      {...props}
    >
      {children}
    </img>
  );
};

export default TimeIcon;
