import styles from "./style.module.css";
import { AuthBox } from "../../../index";

const BusinessDescCard = ({
  businessName,
  setBusinessName,
  businessDesc,
  setBusinessDesc,
  children,
  className,
  ...props
}) => {
  return (
    <AuthBox className={styles.authBox}>
      <div className="flex flex-col gap-1 w-full">
        <label htmlFor="isletmeadi" className="ml-1.5">
          İşletme Adı
        </label>
        <input
          id="isletmeadi"
          className={styles.upperBoxTitleText}
          placeholder="İşletme Adı"
          value={businessName}
          onChange={(event) => {
            setBusinessName(event.target.value);
          }}
        ></input>
      </div>
      <div className="flex flex-col gap-1 w-full h-full">
        <label htmlFor="isletmeaciklama" className="ml-1.5">
          İşletme Açıklaması
        </label>
        <textarea
          id="isletmeaciklama"
          className={styles.upperBoxDescriptionText}
          placeholder="Açıklama giriniz..."
          value={businessDesc}
          onChange={(event) => {
            setBusinessDesc(event.target.value);
          }}
        ></textarea>
      </div>
    </AuthBox>
  );
};

export default BusinessDescCard;
