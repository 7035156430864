import {
  Header,
  Container,
  Description,
  Title,
  BuyTicketUpperBox,
  Footer,
  AuthBox,
  AddBusinessesInput,
  AuthButton,
  AddPersonelBox,
  AddOfferBox,
  PersonelPermissionsCheckBox,
} from "../../../components/index";
import styles from "./style.module.css";
import fakeImage1 from "../../../assets/fakeImages/photo1.png";
import okkologo from "../../../assets/okkoadminlogo.svg";
import userlogo from "../../../assets/userlogowhite.svg";
import menu from "../../../assets/menu.svg";
import logo1 from "../../../assets/okkologo.svg";
import photo1 from "../../../assets/fakeImages/photo4.png";
import { useState, useContext, useEffect, useCallback } from "react";
import StoreContext from "../../../context/store";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { errorMessage, successMessage } from "../../../helpers/toast";
import { API_URL } from "../../../utils/url";
import { ReactComponent as Plus } from "../../../assets/plus.svg";
import CampaignTable from "../../../components/Table/CampaignTable";
import MiniLoading from "../../../components/Loading/MiniLoading";
import { getServerErrorMessage } from "../../../helpers/auth";

const CreateFreeCampaign = () => {
  const navigate = useNavigate();
  const axiosClient = useAxiosClient();

  const [totalPageCount, setTotalPageCount] = useState(0);
  const [searching, setSearching] = useState("all");
  const [freeData, setFreeData] = useState([]);
  const [freeDataLoading, setFreeDataLoading] = useState(false);
  const [indirimType, setIndirimType] = useState(1);
  const [indirimRate, setIndirimRate] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getFreeCampaignsData();
  }, []);

  useEffect(() => {
    getFreeCampaigns();
    if (searching == "") {
      setSearching("all");
    }
  }, [searching, axiosClient]);

  const getFreeCampaignsData = async () => {
    setFreeDataLoading(true);
    try {
      const response = await axiosClient.get(`/superuser/get-all-campaign-codes/searching=all&page-num=${1}&page-size=${10}`);

      setFreeData(response?.data?.data?.campaign_codes_list);
      setTotalPageCount(response?.data?.data?.total_page_count);
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
      setFreeDataLoading(false);
    }
  };

  const getFreeCampaigns = async () => {
    try {
      const response = await axiosClient.get(`/superuser/get-all-campaign-codes/searching=${searching}&page-num=${1}&page-size=${10}`);
      if (response.status === 200) {
        setFreeData(response?.data?.data?.campaign_codes_list);
      }
    } catch (error) {
      //errorMessage("Ücretsiz Kampanya kodları getirilirken bir hata oluştu!");
    }
  };

  const getHandlePageChange = async (page) => {
    setFreeDataLoading(true);
    try {
      const response = await axiosClient.get(`/superuser/get-all-campaign-codes/searching=${searching}&page-num=${page}&page-size=${10}`);

      setFreeData(response?.data?.data?.campaign_codes_list);
      setTotalPageCount(response?.data?.data?.total_page_count);
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
      setFreeDataLoading(false);
    }
  };

  const createCampaign = useCallback(async () => {
    try {
      const response = await axiosClient.post(
        "/superuser/create-campaign-code",
        {
          is_indirim: indirimType == 1 ? true : false,
          indirim_rate: indirimRate,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        successMessage("Ücretiz Kampanya başarıyla oluşturuldu.");
        getFreeCampaignsData();
      }
    } catch (error) {
      errorMessage("Ücretsiz Kampanya eklenirken bir hata oluştu.");
    }
  }, [indirimType, indirimRate]);

  const deleteFreeCampaign = async (id) => {
    try {
      const response = await axiosClient.get(`/superuser/delete-campaign-code/id=${id}`);
      if (response.status === 200) {
        successMessage("Ücretiz Kampanya başarıyla silindi.");
        getFreeCampaignsData();
      }
    } catch (error) {
      errorMessage("Ücretsiz Kampanya eklenirken bir hata oluştu.");
    }
  };

  return (
    <div className="md:grid grid-rows-layoutAuth">
      <Header className="bg-primary" okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink={"/profil"} homeLink={"/admin"} />
      <Container className={styles.container}>
        {/* <Title className={styles.upperTitle}>
          Macera, Adrenalin, Mutluluk...
        </Title>
        <Description className={styles.description}>
          Eğlencenin merkezine hoş geldin!
        </Description>

        <BuyTicketUpperBox
          logo={logo1}
          photo={API_URL + businessData?.image}
          description={businessData?.description}
          imageText={businessData?.name}
          className="mb-20"
        /> */}

        <Title className={styles.midTitle}>Ücretsiz Kampanya Oluştur</Title>

        <AuthBox className={styles.authBox1}>
          <div className="flex flex-col items-center gap-6 mb-4">
            <div className="flex items-center gap-3">
              <button
                className={["border rounded-md px-6 py-1", indirimType == 1 ? "bg-blue-500 text-white" : "bg-gray-50"].join(" ")}
                onClick={() => {
                  setIndirimType(1);
                }}
              >
                Yüzde
              </button>
              <button
                className={["border rounded-md px-6 py-1", indirimType == 2 ? "bg-blue-500 text-white" : "bg-gray-50"].join(" ")}
                onClick={() => {
                  setIndirimType(2);
                }}
              >
                Miktar
              </button>
            </div>

            <div className="flex items-center gap-1 border w-52 pr-2 py-1.5">
              <input
                type="text"
                className="pl-2 outline-none w-full"
                placeholder="İndirim"
                value={indirimRate}
                onChange={(e) => {
                  setIndirimRate(e.target.value);
                }}
              />
              <span className="text-lg">{indirimType == 1 ? "%" : "TL"}</span>
            </div>
          </div>
          <AuthButton
            className="w-full text-[16px] mt-5"
            onClick={() => {
              createCampaign();
            }}
          >
            Oluştur
          </AuthButton>
        </AuthBox>

        <Title className={styles.midTitle}>Kampanyaları Görüntüle</Title>

        {freeDataLoading && <MiniLoading />}

        <CampaignTable
          totalPageCount={totalPageCount}
          getHandlePageClick={getHandlePageChange}
          searching={searching}
          setSearching={setSearching}
          className={styles.table}
          codes={freeData}
          deleteCode={(id) => {
            deleteFreeCampaign(id);
          }}
        />
      </Container>

      <Footer />
    </div>
  );
};

export default CreateFreeCampaign;
