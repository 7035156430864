import {
  Header,
  Container,
  Description,
  Title,
  BuyTicketUpperBox,
  Footer,
  AuthBox,
  AddBusinessesInput,
  AuthButton,
  AddPersonelBox,
  AddOfferBox,
  PersonelPermissionsCheckBox,
} from "../../../components/index";
import styles from "./style.module.css";
import fakeImage1 from "../../../assets/fakeImages/photo1.png";
import okkologo from "../../../assets/okkoadminlogo.svg";
import userlogo from "../../../assets/userlogowhite.svg";
import menu from "../../../assets/menu.svg";
import logo1 from "../../../assets/okkologo.svg";
import photo1 from "../../../assets/fakeImages/photo4.png";
import { useState, useContext } from "react";
import StoreContext from "../../../context/store";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { errorMessage, successMessage } from "../../../helpers/toast";
import { useEffect } from "react";
import { API_URL } from "../../../utils/url";
import { ReactComponent as Plus } from "../../../assets/plus.svg";

const AddOffer = () => {
  const navigate = useNavigate();
  const axiosClient = useAxiosClient();
  const [photo, setPhoto] = useState();
  // addOffer, setShortOfferDesc,shortOfferDesc, offerDesc, setOfferDesc,offerName, setOfferName, isActiveOffer, setIsActiveOffer
  const [shortOfferDesc, setShortOfferDesc] = useState();
  const [offerDesc, setOfferDesc] = useState();
  const [offerName, setOfferName] = useState();
  const [isActiveOffer, setIsActiveOffer] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addOffer = async () => {
    try {
      let formdata = new FormData();
      formdata.append("image", photo);
      formdata.append("title", offerName);
      formdata.append("description", offerDesc);
      formdata.append("short_description", shortOfferDesc);
      formdata.append("is_active", isActiveOffer);

      const response = await axiosClient.post("/superuser/add-news", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        successMessage("Kampanya başarıyla eklendi.");
        navigate("/admin");
      }
    } catch (error) {
      errorMessage("Kampanya eklenirken bir hata oluştu.");
    }
  };

  return (
    <div className="md:grid grid-rows-layoutAuth">
      <Header
        className="bg-primary"
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink={"/profil"}
        homeLink={"/admin"}
      />
      <Container className={styles.container}>
        {/* <Title className={styles.upperTitle}>
          Macera, Adrenalin, Mutluluk...
        </Title>
        <Description className={styles.description}>
          Eğlencenin merkezine hoş geldin!
        </Description>

        <BuyTicketUpperBox
          logo={logo1}
          photo={API_URL + businessData?.image}
          description={businessData?.description}
          imageText={businessData?.name}
          className="mb-20"
        /> */}

        <Title className={styles.midTitle}>Kampanya Ekleme</Title>

        <AuthBox className={styles.authBox1}>
          <Title className={styles.midTitle}>Kampanya Bilgisi</Title>
          <div className="flex gap-4 w-full max-md:flex-wrap max-md:justify-center">
            <div className={styles.businessesbox}>
              {photo && (
                <img
                  src={URL.createObjectURL(photo)}
                  className={styles.photo}
                  onClick={() => {
                    setPhoto();
                  }}
                ></img>
              )}

              {!photo && (
                <label className={styles.photo}>
                  <input
                    type="file"
                    name="photo"
                    id="upload-photo"
                    onChange={(event) => {
                      setPhoto(event.target.files[0]);
                    }}
                    className="hidden"
                    value={photo}
                  ></input>{" "}
                  <Plus className="w-3 h-3" />
                </label>
              )}
            </div>
            <AddOfferBox
              addOffer={addOffer}
              setShortOfferDesc={setShortOfferDesc}
              shortOfferDesc={shortOfferDesc}
              offerDesc={offerDesc}
              setOfferDesc={setOfferDesc}
              offerName={offerName}
              setOfferName={setOfferName}
              isActiveOffer={isActiveOffer}
              setIsActiveOffer={setIsActiveOffer}
            />
          </div>
        </AuthBox>
      </Container>

      <Footer />
    </div>
  );
};

export default AddOffer;
