import { useEffect } from "react";
import {
  BgBox,
  AuthBox,
  Header,
  AuthButton,
  Logo,
  Container,
  Title,
  OtpInput,
  RegisterLink,
  ForgotLink,
} from "../../../../components";
import styles from "./style.module.css";
import redsplash from "../../../../assets/splash/redsplash.svg";
import cyan2splash from "../../../../assets/splash/cyansplash2.svg";
import okkologo from "../../../../assets/okkologo.svg";
import userlogo from "../../../../assets/userlogo.svg";
import menu from "../../../../assets/menu.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosClient } from "../../../../api/config";
import { getServerErrorMessage } from "../../../../helpers/auth";
import { successMessage } from "../../../../helpers/toast";

const MailOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const email = searchParams.get("email") || "";
  const phone = searchParams.get("phone") || "";

  useEffect(() => {
    sendEmailVerification();
  }, [email]);

  const sendEmailVerification = async () => {
    try {
      const response = await axiosClient.post(
        "/users/send-email-verification",
        JSON.stringify({ email })
      );
      successMessage("Mail gönderildi");
      return;
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  const controlOtpMail = async (otp) => {
    try {
      const response = await axiosClient.post(
        "/users/email-verify",
        JSON.stringify({ email, otp })
      );

      navigate({
        pathname: "/kayit-ol/mail-dogrula/telefon-dogrula",
        search: `?email=${email}&phone=${phone}`,
      });
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  const getMailCodeAgain = () => {
    // let { data, error } = await getMailCode(otp);
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink="/profil"
        homeLink={"/"}
      />
      <Container className={styles.container}>
        <BgBox
          className={styles.bgBox}
          img1={cyan2splash}
          img2={redsplash}
          img1Style="bottom-[13%] left-[52%]"
          img2Style="bottom-[48%] right-[57%] "
          windowsize={768}
        >
          <AuthBox className={styles.authBox}>
            <Logo className={styles.logo} />
            <Title className={styles.title}>Mail adresinizi doğrulayın.</Title>
            <OtpInput
              type="email"
              linkText="Farklı e-posta ile kayıt ol."
              getCodeAgain={() => {
                getMailCodeAgain();
              }}
              controlOtp={(otp) => {
                controlOtpMail(otp);
              }}
            />
          </AuthBox>
          <RegisterLink link="Giriş Yap" to="/giris">
            Zaten hesabım var?
          </RegisterLink>

          <ForgotLink to="/sifremi-unuttum">Şifremi Unuttum!</ForgotLink>
        </BgBox>
      </Container>
    </div>
  );
};

export default MailOtp;
