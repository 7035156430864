import { useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "../../assets/Roboto-Regular-normal";
import MiniLoading from "../Loading/MiniLoading";

const ExportPdf = ({
  className,

  fileName,
  fileTitle,
  data,
  headData,
  ...props
}) => {
  const [pdfLoading, setPdfLoading] = useState(false);

  const exportPdf = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(13);
    doc.setFont("Roboto-Regular", "normal");

    const title = fileTitle;
    const headers = headData;

    let content = {
      startY: 50,
      head: headers,
      body: data,
      styles: {
        font: "Roboto-Regular",
        fontStyle: "normal",
      },
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(fileName);
  };

  return (
    <button
      className={[
        "font-bold bg-primary text-white rounded-md text-xl px-6 py-2",
        className,
      ].join(" ")}
      onClick={() => {
        exportPdf();
      }}
      disabled={!(pdfLoading === false)}
    >
      {pdfLoading ? <MiniLoading /> : "PDF"}
    </button>
  );
};

export default ExportPdf;
