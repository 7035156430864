import { useState, useEffect } from "react";
import {
  BgBox,
  Header,
  Container,
  Description,
  OpportunityBox,
  PagesBox,
  Title,
  Footer,
} from "../../../components";
import styles from "./style.module.css";
import redsplash from "../../../assets/splash/redsplash.svg";
import cyansplash from "../../../assets/splash/cyansplash2.svg";
import okkologo from "../../../assets/okkologo.svg";
import userlogo from "../../../assets/userlogo.svg";
import menu from "../../../assets/menu.svg";
import { getServerErrorMessage } from "../../../helpers/auth";
import { axiosClient } from "../../../api/config";
import MiniLoading from "../../../components/Loading/MiniLoading";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../../utils/url";

const UserHome = () => {
  const { t } = useTranslation();

  const [newsLoading, setNewsLoading] = useState(false);
  const [news, setNews] = useState([]);

  useEffect(() => {
    getNews();

    return () => {
      setNews([]);
    };
  }, []);

  const getNews = async () => {
    setNewsLoading(true);
    try {
      const response = await axiosClient.get("/users/get-all-news");

      setNews(response?.data?.data);
    } catch (err) {
      getServerErrorMessage(err);
    } finally {
      setNewsLoading(false);
    }
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink="/profil"
        homeLink={"/"}
      />

      <Container className={styles.container}>
        <Title className={styles.upperTitle}>
          {t("adventure")} {t("adrenaline")} {t("happiness")}...
        </Title>
        <Description className={styles.description}>
          {t("welcomeMessage")}
        </Description>
        <BgBox
          className={styles.bgBox}
          img1={cyansplash}
          img2={redsplash}
          img1Style="top-[10%] left-[70%]"
          img2Style="bottom-[40%] right-[76%]"
          windowsize={1250}
        >
          <PagesBox link1="/isletmeler" link2="/pnr-sorgula"></PagesBox>
        </BgBox>
        <Title className={styles.bottomTitle}>{t("opportunityTitle")}</Title>
        {newsLoading && <MiniLoading className="mb-8" />}
        {news?.map((item) => (
          <OpportunityBox
            key={item.id}
            img={`${API_URL + item.image}`}
            imgStyle="md:w-96"
          >
            <Title className={styles.opportunityBoxTitleText}>
              {item.title}
            </Title>
            <Description className={styles.opportunityBoxDescriptionText}>
              {item.description}
            </Description>
          </OpportunityBox>
        ))}
      </Container>
      <Footer />
    </div>
  );
};

export default UserHome;
