import { useState, useEffect } from "react";
import styles from "./style.module.css";
import okkologo from "../../../assets/okkologo.svg";
import userlogo from "../../../assets/userlogo.svg";
import menu from "../../../assets/menu.svg";
import { Header, Description, Container } from "../../../components";
import useAxiosClient from "../../../hooks/useAxiosClient";
import MoneyHistoryTable from "../../../components/Table/MoneyHistoryTable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const PersonalMoneyHistory = () => {
  const authAxiosClient = useAxiosClient();

  const [totalPageCount, setTotalPageCount] = useState(0);
  const [searching, setSearching] = useState("all");
  const [moneyData, setMoneyData] = useState([]);

  const [allMoneyDetail, setAllMoneyDetail] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const startDay = startDate.getDate().toString().padStart(2, "0");
  const startMonth = (startDate.getMonth() + 1).toString().padStart(2, "0");
  const startYear = startDate.getFullYear();

  const endDay = endDate.getDate().toString().padStart(2, "0");
  const endMonth = (endDate.getMonth() + 1).toString().padStart(2, "0");
  const endYear = endDate.getFullYear();

  const formattedDate = `${startDay}-${startMonth}-${startYear}:${endDay}-${endMonth}-${endYear}`;

  useEffect(() => {
    if (searching == "" || searching == "all") {
      setSearching("all");
    }
    getMoneyDetails();
    getAllMoneyDetailsForExcel();
  }, [searching, formattedDate]);

  const getAllMoneyDetailsForExcel = async () => {
    try {
      const response = await authAxiosClient.get(
        `/personel/get-using-details/date-info=${formattedDate}&page-num=1&page-size=100&searching=${searching}`
      );
      setAllMoneyDetail(response?.data?.data?.items);
    } catch (err) {
      // getServerErrorMessage(err);
    }
  };

  const getMoneyDetails = async () => {
    try {
      const response = await authAxiosClient.get(
        `/personel/get-using-details/date-info=${formattedDate}&page-num=1&page-size=6&searching=${searching}`
      );

      setMoneyData(response?.data?.data?.items);
      setTotalPageCount(response?.data?.data?.total_page_count);
    } catch (err) {
      // getServerErrorMessage(err);
    }
  };

  const getHandlePageChange = async (page) => {
    try {
      const response = await authAxiosClient.get(
        `/personel/get-using-details/date-info=${formattedDate}&page-num=${page}&page-size=6&searching=${searching}`
      );

      setMoneyData(response?.data?.data?.items);
      setTotalPageCount(response?.data?.data?.total_page_count);
    } catch (error) {
      // getServerErrorMessage(error);
    } finally {
    }
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/personel"} />
      <Container className={styles.container}>
        {/* <Title className={styles.upperTitle}>
          Macera, Adrenalin, Mutluluk...
        </Title>
        <Description className={styles.description}>
          Eğlencenin merkezine hoş geldin!
        </Description> */}

        <Description className={[styles.description, "text-4xl font-bold"].join(" ")}>İşlem Geçmişi</Description>

        <div className="flex items-center gap-6 mb-6">
          <div className="flex flex-col items-center">
            <h5 className="text-base">Başlama Tarihi</h5>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="border rounded-md text-center py-1 text-xl"
              dateFormat={"dd-MM-yyyy"}
              maxDate={new Date()}
            />
          </div>

          <div className="flex flex-col items-center">
            <h5 className="text-base">Bitiş Tarihi</h5>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="border rounded-md text-center py-1 text-xl"
              dateFormat={"dd-MM-yyyy"}
              maxDate={new Date()}
            />
          </div>
        </div>

        {/* <div className="flex flex-col md:flex-row items-center gap-2 w-full md:w-1/2">
          <AddBusinessesInput
            placeholder="Tarih Seçiniz  --/--/----"
            className=" h-[45px] w-full text-[16px] flex-grow"
            value={dateInfo}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.length === 2 || inputValue.length === 5) {
                if (inputValue.length > date.length) {
                  setDateInfo(inputValue + "-");
                } else {
                  setDateInfo(inputValue.slice(0, -1));
                }
              } else {
                setDateInfo(inputValue);
              }
            }}
          />

          <button
            className="px-3 py-2.5 bg-white rounded-md w-20 shadow-md border border-gray-100 h-full"
            onClick={() => {
              getMoneyDetails();
            }}
          >
            Bul
          </button>
        </div> */}

        <MoneyHistoryTable
          searching={searching}
          setSearching={setSearching}
          getHandlePageClick={getHandlePageChange}
          totalPageCount={totalPageCount}
          className={[styles.table, "mt-8"].join(" ")}
          moneys={moneyData}
          allMoneyData={allMoneyDetail}
        />
      </Container>
    </div>
  );
};

export default PersonalMoneyHistory;
