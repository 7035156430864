import {
  BgBox,
  AuthBox,
  Header,
  AuthInput,
  Logo,
  Container,
  ForgotLink,
  RegisterLink,
  PasswordInput,
  InputError,
  SubmitButton,
} from "../../../components";
import styles from "./style.module.css";
import bluesplash from "../../../assets/splash/bluesplash.svg";
import cyansplash from "../../../assets/splash/cyansplash.svg";
import okkologo from "../../../assets/okkologo.svg";
import userlogo from "../../../assets/userlogo.svg";
import menu from "../../../assets/menu.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getServerErrorMessage } from "../../../helpers/auth";
import useAuth from "../../../hooks/useAuth";
import { axiosClient } from "../../../api/config";

const schema = yup
  .object({
    username: yup.string().required("Bu alan gerekli"),
    password: yup
      .string()
      .required("Bu alan gerekli")
      .min(8, "Şifre en az 8 karakter olmalı"),
  })
  .required();

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const onSubmit = (data) => {
    loginSystem(data);
  };

  const loginSystem = async (newData) => {
    let username = newData.username;
    let password = newData.password;

    try {
      const response = await axiosClient.post(
        "/users/login",
        JSON.stringify({ username, password }),
        {
          withCredentials: true,
        }
      );

      const token = response?.data?.data?.token;
      const role = response?.data?.data?.user_type;

      setAuth({ token, role });

      if (role === "admin") {
        navigate("/admin", { replace: true });
      } else if (
        role === "personel" ||
        role === "technical_support" ||
        role === "personel_lead"
      ) {
        navigate("/personel", { replace: true });
      } else if (role === "normal") {
        navigate("/", { replace: true });
      } else {
        navigate(from, { replace: true });
      }
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink="/profil"
        homeLink={"/"}
      />
      <Container className={styles.container}>
        <BgBox
          className={styles.bgBox}
          img1={bluesplash}
          img2={cyansplash}
          img1Style="bottom-[45%] left-[66%]"
          img2Style="top-[31%] right-[53%]"
          windowsize={768}
        >
          <AuthBox className={styles.authBox}>
            <Logo className={styles.logo} />
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={styles.formContainer}
            >
              <div className={styles.inputContainer}>
                <AuthInput
                  name="username"
                  className={styles.authInput}
                  placeholder="Kullanıcı Adı"
                  register={register}
                />
                <InputError text={errors.username?.message} />
              </div>

              <div className={styles.inputContainer}>
                <PasswordInput
                  name="password"
                  className={styles.authInput}
                  placeholder="Şifre"
                  register={register}
                />
                <InputError text={errors.password?.message} />
              </div>

              <SubmitButton text="Giriş Yap" />

              {/* <AuthButton className={styles.authButton}>Giriş Yap</AuthButton> */}
            </form>
          </AuthBox>
          <RegisterLink link="Kayıt Ol!" to="/kayit-ol">
            Yeni misin?
          </RegisterLink>
          <ForgotLink to="/sifremi-unuttum">Şifremi Unuttum!</ForgotLink>
          <ForgotLink to="/">Giriş Yapmadan Devam Et</ForgotLink>
        </BgBox>
      </Container>
    </div>
  );
};

export default Login;
