import styles from "./style.module.css";
import plus from "../../../assets/plus.svg";
import { Link } from "react-router-dom";

const AddBusinessesBox = ({ children, className, ...props }) => {
  return (
    <Link className={[styles.addbusinessesbox, className].join(" ")} {...props}>
      <img src={plus}></img>
    </Link>
  );
};

export default AddBusinessesBox;
