import { useState, useEffect } from "react";
import styles from "./style.module.css";
import okkologo from "../../../assets/okkologo.svg";
import userlogo from "../../../assets/userlogo.svg";
import menu from "../../../assets/menu.svg";
import { Header, Title, Description, Container, AuthInput, AuthButton, TicketButtonBox } from "../../../components";
import { useContext } from "react";
import AuthContext from "../../../context/auth";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { successMessage } from "../../../helpers/toast";
import { getServerErrorMessage } from "../../../helpers/auth";
import { Link, useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";

const PersonalCheckin = () => {
  const authAxiosClient = useAxiosClient();

  const navigation = useNavigate();

  const [checkinObj, setCheckinObj] = useState({
    // phone: "",
    pnr: "",
  });

  const checkIn = async () => {
    // let newPhone = checkinObj.phone.replace(/[\(\)\s]/g, "");
    // newPhone = "90" + newPhone;
    try {
      const response = await authAxiosClient.post(`/personel/check-in`, {
        // phone: newPhone,
        pnr: checkinObj.pnr,
      });
      successMessage("Başarıyla Check-In Yapıldı!");
      navigation(-1);
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  const register = () => {};

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/"} />
      <Container className={styles.container}>
        {/* <Title className={styles.upperTitle}>
          Macera, Adrenalin, Mutluluk...
        </Title>
        <Description className={styles.description}>
          Eğlencenin merkezine hoş geldin!
        </Description> */}

        <Description className={[styles.description, "text-4xl font-bold"].join(" ")}>Check-In işlemleri</Description>

        <div className=" shadow-md px-16 pt-12 pb-7 w-full md:w-2/3 mx-20 mt-16 border">
          <h4 className="text-3xl text-center mb-12">Check-In Yap</h4>

          <div className="flex flex-col gap-4 mt-16">
            {/* <div className="flex flex-col gap-1 mb-3">
              <label className="text-lg ml-3">Telefon Numarası</label>
              <InputMask
                className={styles.maskInput}
                name="phone"
                placeholder="Telefon Numarası"
                value={checkinObj?.phone}
                onChange={(e) => setCheckinObj({ ...checkinObj, phone: e.target.value })}
                mask="(999) 999 99 99"
              />
            </div> */}

            <div className="flex flex-col gap-1 mb-3">
              <label className="text-lg ml-3">PNR</label>
              <InputMask
                className={styles.maskInput}
                type="number"
                name="pnr"
                placeholder="PNR Numarası"
                value={checkinObj?.pnr}
                onChange={(e) => setCheckinObj({ ...checkinObj, pnr: e.target.value })}
                //mask="(999) 999 99 99"
              />
            </div>

            <AuthButton className="w-full flex justify-center max-w-full text-center drop-shadow-xl mr-2 " onClick={checkIn}>
              Check-In Yap
            </AuthButton>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PersonalCheckin;
