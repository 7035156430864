import {
  BgBox,
  AuthBox,
  Header,
  Logo,
  Container,
  Title,
  OtpInput,
  RegisterLink,
  ForgotLink,
  Footer,
} from "../../../../../components";
import styles from "./style.module.css";
import redsplash from "../../../../../assets/splash/redsplash.svg";
import bluesplash from "../../../../../assets/splash/bluesplash2.svg";
import okkologo from "../../../../../assets/okkologo.svg";
import userlogo from "../../../../../assets/userlogo.svg";
import menu from "../../../../../assets/menu.svg";
import { useTranslation } from "react-i18next";

const NewTelOtp = () => {
  const { t } = useTranslation();
  return (
    <div className="grid grid-rows-layoutAuth">
      <Header
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink="/profil"
        homeLink={"/"}
      />
      <Container className={styles.container}>
        <BgBox
          className={styles.bgBox}
          img1={bluesplash}
          img2={redsplash}
          img1Style="bottom-[13%] left-[52%] rotate-[240deg]"
          img2Style="bottom-[51%] right-[57%] "
          windowsize={900}
        >
          <AuthBox className={styles.authBox}>
            <Logo className={styles.logo} />
            <Title className={styles.title}>
              {t("verifyPhone")} 
            </Title>
            <OtpInput></OtpInput>
          </AuthBox>
          <RegisterLink link={t("signIn")}  to="/giris">
          {t("alreadyHaveAccount")} 
          </RegisterLink>

          <ForgotLink to="/sifremi-unuttum">{t("forgotPassword")} </ForgotLink>
        </BgBox>
      </Container>
      <Footer />
    </div>
  );
};

export default NewTelOtp;
