import styles from "./style.module.css";
import { Link } from "react-router-dom";

const RegisterLink = ({
  className,
  children,
  link = "",
  to = "",
  ...props
}) => {
  const url = to;
  return (
    <div className={[styles.registerlink, className].join(" ")} {...props}>
      <div>{children}</div>
      <Link to={url} className={styles.link}>
        {link}
      </Link>
    </div>
  );
};

export default RegisterLink;
