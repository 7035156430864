import { Link } from "react-router-dom";
import styles from "./style.module.css";
import { ReactComponent as Plus } from "../../assets/plus.svg";

const Personels = ({ className, type, personelsData, ...props }) => {
  return (
    <div className={[styles.personelscont, className].join(" ")} {...props}>
      <div className={styles.personeltitle}>Personel Listesi</div>
      {personelsData.map((personel) => (
        <div className={styles.personelbox} key={personel.id}>
          <div className={styles.leftside}>
            <img
              src={personel.image}
              className={styles.personelimg}
              alt="personel-image"
            />
            <div className={styles.personelname}>{personel.name}</div>
          </div>
          <div className={styles.buttonbox}>
            <Link
              className={styles.button}
              to={`/admin/personeller/${personel.id}`}
            >
              Seç
            </Link>
          </div>
        </div>
      ))}
      <Link className={styles.addbox} to="/admin/personel-ekle">
        <Plus />
      </Link>
    </div>
  );
};

export default Personels;
