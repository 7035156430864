import { useState, useEffect, useContext } from "react";
import { Header, Container, Description, Title, BuyTicketUpperBox, Footer, TicketButtonBox } from "../../../../components";
import styles from "./style.module.css";
import fakeImage1 from "../../../../assets/fakeImages/photo1.png";
import okkologo from "../../../../assets/okkologo.svg";
import userlogo from "../../../../assets/userlogo.svg";
import menu from "../../../../assets/menu.svg";
import logo1 from "../../../../assets/okkologo.svg";
import photo1 from "../../../../assets/fakeImages/photo4.png";
import Calendar from "react-calendar";
import { useNavigate, useParams } from "react-router-dom";
import { getServerErrorMessage } from "../../../../helpers/auth";
import { axiosClient } from "../../../../api/config";
import MiniLoading from "../../../../components/Loading/MiniLoading";
import BusinessContext from "../../../../context/business";
import { ReactComponent as RedArrowLeft } from "../../../../assets/icons/redArrowLeft.svg";
import { ReactComponent as RedArrowRight } from "../../../../assets/icons/redArrowRight.svg";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../../../utils/url";
import { dayRange } from "../../../../constants/numbers";

const ChooseDate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [value, onChange] = useState(new Date());

  const { isletmeId, biletId } = useParams();

  const [apiLoading, setApiLoading] = useState(false);

  const [branch, setBranch] = useState(null);
  const [ticket, setTicket] = useState(null);
  const [hoverDate, setHoverDate] = useState(null);

  const { setSelectedDate } = useContext(BusinessContext);

  useEffect(() => {
    getMultipleResponse();

    return () => {
      setBranch(null);
      setTicket(null);
    };
  }, []);

  const getMultipleResponse = async () => {
    setApiLoading(true);
    try {
      const [branchInfoResponse, ticketInfoResponse] = await Promise.all([
        axiosClient.get(`/users/get-branch/branch=${isletmeId}`),
        axiosClient.get(`/users/get-ticket/${biletId}`),
      ]);

      setBranch(branchInfoResponse?.data?.data?.branch);
      setTicket(ticketInfoResponse.data.data);
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
      setApiLoading(false);
    }
  };

  const convertDate = (dateStr) => {
    let mnths = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    let date = dateStr.toString().split(" ");

    return [date[3], mnths[date[1]], date[2]].join("-");
  };

  const dateRange = (date) => {
    return (
      date <= new Date() - (new Date().getHours() + 1) * 3600000 ||
      date - (new Date().getDay() + dayRange) * 86400000 >= new Date()
    );
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/"} />
      <Container className={styles.container}>
        {/* <Title className={styles.upperTitle}>
          Macera, Adrenalin, Mutluluk...
        </Title>
        <Description className={styles.description}>
          Eğlencenin merkezine hoş geldin!
        </Description> */}
        {apiLoading && <MiniLoading className="mb-8" />}
        {/* <BuyTicketUpperBox
          logo={okkologo}
          photo={`${API_URL}` + branch?.image}
          description={branch?.description}
          imageText={branch?.name}
        /> */}
        <Title className={styles.midTitle}>Bilet Detayları</Title>
        <TicketButtonBox className={styles.ticketButtonBox}>
          <Description className={styles.ticketButtonBoxTitle}>{ticket?.name}</Description>
        </TicketButtonBox>
        <div className={styles.calendarContainer}>
          <Calendar
            onChange={onChange}
            value={value}
            className={styles.calendarBox}
            // activeStartDate={new Date()}
            minDate={new Date()}
            nextLabel={<RedArrowLeft className="mx-4" />}
            prevLabel={<RedArrowRight className="mx-4 rotate-180" />}
            next2Label={null}
            prev2Label={null}
            locale="tr-TR"
            tileDisabled={({ date, view }) => dateRange(date)}
            tileClassName={({ date }) =>
              dateRange(date)
                ? "border cursor-not-allowed text-[#E8E8E8] p-4 border-[#E8E8E8]"
                : "border hover:border-[#0C6DFD]  p-4 border-[#959595]"
            }
            tileContent={({ date, view }) =>
              dateRange(date) ? <p className="text-[#E8E8E8]">Seçilemez</p> : <p className=" text-[#F80A3A]">Seçilebilir</p>
            }
            onClickDay={(value, event) => {
              let date = convertDate(value);
              setSelectedDate(date);
              navigate(`/isletmeler/${isletmeId}/${biletId}/saat-sec?tarih=${date}`);
            }}
            minDetail="month"
          />
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default ChooseDate;
