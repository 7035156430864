import { useState, useEffect } from "react";
import styles from "./style.module.css";
import { Title } from "../../index";
import { ReactComponent as RedCircle } from "../../../assets/redCircle.svg";
import { ReactComponent as BlueCircle } from "../../../assets/blueCircle.svg";
import { ReactComponent as GrayCircle } from "../../../assets/grayCircle.svg";

const ChooseAllHourBox = ({ calendarDetail = [], selectHours = () => {}, type = "" }) => {
  const [selectedHours, setSelectedHours] = useState([]);

  const [visibleItems, setVisibleItems] = useState(4);
  const itemsPerPage = 4;

  const handleLoadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerPage);
  };

  useEffect(() => {
    selectHours(selectedHours);
    // if (type === "personal" && selectedHours.length > 1) {
    //   setSelectedHours([]);
    // }
  }, [selectedHours]);

  const ballStyle = (emptySlots, currentSlot, selectedTime, selectedEndTime) => {
    if (emptySlots > 0 && currentSlot > emptySlots) {
      return "bg-primary";
    } else {
      if (selectedHours.includes(`${selectedTime}-${selectedEndTime}-${currentSlot}`)) {
        return "bg-softBlue";
      } else {
        return "bg-softGray";
      }
    }
  };

  const selectHour = (time, endTime, slot) => {
    const selected = `${time}-${endTime}-${slot}`;
    if (selectedHours.includes(selected)) {
      setSelectedHours(selectedHours.filter((hour) => hour !== selected));
    } else {
      setSelectedHours([...selectedHours, selected]);
    }
  };

  const disabledButton = (emptySlots, currentSlot) => {
    if (emptySlots > 0 && currentSlot > emptySlots) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Title className="text-[#454545] flex flex-row gap-6 md:gap-8 text-base md:text-xl justify-center w-full my-4 font-medium xl:whitespace-nowrap">
        <div className="sm:w-full sm:flex sm:gap-4 sm:justify-center">
          {" "}
          <div className="max-sm:w-full mt-1 flex justify-center">
            {" "}
            <RedCircle className={styles.underCircles} />{" "}
          </div>
          Dolu
        </div>
        <div className="sm:w-full sm:flex sm:gap-4 sm:justify-center">
          {" "}
          <div className="max-sm:w-full mt-1 flex justify-center">
            {" "}
            <GrayCircle className={styles.underCircles} />{" "}
          </div>
          Müsait{" "}
        </div>
        <div className="sm:w-full sm:flex sm:gap-4 sm:justify-center">
          {" "}
          <div className="max-sm:w-full mt-1  flex justify-center">
            {" "}
            <BlueCircle className={styles.underCircles} />{" "}
          </div>{" "}
          Seçildi{" "}
        </div>
      </Title>

      {calendarDetail?.slice(0, visibleItems).map((item, index) => (
        <div className={styles.ticketBox} key={index}>
          <div className="flex text-xl">
            <p>{item?.time?.slice(0, -3)}</p>
            <span className="mx-2">-</span>
            <p>{item?.end_time.slice(0, -3)}</p>
          </div>
          <div className="flex flex-wrap gap-4 mt-4 md:mt-0">
            {Array.from({ length: item?.slots }, (_, indexx) => indexx + 1).map((slot, index2) => (
              <button
                key={index2}
                onClick={() => {
                  selectHour(item?.time, item?.end_time, slot);
                }}
                className={[styles.ballStyle, ballStyle(item?.empty_slots, slot, item?.time, item?.end_time)].join(" ")}
                disabled={disabledButton(item?.empty_slots, slot)}
              ></button>
            ))}
          </div>
        </div>
      ))}

      {visibleItems < calendarDetail?.length && (
        <button
          onClick={handleLoadMore}
          className="border rounded-md bg-blue-500 mx-auto px-8 mb-4 py-2.5 text-white font-semibold"
        >
          Daha Fazla Yükle
        </button>
      )}

      {selectedHours.length > 0 && (
        <div className="flex flex-col items-center text-lg mt-4 mb-4">
          <div className="flex flex-wrap gap-2">
            <p>Seçilen saatler:</p>
            {selectedHours.map((item, index) => (
              <div key={index}>
                <p>{item.split("-")[0].slice(0, -3)}</p>
                {index < selectedHours.length - 1 && <span></span>}
              </div>
            ))}
          </div>

          <div className="flex items-center gap-2">
            <p>Kişi Sayısı: </p>
            <p className="text-primary font-bold">{selectedHours.length}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ChooseAllHourBox;
