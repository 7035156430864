import styles from "./style.module.css";
import { AuthBox, LinkButton, Description, Logo, TimeIcon } from "../../index";
import { useNavigate } from "react-router-dom";

const PagesBox = ({ children, className, link1, link2, ...props }) => {
  const navigate = useNavigate();

  return (
    <div className={[styles.pagesbox, className].join(" ")} {...props}>
      <div
        className="max-sm:w-full cursor-pointer"
        onClick={() => {
          navigate(link1);
        }}
      >
        <AuthBox className={styles.authBox}>
          <Description className={styles.upperBoxTitleText}>
            İşletmeler
          </Description>
          <div className="h-full flex items-center justify-center">
            <Logo className={styles.logo} />
          </div>
        </AuthBox>
        <LinkButton
          className="my-5 mx-10 w-full border-2 border-red-500 "
          to={link1}
        >
          Tümünü Göster
        </LinkButton>
      </div>
      <div
        className="max-sm:w-full cursor-pointer"
        onClick={() => {
          navigate(link2);
        }}
      >
        <AuthBox className={styles.authBox}>
          <Description className={styles.upperBoxTitleText}>
            Bilet PNR Sorgula
          </Description>
          <div className="h-full flex items-center justify-center">
            <TimeIcon className={styles.logo} />
          </div>
        </AuthBox>
        <LinkButton
          className="bg-[#ffffff] my-5 mx-10 w-full border-2 border-red-500 text-red-500"
          to={link2}
        >
          İncele
        </LinkButton>
      </div>
    </div>
  );
};

export default PagesBox;
