import { useState, useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
} from "@react-pdf/renderer";
import okkologo from "../assets/okko-logo.png";

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
  fontStyle: "normal",
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    width: "100%",
    height: "100%",
    fontFamily: "Roboto",
    paddingLeft: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  imageSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
    width: 100,
    height: 100,
  },
  //

  pagesbox: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    marginTop: "5px",
    width: "100%",
  },
  upperBoxTitleText: {
    marginBottom: "2px",
  },
  upperBoxDescriptionText: {
    fontSize: 12,
  },
  upperBoxDescriptionText2: {
    display: "flex",
    marginBottom: "2px",
    fontSize: 16,
  },
  upperBoxDescriptionPnrText: {
    width: "100%",
    display: "flex",
    marginBottom: "2px",
    marginLeft: "2px",
    justifyContent: "center",
    fontSize: 16,
  },
  ticketInfoContainer: {
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#ffffff",
    padding: "6px",
    flex: "1",
    width: "100%",
    borderRadius: "0.375rem",
  },
  logo: {
    width: "92px",
    height: "92px",
    marginBottom: "10px",
  },
  showAllButton: {
    margin: "5px",
    marginLeft: "10px",
    marginRight: "10px",
    width: "100%",
  },
  detailButton: {
    backgroundColor: "#ffffff",
    margin: "5px",
    marginLeft: "10px",
    marginRight: "10px",
    width: "100%",
    border: "2px solid #F80A3A",
    color: "#F80A3A",
  },
  branchName: {
    textAlign: "center",
    marginBottom: 8,
  },
  ticketButtonBoxTitle: {
    textAlign: "center",
    marginBottom: "20",
  },
  gridSection: {
    display: "flex",
    marginBottom: "10",
  },
  greenText: {
    color: "green",
  },
  redText: {
    color: "red",
  },

  valueText: {
    color: "black",
  },
});

const Ticket = () => {
  const [ticket, setTicket] = useState(null);

  const searchParams = new URLSearchParams(location.search);
  const ticketStr = searchParams.get("bilet") || "";

  useEffect(() => {
    setTicket(JSON.parse(ticketStr));
  }, [ticketStr]);

  return (
    <PDFViewer className="w-full h-screen">
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.imageSection}>
            <Image style={styles.image} src={okkologo} />
          </View>

          <Text style={styles.branchName}>{ticket?.branch_name}</Text>

          <Text style={styles.ticketButtonBoxTitle}>{ticket?.ticket_name}</Text>

          <View style={styles.container}>
            <View style={styles.leftSide}>
              <View style={styles.gridSection}>
                <Text style={styles.upperBoxTitleText}>
                  İsim Soyisim:{" "}
                  <Text style={styles.valueText}>{ticket?.name}</Text>
                </Text>
              </View>

              <View style={styles.gridSection}>
                <Text style={styles.upperBoxTitleText}>
                  Telefon Numarası: {ticket?.phone}
                </Text>
              </View>

              <View style={styles.gridSection}>
                <Text style={styles.upperBoxTitleText}>
                  Tarih: {ticket?.meeting_date}
                </Text>
              </View>

              <View style={styles.gridSection}>
                <Text style={styles.upperBoxTitleText}>
                  Saat: {ticket?.meeting_time} - {ticket?.meeting_end_time}
                </Text>
              </View>

              <View style={styles.gridSection}>
                <Text style={styles.upperBoxTitleText}>
                  Mekan: {ticket?.branch_name}
                </Text>
              </View>

              <View style={styles.gridSection}>
                <Text style={styles.upperBoxTitleText}>
                  Toplam Fiyat: {ticket?.price || ticket?.ticket_price}₺ (
                  {ticket?.cash} NAKİT)
                </Text>
              </View>

              <View style={styles.gridSection}>
                <Text style={styles.upperBoxTitleText}>
                  Ödeme Durumu:{" "}
                  {ticket?.is_purchased ? (
                    <Text style={{ color: "green" }}>Ödeme Alındı</Text>
                  ) : (
                    <Text style={{ color: "red", fontWeight: "bold" }}>
                      Ödeme Alınmadı
                    </Text>
                  )}
                </Text>
              </View>

              <View style={styles.gridSection}>
                <Text style={styles.upperBoxTitleText}>
                  Check-In Durumu:{" "}
                  {ticket?.is_check_in || ticket?.meeting_check_in ? (
                    <Text style={styles.greenText}>Check-In Yapıldı</Text>
                  ) : (
                    <Text style={styles.redText}>Check-In Yapılmadı</Text>
                  )}
                </Text>
              </View>

              <View style={styles.gridSection}>
                <Text style={styles.upperBoxTitleText}>
                  Kullanım Durumu:{" "}
                  {ticket?.is_completed ? (
                    <Text style={styles.greenText}>Kullanıldı</Text>
                  ) : (
                    <Text style={styles.redText}>Henüz Kullanılmadı</Text>
                  )}
                </Text>
              </View>
            </View>

            <View style={styles.rightSide}>
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                {/*ticket?.qr_code && (
                  <QRCodeSVG
                    value={ticket?.phone + "-" + ticket?.qr_code}
                    id="myCanvas"
                    style={{
                      width: 200,
                      height: 200,
                      minWidth: 200,
                      minHeight: 200,
                    }}
                  />
                )*/}

                <Text>PNR: {ticket?.qr_code}</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default Ticket;
