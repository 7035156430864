import { useState, useEffect } from "react";
import {
  Header,
  Container,
  Description,
  Title,
  BuyTicketUpperBox,
  Footer,
  TicketButtonBox,
  SSSBox,
  AuthBox,
  TicketBox,
  AuthButton,
} from "../../../components";

import fakeImage1 from "../../../assets/fakeImages/photo1.png";
import okkologo from "../../../assets/okkologo.svg";
import userlogo from "../../../assets/userlogo.svg";
import menu from "../../../assets/menu.svg";
import logo1 from "../../../assets/okkologo.svg";
import photo1 from "../../../assets/fakeImages/photo4.png";
import qrCode from "../../../assets/fakeImages/qrCode.png";
import { useNavigate, useParams } from "react-router-dom";
import { getServerErrorMessage } from "../../../helpers/auth";
import { axiosClient } from "../../../api/config";
import { errorMessage, successMessage } from "../../../helpers/toast";
import InputMask from "react-input-mask";
import styles from "./style.module.css";
import useAxiosClient from "../../../hooks/useAxiosClient";
import QrReader from "react-qr-scanner";
import useWindowSize from "../../../hooks/useWindowSize";
import { ReactComponent as SwitchCamera } from "../../../assets/icons/switch-camera.svg";

const PersonelSearchPnr = () => {
  const navigate = useNavigate();

  const authAxiosClient = useAxiosClient();

  const size = useWindowSize();

  const [pnrObj, setPnrObj] = useState({
    phone: "",
    pnr: "",
  });
  const [isQr, setIsQr] = useState(false);

  const [ticket, setTicket] = useState(null);
  const [newGameTime, setNewGameTime] = useState("");
  const [qrState, setQrState] = useState(null);
  // const [facingMode, setFacingMode] = useState("environment");

  useEffect(() => {
    if (qrState) {
      setPnrObj({
        // phone: qrState.split("-")[0].slice(2),
        // pnr: qrState.split("-")[1],
        pnr: qrState,
      });
      setIsQr(false);
      setQrState(null);
    }
  }, [qrState]);

  useEffect(() => {
    if (pnrObj.pnr.length > 7) {
      getPnr();
    }
  }, [pnrObj]);

  const getPnr = async () => {
    /*
    let newPhone = pnrObj.phone.replace(/[\(\)\s]/g, "");
    newPhone = "90" + newPhone;
    */
    try {
      const response = await authAxiosClient.post("/personel/get-pnr", {
        // phone: newPhone,
        pnr: pnrObj.pnr,
      });
      setTicket(response?.data?.data);
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  const changeMeetingTime = async () => {
    try {
      const response = await authAxiosClient.post(`/personel/change-meeting/meeting-details-id=${ticket?.meeting_details_id}`, {
        game_time: newGameTime,
      });
      successMessage("Oyun Saati Değiştirildi");
      getPnr();
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    setPnrObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckin = async (phone, qr_code) => {
    try {
      const response = await authAxiosClient.post(`/personel/check-in`, {
        phone: phone,
        pnr: qr_code,
      });
      successMessage("Başarıyla Check-In Yapıldı!");
      getPnr();
      // navigation(-1);
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  const useTicket = async (phone, qr_code) => {
    try {
      const response = await authAxiosClient.post(`/personel/complete`, {
        phone: phone,
        pnr: qr_code,
      });
      successMessage("Bilet Başarıyla Kullanıldı!");
      getPnr();
      // navigation(-1);
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/"} />
      <Container className={[styles.container, "overflow-hidden"].join(" ")}>
        {/* <Title className={styles.upperTitle}>
          Macera, Adrenalin, Mutluluk...
        </Title>
        <Description className={styles.description}>
          Eğlencenin merkezine hoş geldin!
        </Description> */}

        <Description className={[styles.description, "text-4xl font-bold"].join(" ")}>Bilet İşlemleri</Description>

        <div className="flex flex-col w-full mx-auto items-center">
          {isQr && (
            <>
              <QrReader
                delay={100}
                style={{
                  height: 240,
                  width: 320,
                }}
                onError={(e) => {
                  errorMessage("QR Kod Okunamadı");
                }}
                onScan={(data) => {
                  setQrState(data?.text);
                }}
                constraints={{
                  video: {
                    // facingMode: { exact: `environment` },
                    facingMode: "environment",
                  },
                }}
              />

              {/* <button
                className="mt-4 mb-6"
                onClick={() => {
                  setFacingMode(facingMode === "environment" ? "front" : "environment");
                }}
              >
                <SwitchCamera width={28} height={28} />
              </button> */}
            </>
          )}

          <div className="flex items-center">
            <Title className={styles.upperTitle}>PNR SORGULA</Title>
            <AuthButton
              className="px-10"
              onClick={() => {
                setIsQr(!isQr);
              }}
            >
              {isQr ? "QR KAPAT" : "QR OKUT"}
            </AuthButton>
          </div>

          {/* <InputMask
            className={styles.maskInput}
            name="phone"
            placeholder="Telefon Numarası"
            value={pnrObj.phone}
            onChange={handleInputChange}
            mask="(999) 999 99 99"
          /> */}

          <InputMask
            className={styles.maskInput}
            type="text"
            name="pnr"
            placeholder="PNR Numarası"
            value={pnrObj.pnr}
            onChange={handleInputChange}
            //mask="(999) 999 99 99"
          />

          <AuthButton
            className="px-8"
            onClick={() => {
              getPnr();
            }}
          >
            PNR Sorgula
          </AuthButton>
        </div>

        {ticket && (
          <>
            <div className="mt-10 w-full">
              <TicketBox
                logo={logo1}
                photo={photo1}
                ticket={ticket}
                handleCheckin={(phone, qr) => {
                  handleCheckin(phone, qr);
                }}
                handleUseTicket={(phone, qr) => {
                  useTicket(phone, qr);
                }}
              />
              {/* <button
              className="w-full"
              onClick={() => {
                setReasonBox(true);
              }}
            >
              <Title className={styles.rejectButton}>İptal Et</Title>
            </button> */}
            </div>
            {/* change time */}

            <div className="flex flex-col w-full mx-auto items-center mt-16">
              <Title className={styles.upperTitle}>OYUN SAATİ DEĞİŞTİR</Title>
              <InputMask
                className={styles.maskInput}
                name="time"
                placeholder="Saati Giriniz"
                value={newGameTime}
                onChange={(e) => {
                  setNewGameTime(e.target.value);
                }}
                mask="99:99"
              />

              <AuthButton
                className="px-8"
                onClick={() => {
                  changeMeetingTime();
                }}
              >
                Oyun Saatini Değiştir
              </AuthButton>
            </div>
          </>
        )}

        {/* <Description className={styles.sssDescription}>
            SSS (Sıkça Sorulan Sorular)
          </Description>

          <SSSBox questions={questions} answers={answers} /> */}
      </Container>
      <Footer />
    </div>
  );
};

export default PersonelSearchPnr;
