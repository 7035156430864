import styles from "./style.module.css";
import { AddBusinessesInput, AuthBox, Title } from "../../../index";
import AddressMap from "../../../AddressMap";
import { ReactComponent as Phone } from "../../../../assets/phone.svg";

const AddAdress = ({
  children,
  address,
  setAddress,
  phone,
  setPhone,
  location,
  setLocation,
  className,
  ...props
}) => {
  return (
    <div className={"grid md:grid-cols-2 sm:grid-cols-1 gap-4 w-full"}>
      <AuthBox className={styles.authBox3}>
        <div className="flex flex-row w-full justify-start gap-4 ">
          <Phone className="" width={40} height={40} />
          <Title className="text-[#454545] font-[500] font-mainFont">
            İletişim{" "}
          </Title>
        </div>
        <Title className={"text-start w-full flex  text-2xl mb-2 font-[400]"}>
          Adres:
        </Title>
        <AddBusinessesInput
          className="w-full"
          value={address}
          onChange={(e) => {
            setAddress(e.target.value);
          }}
          placeholder="Adres girin."
        />
        <Title
          className={"text-start w-full flex mt-8 text-2xl mb-2 font-[400]"}
        >
          Telefon Numarası:
        </Title>
        <AddBusinessesInput
          className="w-full"
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
          placeholder="Telefon numarası girin."
        />
        <Title
          className={"text-start w-full flex mt-8 text-2xl mb-2 font-[400]"}
        >
          Lokasyon (link):
        </Title>
        <AddBusinessesInput
          className="w-full"
          value={location}
          onChange={(e) => {
            setLocation(e.target.value);
          }}
          placeholder="Lokasyon linki girin."
        />
      </AuthBox>
      <AuthBox className={styles.authBox4}>
        <AddressMap
          location={location.includes("goo.gl/maps/") ? location : ""}
        />
      </AuthBox>
    </div>
  );
};

export default AddAdress;
