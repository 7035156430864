import { useState, useEffect } from "react";
import styles from "./style.module.css";
import okkologo from "../../../assets/okkologo.svg";
import userlogo from "../../../assets/userlogo.svg";
import menu from "../../../assets/menu.svg";
import { Header, Description, Container } from "../../../components";
import { useContext } from "react";
import AuthContext from "../../../context/auth";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { getServerErrorMessage } from "../../../helpers/auth";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const PersonalMonthlyMoney = () => {
  const { auth } = useContext(AuthContext);

  const authAxiosClient = useAxiosClient();

  const navigation = useNavigate();
  const [monthlyData, setMonthlyData] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const [monthlyData] = await Promise.all([authAxiosClient.get(`/personel/get-monthly-rewards`)]);

      setMonthlyData(monthlyData?.data?.data);
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  const register = () => {};

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/"} />
      <Container className={styles.container}>
        {/* <Title className={styles.upperTitle}>
          Macera, Adrenalin, Mutluluk...
        </Title>
        <Description className={styles.description}>
          Eğlencenin merkezine hoş geldin!
        </Description> */}

        <Description className={[styles.description, "text-4xl font-bold"].join(" ")}>Aylık Ciro Dökümü</Description>

        <div className="w-full max-w-96 flex flex-col gap-4">
          {monthlyData?.daily_reward_attendance?.map((item, index) => (
            <div key={index} className="flex justify-between">
              <p>{moment(item?.date).format("DD.MM.YYYY")}</p>
              <p className="font-bold">{item?.total_reward} TL</p>
            </div>
          ))}
          <div className="flex justify-between mt-4">
            <p>Toplam</p>
            <p className="font-bold text-xl">{monthlyData?.total_reward} TL</p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PersonalMonthlyMoney;
