import styles from "./style.module.css";

const SubmitButton = ({ text = "", className, loading, ...props }) => {
  return (
    <input
      type="submit"
      value={loading ? "Loading..." : text}
      className={[styles.submitButton, "bg-primary", className].join(" ")}
      {...props}
    />
  );
};

export default SubmitButton;
