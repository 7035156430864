import styles from "./style.module.css";
import useWindowSize from "../../../hooks/useWindowSize";
const BgBox = ({
  children,
  img1,
  img2,
  className,
  img1Style,
  img2Style,
  windowsize,
  ...props
}) => {
  const size = useWindowSize();

  return (
    <div className={[styles.bgBox, className].join(" ")}>
      {size.width > windowsize && (
        <img
          src={img1}
          alt=""
          className={[styles.img1, img1Style].join(" ")}
          {...props}
        />
      )}
      {children}
      {size.width > windowsize && (
        <img
          src={img2}
          alt=""
          className={[styles.img2, img2Style].join(" ")}
          {...props}
        />
      )}
    </div>
  );
};

export default BgBox;
