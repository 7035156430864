import { useState, useEffect } from "react";
import styles from "./style.module.css";
import okkologo from "../../../assets/okkologo.svg";
import userlogo from "../../../assets/userlogo.svg";
import menu from "../../../assets/menu.svg";
import {
  Header,
  Description,
  Container,
  AuthInput,
  AuthButton,
  TicketButtonBox,
  ChooseAllHourBox,
} from "../../../components";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { successMessage } from "../../../helpers/toast";
import { getServerErrorMessage } from "../../../helpers/auth";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import { getFormattedDate } from "../../../helpers/year";
import DatePrettier from "../../../components/DatePrettier";
import MiniLoading from "../../../components/Loading/MiniLoading";
import { cameraPrice } from "../../../constants/numbers";
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

const PersonalCreateMeeting = () => {
  const authAxiosClient = useAxiosClient();
  const navigation = useNavigate();

  const thirdPartyCompanies = [
    "Bilet.com",
    "Parkbileti.com",
    "Biletix",
    "İstanbul Tourist Pass",
    "İstanbul.com",
    "Get Your Guide",
    "Tıqets",
    "Musement.Com",
    "TemaPark",
  ];

  const [meetingObj, setMeetingObj] = useState({
    game_time: "",
    ticket_price: "", //number
    name: "",
    email: "",
    phone: "",
    tc: "11111111111",
    cash: true,
    user_number: 0,
    campaign_code: "",
    third_campaign_code: "",
    firma: "",
    is_want_to_get_invoice: false,
    sms_verification: "",
    is_want_camera: false,
    camera_count: 0,
  });

  const [tickets, setTickets] = useState([]);
  const [activeTicket, setActiveTicket] = useState(null);
  const [todayDate, setTodayDate] = useState("");
  const [calendarDetail, setCalendarDetail] = useState(null);
  const [selectedHours, setSelectedHours] = useState([]);
  const [activeCountry, setActiveCountry] = useState("TR");

  const [isIndirim, setIsIndirim] = useState(false);
  const [indirimRate, setIndirimRate] = useState(0);
  const [whoTicket, setWhoTicket] = useState(1);
  const [whoCode, setWhoCode] = useState(1);

  const [isConsented, setIsConsented] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getTickets();
    setTodayDate(getFormattedDate(new Date()));
    // getCalendarDetail();
  }, []);

  useEffect(() => {
    getCalendarDetail();
  }, [todayDate, activeTicket]);

  useEffect(() => {
    setMeetingObj({
      ...meetingObj,
      ticket_price: activeTicket?.price,
    });
  }, [tickets, activeTicket]);

  const handleCheckboxChange = () => {
    setIsConsented(!isConsented);
  };

  const getCampaignCode = async () => {
    try {
      const response = await authAxiosClient.post(
        "/personel/get-campaign-code",
        {
          campaign_code: meetingObj.campaign_code,
        }
      );

      let is_indirim = response.data.data.is_indirim;
      let indirim_rate = response.data.data.indirim_rate;

      setIsIndirim(is_indirim);
      setIndirimRate(indirim_rate);
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  const getCalendarDetail = async () => {
    try {
      const [calendarDetailResponse] = await Promise.all([
        authAxiosClient.get(
          `/personel/get-calendar-day-detail/ticket-id=${activeTicket?.id}`,
          JSON.stringify({ date: todayDate })
        ),
      ]);

      setCalendarDetail(calendarDetailResponse?.data?.data);
    } catch (error) {
      getServerErrorMessage(error);
    }
  };

  const getTickets = async () => {
    try {
      const response = await authAxiosClient.get(
        `/personel/get-branch-tickets`
      );
      setTickets(response?.data?.data);
      console.log("TİCKETS: ", response?.data?.data);
      setActiveTicket(response?.data?.data[0]);
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  const createNewMeeting = async () => {
    setIsLoading(true);
    let newPhone = meetingObj.phone.replace(/[\(\)\s]/g, "");
    newPhone = newPhone.replace("+", "");

    let newCampaignCode;

    if (meetingObj?.campaign_code?.length > 0) {
      newCampaignCode = meetingObj?.campaign_code;
    } else {
      newCampaignCode = null;
    }

    try {
      const response = await authAxiosClient.post(
        `/personel/create-new-meeting/ticket-id=${activeTicket?.id}`,
        {
          // ...meetingObj,
          game_time: selectedHours[0]?.split("-")[0],
          user_number: selectedHours.length,
          is_cash: meetingObj.cash,
          name: meetingObj.name,
          email: meetingObj.email,
          phone: newPhone,
          tc: meetingObj.tc,
          campaign_code: newCampaignCode,
          third_campaign_code: meetingObj.third_campaign_code,
          firma: meetingObj.firma,
          sms_verification: meetingObj.sms_verification,
          is_want_to_get_invoice: meetingObj.is_want_to_get_invoice,
          is_want_camera: meetingObj.is_want_camera,
          camera_count: meetingObj.camera_count,
        }
      );
      successMessage("Başarıyla Yeni Randevu Oluşturuldu!");
      // clear Inputs
      setMeetingObj({
        game_time: "",
        ticket_price: "", //number
        name: "",
        email: "",
        phone: "",
        tc: "11111111111",
        cash: true,
        user_number: 0,
        sms_verification: "",
        campaign_code: null,
        third_campaign_code: "",
        firma: "",
        is_want_to_get_invoice: false,
      });
      // window.location.reload();

      // navigation(-1);
    } catch (err) {
      getServerErrorMessage(err);
    } finally {
      setIsLoading(false);
    }
  };

  const register = () => {};

  const sendSmsToUser = async () => {
    let newPhone = meetingObj.phone.replace(/[\(\)\s]/g, "");
    newPhone = newPhone.replace("+", "");

    try {
      const response = await authAxiosClient.post(
        `/personel/send-sms-to-user/ticket-id=${activeTicket?.id}`,
        {
          phone: newPhone,
        }
      );
      successMessage("Müşteriye SMS Gönderildi.");
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  const getTotalCameraPrice = () => {
    return meetingObj.camera_count * cameraPrice || 0;
  };

  const getCalculatedPrice = () => {
    if (whoCode == 2) {
      return 0;
    }
    if (isIndirim == true) {
      // yüzde
      let lastTicketPrice2 =
        selectedHours.length * meetingObj.ticket_price -
        (selectedHours.length * meetingObj.ticket_price * indirimRate) / 100;

      return lastTicketPrice2 + getTotalCameraPrice();
    } else {
      // number
      let lastTicketPrice2 =
        selectedHours.length * meetingObj.ticket_price - indirimRate;

      return lastTicketPrice2 + getTotalCameraPrice();
    }
  };

  /*
  const handleCompanySelect = (item) => {
    setSelectedItem(item);
  };
  */

  const incrementCameraCount = () => {
    setMeetingObj({
      ...meetingObj,
      camera_count: meetingObj.camera_count + 1,
    });
  };

  const decrementCameraCount = () => {
    setMeetingObj({
      ...meetingObj,
      camera_count: Math.max(0, meetingObj.camera_count - 1),
    });
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink="/profil"
        homeLink={"/"}
      />
      <Container className={styles.container}>
        <Description
          className={[styles.description, "text-4xl font-bold"].join(" ")}
        >
          Randevu İşlemleri
        </Description>

        <div className="shadow-md px-4 lg:px-16 pt-12 pb-7 w-full md:w-2/3 flex flex-col items-center mt-16">
          <DatePrettier selectedDate={todayDate} />
          <h4 className="text-3xl text-center mb-12">Bilet Satış</h4>

          <div className={styles.countrySelectionContainer}>
            <h4 className={styles.questionTitle}>Müşterinin Ülkesi</h4>

            <div className="flex items-center gap-3">
              <button
                className={[
                  "border rounded-md py-2 w-40 font-bold",
                  activeCountry === "TR"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-50",
                ].join(" ")}
                onClick={() => {
                  setActiveCountry("TR");
                }}
              >
                Türkiye
              </button>
              <button
                className={[
                  "border rounded-md py-2 w-40 font-bold",
                  activeCountry === "OTHER"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-50",
                ].join(" ")}
                onClick={() => {
                  setActiveCountry("OTHER");
                }}
              >
                Diğer
              </button>
            </div>
          </div>

          {tickets.map((ticket, index) => {
            let showTicket =
              activeCountry === "TR"
                ? !ticket.name.includes("YABANCI")
                : ticket.name.includes("YABANCI");

            if (!showTicket) return null;

            return (
              <TicketButtonBox
                key={index}
                onClick={() => {
                  setActiveTicket(ticket);
                  document.getElementById("bottom").scrollIntoView();
                }}
                className={[
                  styles.ticketButtonBox,
                  activeTicket?.id === ticket?.id
                    ? "bg-primary text-white font-semibold"
                    : "",
                ].join(" ")}
              >
                <div
                  className={
                    "flex flex-row justify-between w-full max-sm:flex-wrap"
                  }
                >
                  <Description
                    className={[
                      styles.ticketButtonBoxTitle,
                      activeTicket?.id === ticket?.id ? "text-white" : "",
                    ].join(" ")}
                  >
                    {ticket?.name}
                  </Description>
                  <Description
                    className={[
                      styles.ticketButtonBoxPrice,
                      activeTicket?.id === ticket?.id ? "text-white" : "",
                    ].join(" ")}
                  >
                    {ticket?.price} TL
                  </Description>
                  <span className="max-sm:w-full justify-center items-center flex">
                    Seç
                  </span>
                </div>
              </TicketButtonBox>
            );
          })}

          <div className="mt-6 mr-auto scroll-mt-4" id="bottom">
            <h4 className={styles.questionTitle}>
              Bileti Kimin Adına Alıyorsunuz?
            </h4>

            <div className="flex items-center gap-3">
              <button
                className={[
                  "border rounded-md py-2 w-40 font-bold",
                  whoTicket == 1 ? "bg-blue-500 text-white" : "bg-gray-50",
                ].join(" ")}
                onClick={() => {
                  setWhoTicket(1);
                }}
              >
                Kendi Adıma
              </button>
              <button
                className={[
                  "border rounded-md py-2 w-40 font-bold",
                  whoTicket == 2 ? "bg-blue-500 text-white" : "bg-gray-50",
                ].join(" ")}
                onClick={() => {
                  setWhoTicket(2);
                }}
              >
                Bir Başkasının Adına
              </button>
            </div>
          </div>

          <div className="flex flex-col gap-1 mb-3 w-full mt-8">
            <label className="text-lg ml-3">Bilet Fiyatı</label>

            <AuthInput
              placeholder="Bilet Fiyatı"
              className={"w-full flex max-w-full drop-shadow-xl mr-2 "}
              register={register}
              name="ticket_price"
              readOnly
              value={meetingObj.ticket_price}
              onChange={(e) =>
                setMeetingObj({ ...meetingObj, ticket_price: e.target.value })
              }
            />
          </div>

          <div className="flex flex-col gap-4 mt-8 w-full">
            <div className="flex flex-col gap-1 mb-3">
              <label className="text-lg ml-3">İsim Soyisim</label>
              <AuthInput
                placeholder="İsim Soyisim"
                className={"w-full flex max-w-full drop-shadow-xl mr-2 "}
                register={register}
                name="name"
                value={meetingObj.name}
                onChange={(e) =>
                  setMeetingObj({ ...meetingObj, name: e.target.value })
                }
              />
            </div>

            <div className="flex flex-col gap-1 mb-3">
              <label className="text-lg ml-3">Email</label>
              <AuthInput
                placeholder="E-mail"
                className={"w-full flex max-w-full drop-shadow-xl mr-2 "}
                register={register}
                name="email"
                value={meetingObj.email}
                onChange={(e) =>
                  setMeetingObj({ ...meetingObj, email: e.target.value })
                }
              />
            </div>

            <div className="flex flex-col mb-3">
              <label className="text-lg ml-3">Telefon Numarası</label>
              <div className="flex items-center gap-1">
                <PhoneInput
                  className={[
                    styles.inputMaskStyle,
                    "flex-1 outline-none",
                  ].join(" ")}
                  defaultCountry="TR"
                  international
                  placeholder="Telefon Numarası (---) --- -- --"
                  value={meetingObj.phone}
                  onChange={(phone) => {
                    setMeetingObj({ ...meetingObj, phone });
                  }}
                />
                <button
                  onClick={sendSmsToUser}
                  className={styles.sendSmsButton}
                >
                  Onay kodu Gönder
                </button>
              </div>

              <div className="flex flex-col gap-1 mb-3 mt-8">
                <label className="text-lg ml-3">SMS Onay Kodu</label>
                <InputMask
                  className={[styles.inputMaskStyle].join(" ")}
                  name="sms"
                  placeholder="SMS Onay Kodu"
                  value={meetingObj?.sms_verification}
                  onChange={(e) =>
                    setMeetingObj({
                      ...meetingObj,
                      sms_verification: e.target.value,
                    })
                  }
                />
                <div className="flex items-center gap-1 mt-3 pl-2">
                  <input
                    type="checkbox"
                    id="consentCheckbox"
                    checked={isConsented}
                    onChange={handleCheckboxChange}
                    className="h-6 w-6 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  />
                  <label
                    htmlFor="consentCheckbox"
                    className="ml-2 text-sm text-gray-700"
                  >
                    {"SMS'te gönderilen beyanları okudum, onaylıyorum."}
                  </label>
                </div>
              </div>
            </div>

            <ChooseAllHourBox
              className={styles.chooseAllHourBox}
              selectedDate={todayDate}
              calendarDetail={calendarDetail}
              selectHours={(hours) => {
                setSelectedHours(hours);
              }}
            />

            <div className="flex items-center gap-3 mt-6">
              <button
                className={[
                  "border rounded-md py-2 w-40 font-bold",
                  whoCode == 1 ? "bg-blue-500 text-white" : "bg-gray-50",
                ].join(" ")}
                onClick={() => {
                  setWhoCode(1);
                }}
              >
                OKKO Kampanya Kodu
              </button>
              <button
                className={[
                  "border rounded-md py-2 w-40 font-bold",
                  whoCode == 2 ? "bg-blue-500 text-white" : "bg-gray-50",
                ].join(" ")}
                onClick={() => {
                  setWhoCode(2);
                }}
              >
                Diğer Firmaların Kodu
              </button>
            </div>

            {whoCode == 1 && (
              <div className="flex flex-col mt-4 mb-6">
                <div className="flex flex-col gap-1 mb-3 w-full">
                  <label className="text-lg ml-3">OKKO Kampanya Kodu</label>
                  <AuthInput
                    placeholder="Kampanya Kodu"
                    className={"w-full flex max-w-full drop-shadow-xl mr-2 "}
                    register={register}
                    name="campaign_code"
                    value={meetingObj.campaign_code}
                    onChange={(e) =>
                      setMeetingObj({
                        ...meetingObj,
                        campaign_code: e.target.value,
                      })
                    }
                  />
                </div>

                <button
                  onClick={getCampaignCode}
                  className="whitespace-nowrap px-1.5 py-3 mr-auto text-white font-semibold rounded-md border border-primary bg-primary"
                >
                  Kodu Uygula
                </button>
              </div>
            )}

            {whoCode == 2 && (
              <div className="flex items-center gap-8 mt-6">
                <div className="flex flex-col w-1/2">
                  <AuthInput
                    placeholder="3. Parti Kampanya Kodu"
                    className={"w-full flex max-w-full drop-shadow-xl mr-2 "}
                    register={register}
                    name="third_campaign_code"
                    value={meetingObj.third_campaign_code}
                    onChange={(e) =>
                      setMeetingObj({
                        ...meetingObj,
                        third_campaign_code: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="w-1/2">
                  <select
                    value={meetingObj.firma}
                    onChange={(e) => {
                      setMeetingObj({
                        ...meetingObj,
                        firma: e.target.value,
                      });
                    }}
                    className="border w-full py-2 rounded-md"
                  >
                    <option value="">Seç</option>
                    {thirdPartyCompanies.map((name, index) => (
                      <option key={index} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            {whoCode != 2 && (
              <div className="mt-4">
                <h4 className={styles.questionTitle}>Ödeme Tercihi</h4>

                <div className="flex items-center gap-3">
                  <button
                    className={[
                      "border rounded-md py-2 w-40 font-bold",
                      meetingObj.cash ? "bg-blue-500 text-white" : "bg-gray-50",
                    ].join(" ")}
                    onClick={() => {
                      setMeetingObj({
                        ...meetingObj,
                        cash: true,
                      });
                    }}
                  >
                    Nakit
                  </button>
                  <button
                    className={[
                      "border rounded-md py-2 w-40 font-bold",
                      !meetingObj.cash
                        ? "bg-blue-500 text-white"
                        : "bg-gray-50",
                    ].join(" ")}
                    onClick={() => {
                      setMeetingObj({
                        ...meetingObj,
                        cash: false,
                      });
                    }}
                  >
                    Kredi Kartı
                  </button>
                </div>
              </div>
            )}

            <div className="mt-4">
              <h4 className={styles.questionTitle}>Kamera Tercihi</h4>

              <div className="flex items-center gap-3">
                <div className="flex items-center gap-3">
                  <button
                    className={[
                      "border rounded-md py-2 w-40 font-bold",
                      meetingObj.is_want_camera
                        ? "bg-blue-500 text-white"
                        : "bg-gray-50",
                    ].join(" ")}
                    onClick={() => {
                      setMeetingObj({
                        ...meetingObj,
                        is_want_camera: true,
                      });
                    }}
                  >
                    Kamera İstiyorum
                  </button>
                  <button
                    className={[
                      "border rounded-md py-2 w-40 font-bold",
                      !meetingObj.is_want_camera
                        ? "bg-blue-500 text-white"
                        : "bg-gray-50",
                    ].join(" ")}
                    onClick={() => {
                      setMeetingObj({
                        ...meetingObj,
                        is_want_camera: false,
                      });
                    }}
                  >
                    Kamera İstemiyorum
                  </button>
                </div>

                {meetingObj.is_want_camera && (
                  <div className="flex items-center gap-2">
                    <div className="flex items-center">
                      <button
                        className="text-xl border rounded-md py-1 px-3 font-bold bg-blue-500 text-white mr-2"
                        onClick={decrementCameraCount}
                        disabled={meetingObj?.camera_count <= 0}
                      >
                        -
                      </button>
                      <span className="text-xl w-6 text-center">
                        {meetingObj?.camera_count}
                      </span>
                      <button
                        className="text-xl border rounded-md py-1 px-3 font-bold bg-blue-500 text-white ml-2"
                        onClick={incrementCameraCount}
                        // disabled={meetingObj?.camera_count >= selectedHours.length}
                      >
                        +
                      </button>
                    </div>
                    <span>{getTotalCameraPrice()} TL</span>
                  </div>
                )}
              </div>
            </div>

            <div className="mx-auto mt-4">
              <h3>Toplam Fiyat</h3>
              <h3 className="text-3xl font-bold text-center">
                {getCalculatedPrice()} TL
              </h3>
            </div>

            <AuthButton
              className={[
                "w-full flex justify-center max-w-full text-center drop-shadow-xl mr-2",
                !isConsented || isLoading
                  ? "bg-gray-200 cursor-not-allowed"
                  : "bg-primary cursor-pointer",
              ].join(" ")}
              onClick={createNewMeeting}
              disabled={!isConsented || isLoading}
            >
              {isLoading ? <MiniLoading /> : "Bilet Satış"}
            </AuthButton>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PersonalCreateMeeting;
