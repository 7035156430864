export const convertMonthToTurkish = (month) => {
  const months = {
    January: "Ocak",
    February: "Şubat",
    March: "Mart",
    April: "Nisan",
    May: "Mayıs",
    June: "Haziran",
    July: "Temmuz",
    August: "Ağustos",
    September: "Eylül",
    October: "Ekim",
    November: "Kasım",
    December: "Aralık",
  };

  return months[month];
};
