import { BgBox, AuthBox, Header, Logo, Container, Title, OtpInput, RegisterLink, ForgotLink, Footer } from "../../../../../components";
import styles from "./style.module.css";
import redsplash from "../../../../../assets/splash/redsplash.svg";
import bluesplash from "../../../../../assets/splash/bluesplash2.svg";
import okkologo from "../../../../../assets/okkologo.svg";
import userlogo from "../../../../../assets/userlogo.svg";
import menu from "../../../../../assets/menu.svg";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getServerErrorMessage } from "../../../../../helpers/auth";
import { axiosClient } from "../../../../../api/config";

const NewMailOtp = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const email = searchParams.get("email") || "";

  const controlOtpMail = async (otp) => {
    try {
      const response = await axiosClient.post("/users/email-verify", JSON.stringify({ email, otp }));

      navigate({
        pathname: "/profil/mail-degistir/mail-degistirildi",
        search: `?email=${email}`,
      });
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/"} />
      <Container className={styles.container}>
        <BgBox
          className={styles.bgBox}
          img1={bluesplash}
          img2={redsplash}
          img1Style="bottom-[13%] left-[52%] rotate-[240deg]"
          img2Style="bottom-[51%] right-[57%] "
          windowsize={900}
        >
          <AuthBox className={styles.authBox}>
            <Logo className={styles.logo} />
            <Title className={styles.title}>{t("verifyEmail")}</Title>
            <OtpInput
              type="email"
              //linkText="Farklı telefon numarası ile kayıt ol."

              controlOtp={(otp) => {
                controlOtpMail(otp);
              }}
            />
          </AuthBox>
          <RegisterLink link={t("signIn")} to="/giris">
            {t("alreadyHaveAccount")}
          </RegisterLink>

          <ForgotLink to="/sifremi-unuttum">{t("forgotPassword")}</ForgotLink>
        </BgBox>
      </Container>
      <Footer />
    </div>
  );
};

export default NewMailOtp;
