import { Header, Container, Title, Footer, AuthBox } from "../../../components/index";
import styles from "./style.module.css";
import okkologo from "../../../assets/okkoadminlogo.svg";
import userlogo from "../../../assets/userlogowhite.svg";
import menu from "../../../assets/menu.svg";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { errorMessage } from "../../../helpers/toast";
import { useEffect } from "react";
import { currencyFormatter } from "../../../helpers/currency";
import DatePicker from "react-datepicker";

const TotalMoney = () => {
  const { isletmeId } = useParams();
  const axiosClient = useAxiosClient();

  const navigate = useNavigate();

  const [businessData, setBusinessData] = useState({});
  const [totalMoney, setTotalMoney] = useState(null);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const startDay = startDate.getDate().toString().padStart(2, "0");
  const startMonth = (startDate.getMonth() + 1).toString().padStart(2, "0");
  const startYear = startDate.getFullYear();

  const endDay = endDate.getDate().toString().padStart(2, "0");
  const endMonth = (endDate.getMonth() + 1).toString().padStart(2, "0");
  const endYear = endDate.getFullYear();

  const startDateFormatted = startYear + "-" + startMonth + "-" + startDay;
  const endDateFormatted = endYear + "-" + endMonth + "-" + endDay;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getBusinesses();
  }, []);

  useEffect(() => {
    getTotalMoney();
  }, [businessData, startDate, endDate]);

  const getBusinesses = async () => {
    try {
      const response = await axiosClient.get(`/superuser/get-branch/branch-id=${isletmeId}`);
      if (response.status === 200) {
        setBusinessData(response?.data?.data?.branch);
      }
    } catch (error) {
      errorMessage("İşletmeler getirilirken bir hata oluştu.");
    }
  };

  const getTotalMoney = async () => {
    try {
      const response = await axiosClient.get(
        `/superuser/get-ciro-by-branch/branch-id=${isletmeId}&start-date=${startDateFormatted}&end-date=${endDateFormatted}&filter=day`
      );
      if (response.status === 200) {
        setTotalMoney(response?.data?.data);
      }
    } catch (error) {
      errorMessage("İşletmeler getirilirken bir hata oluştu.");
    }
  };

  return (
    <div className="md:grid grid-rows-layoutAuth">
      <Header className="bg-primary" okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink={"/profil"} homeLink={"/admin"} />
      <Container className={styles.container}>
        {/* <Title className={styles.upperTitle}>Macera, Adrenalin, Mutluluk...</Title>
        <Description className={styles.description}>Eğlencenin merkezine hoş geldin!</Description> */}

        <Title className={[styles.upperTitle, "mb-6"].join(" ")}>{businessData?.name}</Title>

        {/* <BuyTicketUpperBox
          logo={logo1}
          photo={API_URL + businessData?.image}
          description={businessData?.description}
          imageText={businessData?.name}
          className="mb-20"
        /> */}

        <Title className={styles.midTitle}>Şubedeki Toplam Ciro</Title>

        <div className="flex items-center gap-6 mb-6">
          <div className="flex flex-col items-center">
            <h5 className="text-base">Başlama Tarihi</h5>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="border rounded-md text-center py-1 text-xl"
              dateFormat={"dd-MM-yyyy"}
              maxDate={new Date()}
            />
          </div>

          <div className="flex flex-col items-center">
            <h5 className="text-base">Bitiş Tarihi</h5>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="border rounded-md text-center py-1 text-xl"
              dateFormat={"dd-MM-yyyy"}
              maxDate={new Date()}
            />
          </div>
        </div>

        <AuthBox className={styles.authBox1}>
          <Title className={styles.midTitle}>Toplam Ciro</Title>

          <div className="flex flex-col items-start gap-4">
            <div className="flex justify-between gap-6 text-xl w-72">
              <p>Toplam Nakit:</p>
              <p className="font-bold">{currencyFormatter(parseFloat(totalMoney?.total_cash)) + " TL"}</p>
            </div>
            <div className="flex justify-between gap-6 text-xl w-72">
              <p>Toplam Kredi:</p>
              <p className="font-bold">{currencyFormatter(parseFloat(totalMoney?.total_credit)) + " TL"}</p>
            </div>
            <div className="flex justify-between gap-6 text-xl w-72">
              <p>Toplam:</p>
              <p className="font-bold">{currencyFormatter(parseFloat(totalMoney?.total_money)) + " TL"}</p>
            </div>
          </div>
        </AuthBox>
      </Container>

      <Footer />
    </div>
  );
};

export default TotalMoney;
