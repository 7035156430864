import AuthBox from "../../Box/AuthBox";
import styles from "./style.module.css";
import { useState, useRef } from "react";
import cn from "classnames";
import ReactPaginate from "react-paginate";
import { ReactComponent as RedArrow } from "../../../assets/redarrowright.svg";

const PersonelDatabaseTable = ({ className, personels,getHandlePageClick, totalPageCount,setSearching,searching,  ...props }) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");

  const [paginate, setPaginate] = useState(0);
  const [pageData, setPageData] = useState([]); 
  const [pageNumber, setPageNumber] = useState(0);

  const handlePageClick = (data) => {
    let selected = data.selected;
    setPaginate(selected);
    setPageData(personels.slice(selected * 10, selected * 10 + 10));
    getHandlePageClick(selected+1);
  };

  const handleClickKeyDown = (e) => {
    if (e.key === "Enter") {
      setPageData(
        personels.slice(
          (Number(e.target.value) - 1) * 10,
          (Number(e.target.value) - 1) * 10 + 10
        )
      );
      setPaginate(Number(e.target.value) - 1);
      getHandlePageClick(Number(e.target.value));
    }
  };

  /* const handleClick = () => {
    setPageData(
      personels.slice(
        (Number(e.target.value) - 1) * 10,
        (Number(e.target.value) - 1) * 10 + 10
      )
    );
    setPaginate(Number(e.target.value) - 1);
  }; */

  return (
    <div className={[styles.tableCont, className].join(" ")} {...props}>
      <AuthBox className={styles.authBox}>
        <input
          className={styles.input}
          placeholder="İsim Soyisim, Kullanıcı adı, Telefon Numarası"
          value={searching == 'all' ? '' : searching}
          onChange={(e) => setSearching(e.target.value)}
        />
      </AuthBox>
      <AuthBox className={styles.authBox2}>
        <div className="flex flex-col min-w-[1000px] w-full">
          <div className={styles.titles}>
            <div className={styles.title1}>Kullanıcı Adı</div>
            <div className={styles.title2}>İsim Soyisim</div>
            <div className={styles.title3}>Kullanıcı Telefon No</div>
            <div className={styles.title4}>Tarih</div>
            <div className={styles.title5}>Saat (Giriş - Çıkış)</div>
          </div>
          <div className="flex flex-col w-full mt-5  gap-2">
            {personels &&
              personels
               .map((personel, index) => (
                  <div
                    className={cn(
                      styles.datas,
                      index % 2 === 0 ? styles.datas2 : null
                    )}
                    key={index}
                  >
                    <div className={styles.data1}>{personel?.personel_username} </div>
                    <div className={styles.data2}>{personel?.personel_firstname + " " + personel?.personel_lastname}</div>
                    <div className={styles.data3}>{personel?.personel_phone}</div>
                    <div className={styles.data4}>{personel?.date}</div>
                    <div className={styles.data5}>{personel?.start_time?.substring(0,5)+"-"+personel?.end_time?.substring(0,5)}</div>
                  </div>
                ))}
          </div>
        </div>
      </AuthBox>

      <div className={styles.paginateContainer}>
        <ReactPaginate
          className={styles.paginate}
          breakLabel="..."
          nextLabel=">"
          previousLabel="<"
          nextLinkClassName="text-primary"
          previousLinkClassName="text-primary"
          nextClassName={styles.pageNextClassname}
          previousClassName={styles.pagePreviousClassname}
          onPageChange={(e) => handlePageClick(e)}
          pageRangeDisplayed={5}
          pageCount={totalPageCount}
          renderOnZeroPageCount={false}
          forcePage={paginate}
          pageClassName={styles.pageClassname}
          activeClassName={styles.activeClassname}
        />
        {
          <div className={styles.paginateGitContainer}>
            <label htmlFor="Git" className="text-primary">
              Git
            </label>
            <input
              autoComplete="off"
              ref={inputRef}
              maxLength="3"
              placeholder="1"
              className={styles.paginateGitInput}
              type="text"
              id="Git"
              onKeyDown={(e) => {
                handleClickKeyDown(e);
              }}
            />
            <button /*onClick={(e)=> handleClick(e)} */>
              <RedArrow
                width="1.5em"
                height="1.5em"
                className="absolute right-1 top-[5px] text-primary"
              />
            </button>
          </div>
        }
      </div>
    </div>
  );
};

export default PersonelDatabaseTable;
