import React from "react";
import { useState } from "react";
import {
  Header,
  Container,
  Title,
  Description,
  Footer,
  AddBusinessUpperBox,
  TicketDetails,
  AddSSSBox,
  AuthBox,
  AddAdress,
  AuthButton,
} from "../../../../components";
import styles from "./style.module.css";
import okkologo from "../../../../assets/okkoadminlogo.svg";
import userlogo from "../../../../assets/userlogowhite.svg";
import menu from "../../../../assets/menuwhite.svg";
import AddressMap from "../../../../components/AddressMap";
import useAxiosClient from "../../../../hooks/useAxiosClient";
import { successMessage } from "../../../../helpers/toast";
import { useNavigate } from "react-router-dom";

const AddBusiness = () => {
  const axiosClient = useAxiosClient();
  const navigate = useNavigate();
  const questions = ["Watergarden Zipline ve Macera Parkı biletlerini nereden alabilirim?"];

  const answers = [
    "Watergarden Zipline ve Macera Parkı biletini seçeneklerin olduğu etkinlik listesinden seçip, bilgilerinizi doldurarak satın alabilirsiniz.",
  ];

  const [photo, setPhoto] = useState();
  const [businessName, setBusinessName] = useState();
  const [businessDesc, setBusinessDesc] = useState();
  const [businessInputsArray, setBusinessInputsArray] = useState([
    { name: "", price: "", campaign_price: "", slots: "", time_interval: "" },
  ]);
  const [sssArray, setSssArray] = useState([{ question: "", answer: "" }]);
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  const [businessExtraDesc, setBusinessExtraDesc] = useState("");
  const [workingHours, setWorkingHours] = useState("");

  const addBusiness = async () => {
    try {
      let formdata = new FormData();
      formdata.append("branch_name", businessName);
      formdata.append("branch_description", businessDesc);
      formdata.append("branch_image", photo);
      formdata.append("address", address);
      formdata.append("phone", phone);
      formdata.append("location", location);
      formdata.append("additional_details", businessExtraDesc);
      formdata.append("hours", workingHours);
      formdata.append("tickets", JSON.stringify(businessInputsArray));
      formdata.append("faq_questions", JSON.stringify(sssArray));

      const response = await axiosClient.post("/superuser/create-branch", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      successMessage("İşletme başarıyla eklendi");
      navigate("/admin");
    } catch (error) {}
  };

  return (
    <div className="grid grid-rows-layoutAuth ">
      <Header
        className="bg-primary"
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink={"/profil"}
        homeLink={"/admin"}
      />
      <Container className={styles.container}>
        <Title className={styles.upperTitle}>Macera, Adrenalin, Mutluluk...</Title>
        <Description className={styles.description}>Eğlencenin merkezine hoş geldin!</Description>
        <AddBusinessUpperBox
          photo={photo}
          setPhoto={setPhoto}
          businessName={businessName}
          setBusinessName={setBusinessName}
          businessDesc={businessDesc}
          setBusinessDesc={setBusinessDesc}
        />
        <TicketDetails
          businessInputsArray={businessInputsArray}
          setBusinessInputsArray={setBusinessInputsArray}
          businessTitle={businessName}
          setBusinessTitle={setBusinessName}
          businessExtraDesc={businessExtraDesc}
          setBusinessExtraDesc={setBusinessExtraDesc}
          workingHours={workingHours}
          setWorkingHours={setWorkingHours}
        />
        <AddSSSBox sssArray={sssArray} setSssArray={setSssArray} className="w-full" />

        <AddAdress
          address={address}
          setAddress={setAddress}
          phone={phone}
          setPhone={setPhone}
          location={location}
          setLocation={setLocation}
        >
          {" "}
        </AddAdress>
        <AuthButton className="w-full mt-20" clickButton={addBusiness}>
          Devam Et
        </AuthButton>
      </Container>
      <Footer showBg={false} />
    </div>
  );
};

export default AddBusiness;
