import {
  BgBox,
  AuthBox,
  Header,
  Logo,
  Container,
  Title,
  Footer,
  AuthInput,
  ForgotLink,
  RegisterLink,
  InputError,
  SubmitButton,
} from "../../../../../components/";
import styles from "./style.module.css";
import redsplash from "../../../../../assets/splash/redsplash.svg";
import bluesplash from "../../../../../assets/splash/bluesplash2.svg";
import okkologo from "../../../../../assets/okkologo.svg";
import userlogo from "../../../../../assets/userlogo.svg";
import menu from "../../../../../assets/menu.svg";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { successMessage } from "../../../../../helpers/toast";
import { useNavigate } from "react-router-dom";
import { getServerErrorMessage } from "../../../../../helpers/auth";

const telScheme = yup
  .object({
    phone: yup
      .string()
      .min(10, "Lütfen uygun bir telefon numarası giriniz!")
      .max(10, "Lütfen uygun bir telefon numarası giriniz!")
      .required("Bu alan gerekli"),
  })
  .required();

const NewTel = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(telScheme),
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    try {
      //let response = axiosClient.post("/users/change-phone", data.phone);
      //console.log("response: ", response);

      successMessage("Telefon doğrulama kodu gönderildi!");

      navigate({
        pathname: "/profil/telefon-degistir/telefon-dogrula",
        search: `?phone=${data.phone}`,
      });
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  return (
    <div className="grid grid-rows-layoutAuth ">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/"} />
      <Container className={styles.container}>
        <BgBox
          className={styles.bgBox}
          img1={bluesplash}
          img2={redsplash}
          img1Style="top-[30%] left-[52%] rotate-[240deg]"
          img2Style="bottom-[42%] right-[57%]"
          windowsize={900}
        >
          <AuthBox className={styles.authBox}>
            <Logo className={styles.logo} />
            <form onSubmit={handleSubmit(onSubmit)} className={styles.formContainer}>
              <Title className={styles.title}>{t("enterPhoneNumber")}</Title>
              <div className={styles.inputContainer}>
                <div className="flex items-center gap-1">
                  <span className="font-mainFont font-normaltext-gray-700 text-[19px] rounded-[16px] h-full p-3 text-xl bg-white">
                    +90
                  </span>
                  <AuthInput
                    className={styles.authInput}
                    name="phone"
                    placeholder={t("phoneNumberPlaceholder")}
                    register={register}
                    type="tel"
                  />
                </div>

                <InputError text={errors.phone?.message} />
              </div>

              {/* <AuthButton className={styles.authButton}>
              {t("continueButton")}
            </AuthButton> */}

              <SubmitButton text={t("continueButton")} />
            </form>
          </AuthBox>
        </BgBox>
        <RegisterLink link={t("signIn")} to="/giris">
          {t("alreadyHaveAccount")}
        </RegisterLink>
        <ForgotLink to="/sifremi-unuttum">{t("forgotPassword")}</ForgotLink>
      </Container>
      <Footer />
    </div>
  );
};

export default NewTel;
