import { BusinessesBox, AddBusinessesBox } from "../../index";
import styles from "./style.module.css";
import { API_URL } from "../../../utils/url";
import okkologo from "../../../assets/okkologo.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/trash.svg";
import { ReactComponent as EditIcon } from "../../../assets/edit2.svg";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { errorMessage, successMessage } from "../../../helpers/toast";
import { useNavigate } from "react-router-dom";

const BusinessesBoxContainer = ({
  className,
  type,
  businessBoxes,
  ...props
}) => {
  const axiosClient = useAxiosClient();

  const deleteBusiness = async (id) => {
    try {
      const response = await axiosClient.get(
        `/superuser/delete-branch/branch-id=${id}`
      );

      successMessage("İşletme başarıyla silindi.");
      window.location.reload();
    } catch (error) {
      errorMessage("İşletme silinirken bir hata oluştu.");
    }
  };

  const navigate = useNavigate();

  return (
    <div className={[styles.businessesboxcont, className].join(" ")} {...props}>
      {businessBoxes.map((business) =>
        type === "admin" ? (
          <BusinessesBox
            key={business.id}
            logo={okkologo}
            photo={`${API_URL}` + business.image}
            toLink={`/isletmeler/${business.id}`}
          >
            <DeleteIcon
              className="absolute left-4 top-3 "
              onClick={() => deleteBusiness(business.id)}
            />
            {business.name}
            <EditIcon
              className="absolute right-4 top-3"
              stroke="white"
              onClick={() =>
                navigate(`/admin/isletmeler/isletme-duzenle/${business.id}`)
              }
            />
          </BusinessesBox>
        ) : (
          type === "user" && (
            <BusinessesBox
              key={business.id}
              logo={okkologo}
              photo={`${API_URL}` + business.image}
              toLink={`/isletmeler/${business.id}`}
            >
              {business.name}
            </BusinessesBox>
          )
        )
      )}
      {type === "admin" && (
        <AddBusinessesBox to="/admin/isletmeler/isletme-ekle" />
      )}
    </div>
  );
};

export default BusinessesBoxContainer;
