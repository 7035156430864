import styles from "./style.module.css";
import { useState, useEffect } from "react";

const SSSBox = ({ children, data, className, ...props }) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  useEffect(() => {
    const handleClick = (event) => {
      if (event.target.classList.contains("question")) {
        const clickedQuestionIndex = parseInt(
          event.target.getAttribute("data-index")
        );

        setActiveQuestionIndex(
          activeQuestionIndex === clickedQuestionIndex
            ? null
            : clickedQuestionIndex
        );
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [activeQuestionIndex]);

  return (
    <div className={[styles.sssbox, className].join(" ")}>
      {data?.map((data, index) => (
        <div
          key={index}
          className={`border rounded-xl fill-transparent-[5%] border-gray-200  `}
        >
          <div
            className="question border border-gray-400  rounded-xl  text-[24px] drop-shadow-md bg-[#FEF2F5]  py-3 px-3 cursor-pointer xss:px-[72px]"
            data-index={index}
          >
            {data?.question}
          </div>
          {activeQuestionIndex === index && (
            <div className="bg-white rounded-b-xl answer py-3 px-3 xss:px-[72px] text-[16px] ">
              {data?.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SSSBox;
