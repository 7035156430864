import { AddBusinessesInput, AuthButton } from "../../index";
import styles from "./style.module.css";
import { useContext } from "react";
import StoreContext from "../../../context/store";

import { useNavigate } from "react-router-dom";
import useAxiosClient from "../../../hooks/useAxiosClient";

const AddPersonelBox = ({
  personelData,
  personelExist,
  addPersonelClick,
  deletePersonelFromBusiness,
}) => {
  const navigate = useNavigate();
  const axiosClient = useAxiosClient();
  const { setAddPersonelToBusinessClick } = useContext(StoreContext);

  //1979-08-30 to 30 Ağustos 1979 format

  const prettyDate = (date) => {
    const dateArray = date.split("-");
    const year = dateArray[0];
    const month = dateArray[1];
    const day = dateArray[2];
    const monthNames = [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ];
    return day + " " + monthNames[month - 1] + " " + year;
  };

  return (
    <div className="flex flex-col w-full">
      <AddBusinessesInput
        className="w-full h-[45px] text-[16px] mt-10"
        value={personelData?.username}
        readOnly
      />
      <AddBusinessesInput
        className="w-full h-[45px] text-[16px] mt-5"
        value={personelData?.firstname + " " + personelData?.lastname}
        readOnly
      />
      <div className="w-full flex flex-row  h-[45px] text-[16px] mt-5 gap-5 max-sm:flex-wrap">
        <AddBusinessesInput
          className="w-full sm:w-5/12"
          value={"+" + personelData?.phone}
          readOnly
        />
        <AddBusinessesInput
          className="w-full sm:w-2/12"
          value={personelData?.gender ? "Erkek" : "Kadın"}
          readOnly
        />
        <AddBusinessesInput
          className="w-full sm:w-5/12"
          value={
            personelData?.birthday ? prettyDate(personelData?.birthday) : ""
          }
          readOnly
        />
      </div>
      {personelExist && (
        <div className={styles.role}>{personelData?.user_type}</div>
      )}
      {/* {!personelExist && (
        <AuthButton
          className=" flex px-[45px] py-2 max-sm:mt-32 mt-7 font-[400] rounded-[12px] text-[24px] whitespace-nowrap"
          clickButton={() =>{ setAddPersonelToBusinessClick(true); addPersonelClick()}}
        >
          Şubeye Ekle
        </AuthButton>
      )}
      {personelExist && (
        <AuthButton className=" flex px-[45px] py-2 max-sm:mt-32 mt-7 font-[400] rounded-[12px] text-[24px] whitespace-nowrap"
          clickButton={() => deletePersonelFromBusiness()}
        >
          Şubeden Çıkar
        </AuthButton>
      )} */}
    </div>
  );
};

export default AddPersonelBox;
