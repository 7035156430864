import {
  Header,
  Container,
  Description,
  Title,
  BuyTicketUpperBox,
  UserInformationBoxContainer,
  Footer,
  TicketButtonBox,
  AuthBox,
  ChooseHourBox,
  AuthButton,
  AuthInput,
} from "../../../../components";
import styles from "./style.module.css";
import okkologo from "../../../../assets/okkologo.svg";
import userlogo from "../../../../assets/userlogo.svg";
import menu from "../../../../assets/menu.svg";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MiniLoading from "../../../../components/Loading/MiniLoading";
import { axiosClient } from "../../../../api/config";
import { getServerErrorMessage } from "../../../../helpers/auth";
import { errorMessage } from "../../../../helpers/toast";

const UserInformationInput = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const selectedDate = searchParams.get("tarih") || "";
  const selectedHours = searchParams.get("saat") || "";

  const { isletmeId, biletId } = useParams();

  const [branch, setBranch] = useState(null);
  const [ticket, setTicket] = useState(null);
  const [users, setUsers] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);

  useEffect(() => {
    getMultipleResponse();

    return () => {
      setBranch(null);
      setTicket(null);
    };
  }, []);

  const getMultipleResponse = async () => {
    setApiLoading(true);
    try {
      const [branchInfoResponse, ticketInfoResponse] = await Promise.all([
        axiosClient.get(`/users/get-branch/branch=${isletmeId}`),
        axiosClient.get(`/users/get-ticket/${biletId}`),
      ]);

      setBranch(branchInfoResponse?.data?.data?.branch);
      setTicket(ticketInfoResponse.data.data);
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
      setApiLoading(false);
    }
  };

  const createMeeting = async () => {
    const parts = selectedHours.split(",");
    const game_times = parts.map((part) => part.split("-")[0]);

    // let localUsers = users.map((user, index) => {
    //   return {
    //     name: user.namesurname,
    //     phone: user.phone,
    //     email: user.email,
    //     tc: user.tc,
    //     game_time: game_times[index],
    //   };
    // });

    try {
      // const response = await axiosClient.post(
      //   `/users/create-meeting/ticket=${biletId}`,
      //   JSON.stringify({ game_times: game_times, date: selectedDate })
      // );

      let usersLength = selectedHours.split(",").length;

      if (usersLength > users.length) {
        errorMessage("Lütfen tüm kullanıcıların bilgilerini giriniz.");
        return;
      }

      const updatedUsers = users.map((user) => {
        const formattedPhone = user.phone.replace(/[^\d]/g, "");

        // formattedPhone = newPhone.replace("+", "");

        return { ...user, phone: formattedPhone };
      });

      navigate({
        pathname: `/isletmeler/${isletmeId}/${biletId}/saat-sec/iletisim-bilgileri/banka-karti`,
        search: `?tarih=${selectedDate}&saat=${selectedHours}&kullanıcılar=${JSON.stringify(updatedUsers)}`,
      });
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/"} />
      <Container className={styles.container}>
        {/* <Title className={styles.upperTitle}>
          Macera, Adrenalin, Mutluluk...
        </Title>
        <Description className={styles.description}>
          Eğlencenin merkezine hoş geldin!
        </Description> */}
        {apiLoading && <MiniLoading className="mb-8" />}
        {/* <BuyTicketUpperBox
          logo={okkologo}
          photo={`${API_URL}` + branch?.image}
          description={branch?.description}
          imageText={branch?.name}
        /> */}
        <Title className={styles.midTitle}>Bilet Detayları</Title>
        <TicketButtonBox className={styles.ticketButtonBox}>
          <Description className={styles.ticketButtonBoxTitle}>{ticket?.name}</Description>
        </TicketButtonBox>

        <AuthBox
          className={["drop-shadow-lg rounded-[40px]  w-full max-w-[1208px] px-8 md:px-14 mb-8 h-fit;", " bg-white max-md:px-0"].join()}
        >
          <UserInformationBoxContainer
            data={selectedHours}
            handleUsers={(usersArray) => {
              setUsers(usersArray);
            }}
          />
          <AuthButton
            className={"px-8 m-8"}
            onClick={() => {
              createMeeting();
            }}
          >
            Ödeme Adımına Geç
          </AuthButton>
        </AuthBox>
      </Container>
      <Footer />
    </div>
  );
};

export default UserInformationInput;
