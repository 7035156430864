import { useState, useEffect } from "react";
import styles from "./style.module.css";
import okkologo from "../../assets/okkologo.svg";
import userlogo from "../../assets/userlogo.svg";
import menu from "../../assets/menu.svg";
import { Header, Description, Container, AuthInput, AuthButton } from "../../components";
import { useContext } from "react";
import AuthContext from "../../context/auth";
import useAxiosClient from "../../hooks/useAxiosClient";
import { successMessage } from "../../helpers/toast";
import { getServerErrorMessage } from "../../helpers/auth";
import { useNavigate } from "react-router-dom";
import { getFormattedDate } from "../../helpers/year";
import DatePrettier from "../../components/DatePrettier";
import { API_URL } from "../../utils/url";
import { PhotoView } from "react-photo-view";
import moment from "moment";
import { ReactComponent as LeftArrow } from "../../assets/leftredarrow.svg";
import { ReactComponent as RightArrow } from "../../assets/rightredarrow.svg";

const PersonalLeader = () => {
  const { auth } = useContext(AuthContext);

  const authAxiosClient = useAxiosClient();

  const navigation = useNavigate();
  const [dailykz, setDailykz] = useState({});

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [fileSize, setFileSize] = useState(0);

  const [dailyRewards, setDailyRewards] = useState({
    daily_reward_obj: {
      id: 0,
      branch: "",
      date: "",
      total_money: 0.0,
      achieve_money: 0.0,
      cash: 0.0,
      credit: 0.0,
      spent: 0.0,
      image: "",
      description: "",
      is_approve: false,
      free_join: 0,
      created_at: "",
      updated_at: "",
    },
    maddeler: [
      {
        id: 0,
        title: "",
        image: "",
        cash: 0.0,
        created_at: "",
        updated_at: "",
      },
    ],
  });
  const [ticketDetails, setTicketDetails] = useState(null);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [personelDetails, setPersonelDetails] = useState(null);
  const [selectedImages, setSelectedImages] = useState(Array(dailyRewards.maddeler.length).fill(null));
  const [receiptPreview, setReceiptPreview] = useState(null);

  useEffect(() => {
    initialApi();
  }, []);

  useEffect(() => {
    initialApi();
  }, [selectedDate]);

  const initialApi = () => {
    getDailyDataByDate();
  };

  /*
  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };
  */

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    const sizeInMb = file.size / (1024 * 1024); // Byte cinsinden boyutu MB'ye çevirme
    setFileSize(sizeInMb.toFixed(2));

    setDailyRewards({
      ...dailyRewards,
      daily_reward_obj: {
        ...dailyRewards.daily_reward_obj,
        image: file,
      },
    });

    const previewURL = URL.createObjectURL(e.target.files[0]);
    setReceiptPreview(previewURL);
  };

  const getDailyDataByDate = async () => {
    try {
      const formattedDate = getFormattedDate(selectedDate);
      const [dailyKzResponse, dailyRewardsResponse, ticketDetailsResponse, companyDetailsResponse, personelDetailsResponse] =
        await Promise.all([
          authAxiosClient.get(`/personel/get-daily-rewards/date=${formattedDate}`),
          authAxiosClient.get(`/personel/get-daily-reward-by-date/date=${formattedDate}`),
          authAxiosClient.get(`/personel/get-daily-ticket-details/date=${formattedDate}`),
          authAxiosClient.get(`/personel/get-daily-company-details/date=${formattedDate}`),
          authAxiosClient.get(`/personel/get-daily-personel-details/date=${formattedDate}`),
        ]);

      setDailykz(dailyKzResponse?.data?.data);
      setDailyRewards(dailyRewardsResponse?.data?.data);
      setTicketDetails(ticketDetailsResponse?.data?.data);
      setCompanyDetails(companyDetailsResponse?.data?.data);
      setPersonelDetails(personelDetailsResponse?.data?.data);
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  // id ye göre close yapacak. backend güncellenecek
  const closeDailyRewards = async () => {
    let formData = new FormData();
    // achive_money, cash, spent, description, receiptImage
    formData.append("achieve_money", dailyRewards?.daily_reward_obj?.achieve_money);
    formData.append("cash", dailyRewards?.daily_reward_obj?.cash);
    formData.append("spent", dailyRewards?.daily_reward_obj?.spent);
    formData.append("description", dailyRewards?.daily_reward_obj?.description);
    formData.append("image", dailyRewards?.daily_reward_obj?.image);
    try {
      const response = await authAxiosClient.post(`/personel/close-daily-rewards/id=${dailyRewards?.daily_reward_obj?.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (auth.role === "personel_lead") {
        successMessage("Kasa başarıyla kapatıldı.");
      } else {
        successMessage("Kasa başarıyla kaydedildi.");
      }
      navigation("/personel");
      initialApi();
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  const addInvoices = async () => {
    try {
      dailyRewards.maddeler.map(async (invoice) => {
        let formData = new FormData();
        formData.append("title", invoice?.title);
        formData.append("image", invoice?.image);
        formData.append("cash", invoice?.cash);

        const response = await authAxiosClient.post(
          `/personel/add-invoices/daily-reward-id=${dailyRewards?.daily_reward_obj?.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.error) {
          getServerErrorMessage("Gider Kalemleri eklenirken bir hata oluştu.");
        } else {
          // closeDailyRewards();
        }
      });
      successMessage("Gider Kalemleri başarıyla eklendi.");
    } catch (err) {
      getServerErrorMessage(err);
    } finally {
    }
  };

  const register = () => {};

  const addGiderMaddesi = () => {
    const newDailyRewards = { ...dailyRewards };
    newDailyRewards.maddeler.push({
      id: 0,
      title: "",
      image: "",
      cash: 0,
      created_at: "",
      updated_at: "",
    });
    setDailyRewards(newDailyRewards);
  };

  const removeGiderMaddesi = () => {
    const newDailyRewards = { ...dailyRewards };
    newDailyRewards.maddeler.pop();
    setDailyRewards(newDailyRewards);
  };

  const changeDate = (type) => {
    if (type === "before") {
      setSelectedDate((prev) => {
        const newDate = new Date(prev);
        newDate.setDate(newDate.getDate() - 1);
        return newDate;
      });
    } else {
      setSelectedDate((prev) => {
        const newDate = new Date(prev);
        newDate.setDate(newDate.getDate() + 1);
        return newDate;
      });
    }
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/"} />
      <Container className={styles.container}>
        {/* <Title className={styles.upperTitle}>
          Macera, Adrenalin, Mutluluk...
        </Title>
        <Description className={styles.description}>
          Eğlencenin merkezine hoş geldin!
        </Description> */}

        <Description className={[styles.description, "text-4xl font-bold"].join(" ")}>Kasa İcmali</Description>

        <div className="flex items-center gap-2 justify-center mb-4">
          <button onClick={() => changeDate("before")}>
            <LeftArrow />
          </button>

          <DatePrettier
            selectedDate={getFormattedDate(selectedDate)}
            className="text-center flex justify-center items-center mx-4 text-xl"
          />
          {selectedDate.toDateString() !== new Date().toDateString() && (
            <button onClick={() => changeDate("after")}>
              <RightArrow />
            </button>
          )}
        </div>

        <div className="shadow-md border px-16 pt-12 pb-7 w-full md:w-3/4 mx-20">
          <h4 className="text-3xl text-center mb-1 font-semibold">Günlük K/Z Onay</h4>
          <DatePrettier selectedDate={getFormattedDate(selectedDate)} className="text-center flex justify-center items-center" />

          <div className="flex flex-col mt-12">
            <label className="text-xl">Toplam Ciro (₺)</label>

            <div className={[styles.inputBox, "text-green-500 font-semibold"].join(" ")}>
              <p>{dailykz?.total_money}</p>
              <span className="ml-1.5">TL</span>
            </div>
          </div>

          <div className="flex flex-col my-4">
            <label className="mb-1 text-xl">Nakit Miktarı (₺)</label>

            <div className={styles.inputBox}>
              <p>{dailykz?.total_cash}</p>
              <span className="ml-1.5">TL</span>
            </div>
          </div>

          <div className="flex flex-col">
            <label className="mb-1 text-xl">Kredi Miktarı (₺)</label>

            <div className={styles.inputBox}>
              <p>{dailykz?.total_credit}</p>
              <span className="ml-1.5">TL</span>
            </div>
          </div>
          <div className="flex flex-col my-4">
            <label className="mb-1 text-xl">Online Miktarı (₺)</label>

            <div className={styles.inputBox}>
              <p>{dailykz?.online_credit}</p>
              <span className="ml-1.5">TL</span>
            </div>
          </div>
        </div>

        <div className="flex flex-col px-16 pt-12 pb-7 w-full md:w-3/4 mx-20 border-2 mt-20">
          <h4 className="text-3xl text-center mb-1 font-semibold">Kasa Kapat</h4>

          <DatePrettier
            selectedDate={getFormattedDate(selectedDate)}
            className="text-center flex justify-center items-center mx-4 text-xl"
          />

          <div className="flex flex-col items-center mt-4">
            <p>Son Güncelleme</p>
            <p>{moment(dailyRewards?.daily_reward_obj?.updated_at).format("DD.MM.YYYY HH:mm")}</p>
          </div>

          <div className="flex flex-col mt-12">
            <label className="text-xl">Toplam Ciro (₺)</label>

            <AuthInput
              value={dailyRewards?.daily_reward_obj?.total_money}
              onChange={(e) =>
                setDailyRewards({
                  ...dailyRewards,
                  daily_reward_obj: {
                    ...dailyRewards.daily_reward_obj,
                    total_money: e.target.value,
                  },
                })
              }
              className={styles.inputBox}
              placeholder="Toplam Ciro"
              readOnly
              register={() => {}}
            />
          </div>

          <div className="flex flex-col mt-4">
            <label className="text-xl">Kasadaki Miktar (₺)</label>

            <AuthInput
              value={dailyRewards?.daily_reward_obj?.achieve_money}
              onChange={(e) =>
                setDailyRewards({
                  ...dailyRewards,
                  daily_reward_obj: {
                    ...dailyRewards.daily_reward_obj,
                    achieve_money: e.target.value,
                  },
                })
              }
              className={styles.inputBox}
              placeholder="Kasadaki Miktar"
              register={() => {}}
            />
          </div>

          <div className="flex flex-col mt-4">
            <label className="mb-1 text-xl">Toplam Nakit Miktarı (₺) (Kuruşları nokta ile ayırınız. Örn: 18020.40)</label>

            <AuthInput
              value={dailyRewards?.daily_reward_obj?.cash}
              onChange={(e) =>
                setDailyRewards({
                  ...dailyRewards,
                  daily_reward_obj: {
                    ...dailyRewards.daily_reward_obj,
                    cash: e.target.value,
                  },
                })
              }
              className={styles.inputBox}
              placeholder="Nakit Miktarı"
              register={() => {}}
            />
          </div>

          <div className="flex flex-col mt-4">
            <label className="mb-1 text-xl">Toplam Kredi Miktarı (₺)</label>

            <AuthInput
              value={dailyRewards?.daily_reward_obj?.credit}
              onChange={(e) =>
                setDailyRewards({
                  ...dailyRewards,
                  daily_reward_obj: {
                    ...dailyRewards.daily_reward_obj,
                    credit: e.target.value,
                  },
                })
              }
              className={styles.inputBox}
              placeholder="Kredi Miktarı"
              readOnly
              register={() => {}}
            />
          </div>

          <div className="flex flex-col mt-4">
            <label className="mb-1 text-xl">Ücretsiz Giriş Sayısı</label>

            <AuthInput
              value={dailyRewards?.daily_reward_obj?.free_join}
              readOnly
              onChange={(e) =>
                setDailyRewards({
                  ...dailyRewards,
                  daily_reward_obj: {
                    ...dailyRewards.daily_reward_obj,
                    free_join: e.target.value,
                  },
                })
              }
              className={styles.inputBox}
              placeholder="Ücretsiz Giriş Sayısı"
              type="number"
              register={() => {}}
            />
          </div>

          <div className="flex flex-col mt-4">
            <label className="mb-1 text-xl">Kamera Kullanım Sayısı</label>

            <AuthInput
              value={dailyRewards?.daily_reward_obj?.camera_using_count}
              readOnly
              onChange={(e) =>
                setDailyRewards({
                  ...dailyRewards,
                  daily_reward_obj: {
                    ...dailyRewards.daily_reward_obj,
                    camera_using_count: e.target.value,
                  },
                })
              }
              className={styles.inputBox}
              placeholder="Ücretsiz Giriş Sayısı"
              type="number"
              register={() => {}}
            />
          </div>

          <div className="flex flex-col mt-4">
            <label className="mb-1 text-xl">Bilet Detayları</label>

            <div className={[styles.inputBox, "flex flex-col"].join(" ")}>
              {ticketDetails?.tickets?.map((ticket, index) => (
                <div key={index} className="flex flex-col mt-2">
                  <div className="flex flex-row justify-between">
                    <p>{ticket?.ticket_name}</p>
                    <p className="font-semibold">{ticket?.meeting_count}</p>
                  </div>
                </div>
              ))}
              <div className="flex justify-between mt-2 text-xl">
                <p className="font-bold">Toplam</p>
                <p className="font-bold">{ticketDetails?.total_meeting_count}</p>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-4">
            <label className="mb-1 text-xl">Firma Detayları</label>

            <div className={[styles.inputBox, "flex flex-col"].join(" ")}>
              {companyDetails?.company_attendance?.map((company, index) => (
                <div key={index} className="flex flex-col mt-2">
                  <div className="flex flex-row justify-between">
                    <p>{company?.company_name}</p>
                    <p className="font-semibold">{company?.count}</p>
                  </div>
                </div>
              ))}
              <div className="flex justify-between mt-2 text-xl">
                <p className="font-bold">Toplam</p>
                <p className="font-bold">{companyDetails?.total_meeting_count}</p>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-4">
            <label className="mb-1 text-xl">Kasiyer Detayları</label>

            <div className={[styles.inputBox, "flex flex-col"].join(" ")}>
              {personelDetails?.personel_attendance?.map((personel, index) => (
                <div key={index} className="flex flex-col mt-2">
                  <div className="flex flex-row justify-between">
                    <p>{personel?.personel_name}</p>
                    <p className="font-semibold">{personel?.count}</p>
                  </div>
                </div>
              ))}
              <div className="flex justify-between mt-2 text-xl">
                <p className="font-bold">Toplam</p>
                <p className="font-bold">{personelDetails?.total_meeting_count}</p>
              </div>
            </div>
          </div>

          {/* <div className="flex flex-col mt-4">
            <label className="mb-1 text-xl">Açıklama</label>

            <textarea
              value={dailyRewards?.daily_reward_obj?.description}
              onChange={(e) =>
                setDailyRewards({
                  ...dailyRewards,
                  daily_reward_obj: {
                    ...dailyRewards.daily_reward_obj,
                    description: e.target.value,
                  },
                })
              }
              className={styles.inputBox}
              placeholder="Açıklama"
              type="text"
              register={() => {}}
            />
          </div> */}

          <div className="flex flex-col mt-6">
            <label className="mb-1 text-xl font-semibold">Gider Kalemleri (Fotoğraf eklenmek zorundadır!)</label>

            {dailyRewards?.maddeler?.map((reward, index) => (
              <div key={index} className={`border rounded-xl fill-transparent-[5%] text-[#959595] mb-3`}>
                <div
                  className="flex sm:flex-row flex-col gap-4 border border-gray-100  rounded-xl text-[24px] drop-shadow-md bg-white py-3 px-3 cursor-pointer md:px-[22px]"
                  data-index={index}
                >
                  <div className="flex flex-col w-full max-w-full">
                    <label htmlFor="gider" className="text-sm text-black ml-1">
                      Gider Kalemi Başlığı
                    </label>
                    <AuthInput
                      id="gider"
                      placeholder="Gider Kalemi Başlığı"
                      className={"w-full flex max-w-full drop-shadow-xl lg:mr-2 h-16 border"}
                      register={register}
                      value={dailyRewards.maddeler[index].title}
                      onChange={(e) => {
                        let newDailyRewards = { ...dailyRewards };
                        newDailyRewards.maddeler[index].title = e.target.value;
                        setDailyRewards(newDailyRewards);
                      }}
                    />
                  </div>

                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    id={`file${index}`}
                    onChange={(event) => {
                      const file = event.target.files[0];
                      const newSelectedImage = [...selectedImages];
                      newSelectedImage[index] = file;
                      setSelectedImages(newSelectedImage);

                      setDailyRewards((prev) => {
                        const newDailyRewards = { ...prev };
                        newDailyRewards.maddeler[index].image = file;
                        return newDailyRewards;
                      });
                    }}
                    //ref={inputRef}
                  />
                  <div className="flex flex-col">
                    <label htmlFor="gorselekle" className="text-sm text-black ml-1">
                      Görsel Ekle
                    </label>
                    <button
                      id="gorselekle"
                      className=" bg-white drop-shadow-xl rounded-xl px-4 h-16 border"
                      onClick={() => {
                        //inputRef.current.click();
                        document.getElementById(`file${index}`).click();
                      }}
                    >
                      Görsel
                    </button>
                  </div>

                  <div className="mt-0 lg:mt-4 mx-0 mr-0 w-full lg:mx-4 lg:mr-10">
                    {selectedImages[index] ? (
                      <PhotoView src={URL.createObjectURL(selectedImages[index])}>
                        <img className="w-full h-16" src={URL.createObjectURL(selectedImages[index])} alt="görsel" />
                      </PhotoView>
                    ) : (
                      <PhotoView src={`${API_URL}${reward?.image}`}>
                        <img className="w-full h-16" src={`${API_URL}${reward?.image}`} alt="görsel" />
                      </PhotoView>
                    )}
                  </div>

                  <div className="flex flex-col ml-0 lg:ml-1 w-full">
                    <label htmlFor="paramiktari" className="text-sm text-black ml-1">
                      Harcanan Para Miktarı (₺)
                    </label>
                    <AuthInput
                      id="paramiktari"
                      placeholder="Para Miktarı"
                      className={"w-full flex max-w-full drop-shadow-xl h-16"}
                      register={register}
                      value={dailyRewards.maddeler[index].cash}
                      onChange={(e) => {
                        let newDailyRewards = { ...dailyRewards };
                        newDailyRewards.maddeler[index].cash = e.target.value;
                        setDailyRewards(newDailyRewards);
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <button
                className="bg-primary font-medium border text-xl text-white rounded-full px-5 py-3"
                onClick={() => {
                  addGiderMaddesi();
                }}
              >
                Gider Kalemi Ekle
              </button>
              <button
                className="bg-primary font-medium border text-xl text-white rounded-full px-5 py-3"
                onClick={() => {
                  removeGiderMaddesi();
                }}
              >
                Gider Kalemi Çıkart
              </button>
            </div>

            <button
              className="bg-primary font-medium border text-xl text-white rounded-full px-5 py-3"
              onClick={() => {
                addInvoices();
              }}
            >
              Giderleri Kaydet
            </button>
          </div>

          <div className="flex flex-col mt-8">
            <label className="mb-1 text-xl">Toplam Harcanan Miktar(₺) (Harcanan Miktar Nakit miktarından düşük olmalıdır!)</label>
            {/* sum of gider */}
            <AuthInput
              value={dailyRewards?.maddeler?.reduce((a, b) => parseFloat(a) + (parseFloat(b["cash"]) || 0), 0) || 0}
              // onChange={(e) =>
              //   setDailyRewards({
              //     ...dailyRewards,
              //     daily_reward_obj: {
              //       ...dailyRewards.daily_reward_obj,
              //       spent: e.target.value,
              //     },
              //   })
              // }
              className={styles.inputBox}
              placeholder="Harcanan Miktar"
              readOnly
              type="number"
              register={() => {}}
            />
          </div>

          {auth.role === "personel_lead" && (
            <div className="mt-4">
              <div className="flex flex-col gap-1">
                <label htmlFor="receipt" className="block font-medium">
                  Banka Dekontu Ekle
                </label>
                <input
                  type="file"
                  id="receipt"
                  name="receipt"
                  onChange={handleFileChange}
                  className="w-full border rounded-lg px-3 py-2"
                  accept=".jpg, .jpeg, .png"
                  required
                />
              </div>
              {fileSize > 0 && fileSize}MB
              <div className="mt-4">
                {receiptPreview ? (
                  <PhotoView src={receiptPreview}>
                    <img className="w-full md:w-40 h-40" src={receiptPreview} alt="görsel" />
                  </PhotoView>
                ) : (
                  <PhotoView src={`${API_URL}${dailyRewards?.daily_reward_obj?.image}`}>
                    <img className="w-full md:w-40 h-40" src={`${API_URL}${dailyRewards?.daily_reward_obj?.image}`} alt="görsel" />
                  </PhotoView>
                )}
              </div>
            </div>
          )}

          <AuthButton onClick={closeDailyRewards} className="mt-8 px-8">
            {auth.role === "personel_lead" ? "Kasayı Kapat" : "Kasayı Kaydet"}
          </AuthButton>
          {dailyRewards.daily_reward_obj.is_approve ? (
            <p className="text-center mt-2">
              Kasa <span className="font-semibold">Personel Lideri</span> tarafından
              <span className="text-green-500 font-semibold"> kapatılmıştır.</span>
            </p>
          ) : (
            <p className="text-center mt-2">
              Kasayı <span className="font-semibold">Personel Lideri</span> kapatmak zorundadır.
            </p>
          )}
        </div>
      </Container>
    </div>
  );
};

export default PersonalLeader;
