import { useState, useEffect } from "react";
import styles from "./style.module.css";
import AuthInput from "../../Input/AuthInput";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { getServerErrorMessage } from "../../../helpers/auth";
import { API_URL } from "../../../utils/url";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { PhotoView } from "react-photo-view";
import ExportPdf from "../../ExportPdf";

const BusinessRequirementsFormPersonelDetail = ({
  children,
  answers,
  className,
  date,
  time,
  ...props
}) => {
  // const inputRef = useRef(null);
  const authAxiosClient = useAxiosClient();
  const { auth } = useAuth();
  const { requirementsId } = useParams();
  const navigation = useNavigate();

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);
  const [articles, setArticles] = useState([]);
  const [title, setTitle] = useState("");
  const [personalName, setPersonalName] = useState("");

  useEffect(() => {
    const handleClick = (event) => {
      if (event.target.classList.contains("not")) {
        const clickedQuestionIndex = parseInt(
          event.target.getAttribute("data-index")
        );

        setActiveQuestionIndex(
          activeQuestionIndex === clickedQuestionIndex
            ? null
            : clickedQuestionIndex
        );
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [activeQuestionIndex]);

  useEffect(() => {
    getArticles();
  }, []);

  const getArticles = async () => {
    try {
      const response = await authAxiosClient.get(
        `/personel/get-technical-controlls-by-requirements-id/requirements-id=${requirementsId}`
      );

      let localArticles = response?.data?.data?.details;

      setTitle(response?.data?.data?.branch_name);
      setPersonalName(response?.data?.data?.personel_name);

      localArticles = localArticles.map((article) => {
        return {
          rule: article?.rule,
          image: article?.image,
          note: article?.note,
          status: article?.status,
        };
      });

      setArticles(localArticles);
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
    }
  };

  const register = () => {};

  const checkControl = (articleObj) => {
    if (articleObj.status === true) {
      return "bg-black";
    }
  };

  const checkBorderControl = (articleObj) => {
    if (articleObj.status === true) {
      return "border-green-500";
    }
  };

  return (
    <div className={[styles.sssbox, className].join(" ")}>
      <hr />
      <h3 className="text-center text-2xl font-bold text-primary mt-2">
        {title}
      </h3>
      <p className="text-center text-2xl font-bold">Personel: {personalName}</p>
      <ExportPdf
        className="ml-auto"
        fileName="teknik-kontrol-anketi.pdf"
        fileTitle="Teknik Kontrol Anketi"
        data={articles.map((item) => [
          item.rule,
          item.status === true ? "Uygun" : "Uygun Değil",
          item.note == "null" ? "-" : item.note,
        ])}
        headData={[["Kural", "Durum", "Not"]]}
      />

      <div className="flex flex-col gap-4">
        {articles?.map((article, index) => (
          <div
            key={index}
            className={`border rounded-xl fill-transparent-[5%] text-[#959595] ${checkBorderControl(
              article
            )}`}
          >
            <div
              className="question flex sm:flex-row flex-col border border-gray-400  rounded-xl  text-[24px] drop-shadow-md bg-white   py-3 px-3 cursor-pointer md:px-[72px]"
              data-index={index}
            >
              <AuthInput
                placeholder="İşletme kuralları, işletme gereksinimi,"
                className={"w-full flex max-w-full drop-shadow-xl mr-2 "}
                register={register}
                value={article?.rule}
                readOnly
                onChange={(e) => {
                  const newArticles = [...articles];
                  newArticles[index].rule = e.target.value;
                  setArticles(newArticles);
                }}
              />

              <div>
                <PhotoView src={`${API_URL}${article?.image}`}>
                  <img
                    className="w-[35px] h-[35px] m-2 mr-10"
                    src={`${API_URL}${article?.image}`}
                    alt="görsel"
                  />
                </PhotoView>
              </div>

              <button
                className="not bg-white drop-shadow-xl rounded-xl m-2 px-4"
                data-index={index} // Add data-index attribute to identify the clicked question
              >
                Not
              </button>
              <div className="h-full pt-3 ">
                <button className="circle justify-center bg-white border-2 border-black rounded-full w-[35px] h-[35px]">
                  <div className="w-full h-full justify-center items-center flex">
                    <div
                      className={`rounded-full flex  border-none w-[22px] h-[22px]  ${checkControl(
                        article
                      )}`}
                    ></div>
                  </div>
                </button>
              </div>
            </div>
            {activeQuestionIndex === index && (
              <div className="flex sm:flex-row flex-col bg-white rounded-b-xl answer py-3 px-3 xss:px-[72px] text-[16px] ">
                <AuthInput
                  placeholder="Not Ekleyin."
                  className={"w-full flex max-w-full drop-shadow-xl mx-2 "}
                  register={register}
                  readOnly
                  value={article?.note == "null" ? "" : article?.note}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BusinessRequirementsFormPersonelDetail;
