import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();

  // const goBack = () => navigate(-1);

  return (
    <section className="flex flex-col justify-center items-center px-4 py-8">
      <h2 className="font-semibold text-2xl">Yetkiniz Yok!</h2>
      <br />
      <p className="text-lg">Bu sayfaya erişim yetkiniz yok.</p>
      <div className="flex flex-col gap-4 flexGrow mt-2">
        <button
          onClick={() => {
            navigate(-1);
          }}
          className="font-semibold text-3xl underline"
        >
          Geri Dön
        </button>
        <button
          onClick={() => {
            navigate("/giris");
          }}
          className="font-semibold text-3xl underline text-primary"
        >
          Giriş Yap
        </button>
      </div>
    </section>
  );
};

export default Unauthorized;
