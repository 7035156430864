import styles from "./style.module.css";

const OpportunityBox = ({ children, className, img, imgStyle, ...props }) => {
  return (
    <div className={styles.opportunityBoxContainer}>
      <img
        src={img}
        alt="okko"
        className={[styles.img1, imgStyle].join(" ")}
        {...props}
      />
      <div className={[styles.opportunityBox, className].join(" ")} {...props}>
        {children}
      </div>
    </div>
  );
};

export default OpportunityBox;
