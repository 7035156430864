import {
  BgBox,
  AuthBox,
  Header,
  AuthButton,
  Logo,
  Container,
  Title,
} from "../../../../components";
import styles from "./style.module.css";
import cyansplash from "../../../../assets/splash/cyansplash2.svg";
import redsplash from "../../../../assets/splash/redsplash.svg";
import okkologo from "../../../../assets/okkologo.svg";
import userlogo from "../../../../assets/userlogo.svg";
import menu from "../../../../assets/menu.svg";
import { useNavigate } from "react-router-dom";

const AccountCreated = () => {
  const navigate = useNavigate();

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink="/profil"
        homeLink={"/"}
      />
      <Container className={styles.container}>
        <BgBox
          className={styles.bgBox}
          img1={cyansplash}
          img2={redsplash}
          img1Style="top-[38%] left-[42%]"
          img2Style="bottom-[12%] right-[63%]"
          windowsize={768}
        >
          <AuthBox className={styles.authBox}>
            <Logo className={styles.logo} />
            <Title className={styles.title}>Hesabınız oluşturuldu!</Title>
            <AuthButton
              className={styles.authButton}
              clickButton={() => {
                navigate("/giris");
              }}
            >
              Giriş Yap
            </AuthButton>
          </AuthBox>
        </BgBox>
      </Container>
    </div>
  );
};

export default AccountCreated;
