import { Header, Container, Title, Description, Footer, TicketButtonBox } from "../../../../components";
import styles from "./style.module.css";
import okkologo from "../../../../assets/okkologo.svg";
import userlogo from "../../../../assets/userlogo.svg";
import menu from "../../../../assets/menuwhite.svg";
import { Link } from "react-router-dom";

const UserAppointments = () => {
  return (
    <div className="grid grid-rows-layoutAuth">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/"} />
      <Container className={styles.container}>
        <Title className={styles.upperTitle}>Macera, Adrenalin, Mutluluk...</Title>
        <Description className={styles.description}>Eğlencenin merkezine hoş geldin!</Description>

        <Title className={styles.title}>Randevu Geçmişi</Title>

        {fakeData2.map((item, index) => {
          return (
            <TicketButtonBox className={styles.ticketButtonBox}>
              <div className={"grid grid-cols-5 w-full"}>
                <Description className={styles.ticketButtonBoxTitle}>{item.title}</Description>
                <Description className={styles.ticketButtonBoxPrice}>{item.date}</Description>
                <div>
                  <Link className={styles.ticketButtonBoxButton} to={`/randevu-gecmisi/bilet-${item.id}`}>
                    İncele
                  </Link>
                </div>
              </div>
            </TicketButtonBox>
          );
        })}
      </Container>
      <Footer />
    </div>
  );
};

export default UserAppointments;
