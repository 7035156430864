import {
  Header,
  Container,
  Description,
  Title,
  BuyTicketUpperBox,
  Footer,
  TicketButtonBox,
  SSSBox,
  AuthBox,
  TicketBox,
} from "../../../../components";
import styles from "./style.module.css";
import fakeImage1 from "../../../../assets/fakeImages/photo1.png";
import okkologo from "../../../../assets/okkologo.svg";
import userlogo from "../../../../assets/userlogo.svg";
import menu from "../../../../assets/menu.svg";
import logo1 from "../../../../assets/okkologo.svg";
import photo1 from "../../../../assets/fakeImages/photo4.png";
import Calendar from "react-calendar";
import React, { useState } from "react";
import qrCode from "../../../../assets/fakeImages/qrCode.png";

const CancelTicket = () => {
  const [value, onChange] = useState(new Date());
  const fakeData = [
    {
      id: 1,
      title: "Zippline Watergarden",
      description:
        "İstanbul’un merkezinde, ailecek gidebileceğiniz benzersiz bir eğlence parkuru var!Zippline, dev salıncak, macera parkuru, tırmanma duvarı, futbol sahası, atlı karınca, bowling ve trambolin ile WaterGarden; İstanbul eğlence anlayışına yepyeni bir soluk getiriyor.",
      img: fakeImage1,
      workTime: "Hafta içi : 13:00 - 21:00, Hafta sonu: 12:00 - 21:00",
      rules: [
        "Satın alınan biletleri dilediğiniz gün kullanabilirsiniz.",
        " Bilet satın aldıktan sonra rezervasyon nnumaranız veya QR kodu ile giriş yapabilirsiniz.",
        " Randevu için alınan biletler sadece randevu saatinde geçerlidir. İptali için önceden mutlaka haber vermelisiniz.",
      ],
      address: "Kuzguncuk, Gümüşyolu Çk Sk No:4, 34674 Üsküdar/İstanbul",
      phone: "+90 (536) 692 65 93",
    },
  ];

  const ticketFakeData = [
    {
      id: 1,
      title: "Zippline Watergarden",
      name: "Mehmet Ali",
      date: "11 Kasım 2023 - Cumartesi | 10:30 - 10:45",
      business: "Watergarden - Zippline (7+ Yaş)",
      place: "Watergarden AVM",
      persons: "1 Kişi (7+ Yaş)",
      totalPrice: "1000,00 TL (+KDV Dahil)",
      qrCode: qrCode,
      pnr: "314159911",
    },
  ];
  const questions = [
    "Biletim Ne Zamana Kadar Geçerli?",
    "Biletim Ne Zamana Kadar Geçerli?",
    "Biletim Ne Zamana Kadar Geçerli?",
    "Biletim Ne Zamana Kadar Geçerli?",
    "Biletim Ne Zamana Kadar Geçerli?",
    "Biletim Ne Zamana Kadar Geçerli?",
  ];

  const answers = [
    "Biletiniz bilette yazan randevu tarihinize kadar geçerlidir..",
    "Biletiniz bilette yazan randevu tarihinize kadar geçerlidir..",
    "Biletiniz bilette yazan randevu tarihinize kadar geçerlidir..",
    "Biletiniz bilette yazan randevu tarihinize kadar geçerlidir..",
    "Biletiniz bilette yazan randevu tarihinize kadar geçerlidir..",
    "Biletiniz bilette yazan randevu tarihinize kadar geçerlidir..",
  ];

  const handleReject = () => {
    // alert("İptal edildi");
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/"} />
      <Container className={styles.container}>
        <Title className={styles.upperTitle}>Macera, Adrenalin, Mutluluk...</Title>
        <Description className={styles.description}>Eğlencenin merkezine hoş geldin!</Description>
        <TicketBox
          logo={logo1}
          photo={photo1}
          imageText={fakeData[0].title}
          name={ticketFakeData[0].name}
          date={ticketFakeData[0].date}
          business={ticketFakeData[0].business}
          place={ticketFakeData[0].place}
          persons={ticketFakeData[0].persons}
          totalPrice={ticketFakeData[0].totalPrice}
          qrCode={ticketFakeData[0].qrCode}
          pnrCode={ticketFakeData[0].pnr}
        />

        {/* <AuthBox className={styles.sssAuthBox}> */}
        <Description className={styles.cancelDescription}>İptal Sebebinizi açıklar mısınız?</Description>
        <AuthBox className={"bg-white w-full flex max-w-[1206px] drop-shadow-2xl"}>
          <textarea className={styles.textArea} placeholder="İptal sebebinizi açıklar mısınız?" />
        </AuthBox>
        {/* </AuthBox> */}
        <button onClick={handleReject}>
          <Title className={styles.rejectButton}>İptal Et</Title>
        </button>
      </Container>
      <Footer />
    </div>
  );
};

export default CancelTicket;
