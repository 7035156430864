import { useState, useEffect, useContext } from "react";
import {
  Header,
  Container,
  Description,
  Title,
  AuthBox,
  Footer,
  TicketButtonBox,
  AuthButton,
  AuthInput,
} from "../../../../components";
import styles from "./style.module.css";
import okkologo from "../../../../assets/okkologo.svg";
import userlogo from "../../../../assets/userlogo.svg";
import menu from "../../../../assets/menu.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { axiosClient } from "../../../../api/config";
import BusinessContext from "../../../../context/business";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import InputMask from "react-input-mask";
import { successMessage } from "../../../../helpers/toast";
import MiniLoading from "../../../../components/Loading/MiniLoading";
import OkkoModal from "../../../../components/OkkoModal";

const CreditCardInput = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(false);

  const selectedDate = searchParams.get("tarih") || "";
  const selectedHours = searchParams.get("saat") || "";
  const selectedUsers = searchParams.get("kullanıcılar") || "";

  const { isletmeId, biletId } = useParams();

  const [branch, setBranch] = useState(null);
  const [ticket, setTicket] = useState(null);
  const [apiLoading, setApiLoading] = useState(false);

  const { completePurchase } = useContext(BusinessContext);

  const [usersStateArray, setUsersStateArray] = useState([]);
  const [hoursStateArray, setHoursStateArray] = useState([]);

  const [payingModal, setPayingModal] = useState(false);
  const [couponCode, setCouponCode] = useState("");

  const [cardState, setCardState] = useState({
    creditNumber: "",
    name: "",
    expiry: "",
    cvc: "",
    issuer: "",
    focus: "",
  });

  const [newPrice, setNewPrice] = useState(0);
  const [disabledButton, setDisabledButton] = useState(false);

  useEffect(() => {
    getMultipleResponse();

    return () => {
      setBranch(null);
      setTicket(null);
    };
  }, []);

  useEffect(() => {
    let usersArray = JSON.parse(selectedUsers);
    setUsersStateArray(usersArray);
    let hoursArray = selectedHours.split(",");
    setHoursStateArray(hoursArray);

    let totalPrice = ticket?.price * usersArray.length;
    setNewPrice(totalPrice);
  }, [selectedDate, selectedHours, selectedUsers, ticket]);

  const getMultipleResponse = async () => {
    setApiLoading(true);
    try {
      const [branchInfoResponse, ticketInfoResponse] = await Promise.all([
        axiosClient.get(`/users/get-branch/branch=${isletmeId}`),
        axiosClient.get(`/users/get-ticket/${biletId}`),
      ]);

      setBranch(branchInfoResponse?.data?.data?.branch);
      setTicket(ticketInfoResponse?.data?.data);
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
      setApiLoading(false);
    }
  };

  const register = () => {};

  const applyCouponCode = async () => {
    try {
      const response = await axiosClient.post("/personel/get-campaign-code", {
        campaign_code: couponCode,
      });

      let is_indirim = response.data.data.is_indirim;
      let indirim_rate = response.data.data.indirim_rate;

      let lastTicketPrice = 0;

      let selectedHoursLength = selectedHours.split(",").length;

      if (is_indirim == true) {
        // yüzde
        lastTicketPrice = newPrice - (newPrice * indirim_rate) / 100;
      } else {
        // number
        lastTicketPrice = newPrice - indirim_rate;
      }
      setNewPrice(lastTicketPrice);
      successMessage("Kupon kodu başarıyla uygulandı.");
      setDisabledButton(true);
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  const finishPurchase = async () => {
    setIsLoading(true);
    let users = JSON.parse(selectedUsers);
    let hours = selectedHours.split(",");

    let cardInfos = {
      card_holder_name: cardState.name,
      card_number: cardState.creditNumber.replace(/\s+/g, ""),
      card_expiration_month: cardState.expiry.split("/")[0],
      card_expiration_year: cardState.expiry.split("/")[1],
      card_cvv: cardState.cvc,
    };

    let paymentUsers = users.map((user, index) => {
      return {
        name: user.namesurname,
        phone: user.phone,
        email: user.email,
        tc: user.tcno,
        game_time: hours[index].split("-")[0],
      };
    });

    let isOkay = await completePurchase(
      biletId,
      paymentUsers,
      cardInfos,
      selectedDate,
      false, // isCampaign
      couponCode, // couponCode
      false // kapıda odeme
    );

    if (isOkay) {
      successMessage(
        "Satın alma işlemi başarılı. Mailinizi ve telefon numarasınızı kontrol ediniz."
      );
      navigate("/");
    }
    setIsLoading(false);
  };

  // card
  const handleInputChange = (evt) => {
    const { name, value } = evt.target;

    setCardState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputFocus = (evt) => {
    setCardState((prev) => ({ ...prev, focus: evt.target.name }));
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <OkkoModal
        modalIsOpen={payingModal}
        approve={finishPurchase}
        closeModal={() => setPayingModal(false)}
        approveButtonText="Onayla"
        declineButtonText="Vazgeç"
        title="Kart İle Ödeme Onayı"
        altText="Kart ile ödeme yapmak istediğinize emin misiniz?"
        isLoading={isLoading}
      />
      <Header
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink="/profil"
        homeLink={"/"}
      />
      <Container className={styles.container}>
        {/* <Title className={styles.upperTitle}>
          Macera, Adrenalin, Mutluluk...
        </Title>
        <Description className={styles.description}>
          Eğlencenin merkezine hoş geldin!
        </Description> */}

        {/* <BuyTicketUpperBox
          logo={okkologo}
          photo={`${API_URL}` + branch?.image}
          description={branch?.description}
          imageText={branch?.name}
        /> */}

        <Title className={styles.midTitle}>Bilet Detayları</Title>
        <TicketButtonBox className={styles.ticketButtonBox}>
          <Description className={styles.ticketButtonBoxTitle2}>
            {ticket?.name}
          </Description>
        </TicketButtonBox>

        <div className="flex flex-col">
          <p className="text-center font-semibold text-lg">{selectedDate}</p>

          <div className="flex gap-4 text-xl mt-3">
            <div>
              {usersStateArray.map((user) => (
                <p>{user?.namesurname}</p>
              ))}
            </div>
            <div>
              {hoursStateArray.map((hour) => (
                <p>{hour.split("-")[0]}</p>
              ))}
            </div>
          </div>
        </div>

        <AuthBox
          className={
            "bg-white drop-shadow-xl py-4 rounded-3xl mt-0 justify-center mb-4"
          }
        >
          <Title className={"text-xl mx-1 my-1"}>
            Toplam Fiyat: {newPrice} TL (+KDV Dahil)
          </Title>
        </AuthBox>

        <div className="flex flex-col md:flex-row items-center justify-center gap-8 mt-8">
          <AuthInput
            register={register}
            className={[styles.maskInput, "mb-0"].join(" ")}
            name="Kupon Kodu"
            placeholder="Kupon Kodu"
            value={couponCode}
            onChange={() => {
              setCouponCode(event.target.value);
            }}
            maxLength={10}
          />
          <AuthButton
            className="px-2 w-40 text-sm"
            onClick={() => {
              applyCouponCode();
            }}
            disabled={disabledButton}
          >
            Kupon Kodunu Uygula
          </AuthButton>
        </div>

        <div className="flex flex-col md:flex-row items-center gap-8 mt-8">
          <form className="max-w-sm mx-auto">
            <input
              className={styles.maskInput}
              type="text"
              name="name"
              placeholder="Kart Sahibinin İsmi"
              value={cardState.name}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              maxLength={24}
            />

            <InputMask
              className={styles.maskInput}
              name="creditNumber"
              placeholder="Kart Numarası"
              value={cardState.creditNumber}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              mask="9999 9999 9999 9999"
            />

            <InputMask
              name="expiry"
              placeholder="Son Kullanma Tarihi (MM/YY)"
              value={cardState.expiry}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              className={styles.maskInput}
              mask="99/99"
            />

            <InputMask
              className={styles.maskInput}
              type="text"
              name="cvc"
              placeholder="CVC"
              value={cardState.cvc}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              mask="999"
            />
          </form>

          <Cards
            number={cardState.creditNumber}
            expiry={cardState.expiry}
            cvc={cardState.cvc}
            name={cardState.name}
            focused={cardState.focus}
            placeholders={{ name: "İSİM SOYİSİM" }}
          />
        </div>
        <AuthButton
          className="px-8 m-8"
          onClick={() => {
            setPayingModal(true);
          }}
        >
          {isLoading ? <MiniLoading /> : "Ödeme Yap"}
        </AuthButton>
      </Container>

      <Footer />
    </div>
  );
};

export default CreditCardInput;
