import styles from "./style.module.css";

const InputError = ({ text = "", className, ...props }) => {
  return (
    <p className={[styles.error, className].join(" ")} {...props}>
      {text}
    </p>
  );
};

export default InputError;
