import styles from "./style.module.css";
import Description from "../../Text/Description";
import { Link } from "react-router-dom";

const BusinessesBox = ({
  children,
  className,
  logo,
  photo,
  toLink = "",
  ...props
}) => {
  return (
    <div className={[styles.businessesbox, className].join(" ")} {...props}>
      <Link
        className={[styles.businessesbox, className].join(" ")}
        {...props}
        to={toLink}
      >
        <img src={photo} className={styles.photo}></img>
        <img src={logo} className={styles.logo}></img>
      </Link>
      <div className={styles.lowerdescarea}>
        <Description className="text-white text-[20px] leading-[20px] text-center w-full font-[500] tracking-[0.01em]">
          {children}
        </Description>
      </div>
    </div>
  );
};

export default BusinessesBox;
