import { useState, useEffect } from "react";
import {
  Header,
  Footer,
  Container,
  PagesBox,
  Title,
  Description,
  BgBox,
  TechnicalControlsBox,
  OpportunityBox,
  DataPagesBox,
} from "../../../components";
import styles from "./style.module.css";
import redsplash from "../../../assets/splash/redsplash.svg";
import cyansplash from "../../../assets/splash/cyansplash2.svg";
import okkologo from "../../../assets/okkoadminlogo.svg";
import userlogo from "../../../assets/userlogowhite.svg";
import menu from "../../../assets/menuwhite.svg";
import { ReactComponent as Edit } from "../../../assets/edit.svg";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { API_URL } from "../../../utils/url";
import { Link } from "react-router-dom";
import { getServerErrorMessage } from "../../../helpers/auth";
import { getFormattedDate } from "../../../helpers/year";
import { currencyFormatter } from "../../../helpers/currency";

const AdminHome = () => {
  const axiosClient = useAxiosClient();

  const [news, setNews] = useState([]);
  const [technicalControls, setTechnicalControls] = useState([]);
  const [branches, setBranches] = useState([]);

  const [totalMoney, setTotalMoney] = useState(0);

  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    getNews();
    getTechnicalControls();
    getAllBranch();
    getTotalMoney();
  }, []);

  const getNews = async () => {
    try {
      const response = await axiosClient.get("/superuser/get-all-news");
      setNews(response?.data?.data);
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
    }
  };

  const getAllBranch = async () => {
    try {
      const response = await axiosClient.get("/superuser/get-all-branch");
      setBranches(response?.data?.data);
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
    }
  };

  const getTechnicalControls = async () => {
    try {
      const response = await axiosClient.get("/superuser/get-all-technical-controls");
      setTechnicalControls(response?.data?.data);
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
    }
  };

  const getTotalMoney = async () => {
    try {
      const response = await axiosClient.get(`/superuser/get-ciro/date=${getFormattedDate(selectedDate)}/filter=year`);
      setTotalMoney(response?.data?.data?.total_money);
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
    }
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header className="bg-primary" okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink={"/profil"} homeLink={"/admin"} />
      <Container className={styles.container}>
        {" "}
        <Title className={styles.upperTitle}>Macera, Adrenalin, Mutluluk...</Title>
        <Description className={styles.description}>Eğlencenin merkezine hoş geldin!</Description>
        <BgBox
          className={styles.bgBox}
          img1={cyansplash}
          img2={redsplash}
          img1Style="top-[10%] left-[70%]"
          img2Style="bottom-[40%] right-[76%]"
          windowsize={1250}
        >
          <PagesBox link1="/admin/isletmeler" link2="/pnr-sorgula"></PagesBox>
        </BgBox>
        <Link
          to="/admin/total-toplam-ciro"
          className="mt-16 mb-16 flex flex-col items-center gap-2 bg-primary max-w-sm w-full py-4 rounded-lg text-white"
        >
          <h3 className="text-xl">Toplam Ciro (detay için tıkla)</h3>
          <p className="font-bold text-2xl">{currencyFormatter(parseFloat(totalMoney)) + " TL"} </p>
        </Link>
        <Title className="text-[#454545] font-[500]">Günlük Teknik Kontrol Durumu</Title>
        <>
          {technicalControls.filter((item) => item.status === false).length > 0 && (
            <Description className={styles.desc}>Tamamlanmayan İşletmeler</Description>
          )}
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-8 px-4">
            {technicalControls.filter((item) => item.status === false).length > 0 ? (
              technicalControls.map(
                (item, index) =>
                  item.status === false && (
                    <TechnicalControlsBox
                      className={styles.technicalControlsBox}
                      children={item.branch_name}
                      logo={item.branch_image}
                      isletmeId={item.branch_id}
                      isAdmin={true}
                      active={item.is_active}
                      key={index}
                    />
                  )
              )
            ) : (
              <Description className={styles.desc}>Tamamlanmayan teknik kontrol bulunmamaktadır...</Description>
            )}
          </div>
          {technicalControls.filter((item) => item.status === true).length > 0 && (
            <Description className={styles.desc}>Tamamlanan İşletmeler</Description>
          )}
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-8 px-4">
            {technicalControls.map(
              (item, index) =>
                item.status === true && (
                  <TechnicalControlsBox
                    className={styles.technicalControlsBoxActive}
                    children={item.branch_name}
                    logo={item.branch_image}
                    isletmeId={item.branch_id}
                    isAdmin={true}
                    active="active"
                    key={index}
                  />
                )
            )}
          </div>
        </>
        <DataPagesBox data={branches} />
        <Title className={styles.bottomTitle}>Fırsatlar ve Kampanyalar</Title>
        <Link className={styles.buttonbox} to={`/admin/kampanya-ekle`}>
          <Description>Kampanya Ekle</Description>
        </Link>
        {news.map((item) => (
          <OpportunityBox key={item.id} img={API_URL + item.image} imgStyle={" h-[182px] w-[272px] "}>
            <Title className={styles.opportunityBoxTitleText}>{item.title}</Title>
            {/* Add edit icon to rıght top */}
            <Link to={`/admin/kampanya-duzenle/${item.id}`}>
              <Edit className={styles.editIcon} />
              <div className={[styles.checkboxOffer, item.is_active ? "bg-green-500" : "bg-gray-500"].join(" ")}></div>
              {/* <CheckBox
                className={styles.checkbox}
                checkedValue={item.is_active}
                checkBoxBorderColor="border-[#707376] bg-[#707376] checked:bg-emerald-500 checked:border-transparent "
              /> */}
            </Link>
            <Description className={styles.opportunityBoxDescriptionText}>{item.description}</Description>
          </OpportunityBox>
        ))}
        {/* <Weather /> */}
      </Container>
      <Footer />
    </div>
  );
};

export default AdminHome;
