import styles from "./style.module.css";

const Title = ({ children, className, type = "h2", ...props }) => {
  const Tag = type;

  return (
    <Tag className={[styles.title, className].join(" ")} {...props}>
      {children}
    </Tag>
  );
};

export default Title;
