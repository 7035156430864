import {
  Header,
  Container,
  Description,
  Title,
  BuyTicketUpperBox,
  AuthBox,
  Footer,
  TicketButtonBox,
  AuthButton,
  SSSBox,
  BgBox,
  Checkbox,
} from "../../../../components/";
import styles from "./style.module.css";
import okkologo from "../../../../assets/okkologo.svg";
import userlogo from "../../../../assets/userlogo.svg";
import menu from "../../../../assets/menu.svg";
import { useEffect, useState } from "react";
import { ReactComponent as Phone } from "../../../../assets/icons/phone.svg";
import AddressMap from "../../../../components/AddressMap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getServerErrorMessage } from "../../../../helpers/auth";
import { axiosClient } from "../../../../api/config";
import MiniLoading from "../../../../components/Loading/MiniLoading";
import { API_URL } from "../../../../utils/url";
import { usePreferredLanguage } from "@uidotdev/usehooks";

const ChooseTicket = () => {
  const navigate = useNavigate();
  const { isletmeId } = useParams();
  const language = usePreferredLanguage();

  const [apiLoading, setApiLoading] = useState(false);

  const [securityAccepted, setSecurityAccepted] = useState(false);

  const [branch, setBranch] = useState(null);
  const [branchTickets, setBranchTickets] = useState([]);
  const [branchWorkingHours, setBranchWorkingHours] = useState(null);
  const [branchFaqs, setBranchFaqs] = useState([]);

  useEffect(() => {
    if (isletmeId !== null || isletmeId !== undefined) {
      getBranchInfos();
    }

    return () => {
      setBranch(null);
      setBranchTickets(null);
      setBranchWorkingHours(null);
      setBranchFaqs(null);
    };
  }, [isletmeId]);

  const isLangTr = () => {
    return language.includes("tr");
  };

  const getBranchInfos = async () => {
    setApiLoading(true);
    try {
      const response = await axiosClient.get(`/users/get-branch/branch=${isletmeId}`);

      setBranch(response?.data?.data?.branch); // object
      setBranchTickets(response?.data?.data?.tickets); // array
      setBranchWorkingHours(response?.data?.data?.working_hours); // object
      setBranchFaqs(response?.data?.data?.faq); // array
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
      setApiLoading(false);
    }
  };

  const buttonColor = securityAccepted
    ? "bg-white"
    : "bg-gray-300 cursor-not-allowed hover:bg-gray-300 text-gray-600 hover:text-gray-600 border-gray-300";

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/"} />
      <Container className={styles.container}>
        <Title className={styles.upperTitle}>Macera, Adrenalin, Mutluluk...</Title>
        <Description className={styles.description}>Eğlencenin merkezine hoş geldin!</Description>

        {apiLoading && <MiniLoading className="mb-8" />}

        <BuyTicketUpperBox
          logo={okkologo}
          photo={`${API_URL}` + branch?.image}
          description={branch?.description}
          imageText={branch?.name}
        />

        <Checkbox
          id="agreements"
          className={styles.midTitle}
          checkedValue={securityAccepted}
          onChangeValue={() => setSecurityAccepted(!securityAccepted)}
          checkBoxBorderColor="mt-1 h-[24px] w-[24px] rounded-md border-[#F80A3A]"
        >
          {" "}
          <div className="text-[#454545] text-2xl">
            <Link to={`/isletmeler-beyan/${isletmeId}/beyanlar`} className="underline">
              Güvenlik kurallarını ve talimatlarını
            </Link>{" "}
            Okudum, Onaylıyorum.
          </div>{" "}
        </Checkbox>

        <Title className={styles.midTitle}>Biletler</Title>
        {branchTickets?.map((ticket, index) => {
          //let showTicket = true;
          let showTicket = isLangTr() ? !ticket.ticket_name.includes("YABANCI") : ticket.ticket_name.includes("YABANCI");
          if (showTicket) {
            return (
              <TicketButtonBox key={index} className={styles.ticketButtonBox}>
                <Link className={"md:grid  md:grid-cols-5 w-full"} to={`/isletmeler/${isletmeId}/${ticket.id}`}>
                  <Description className={styles.ticketButtonBoxTitle}>{ticket?.ticket_name}</Description>
                  <Description className={styles.ticketButtonBoxPrice}>{ticket?.price}₺</Description>

                  <AuthButton
                    className={[styles.ticketButtonBoxButton, buttonColor].join(" ")}
                    onClick={() => {
                      navigate(`/isletmeler/${isletmeId}/${ticket.id}`);
                    }}
                    disabled={!securityAccepted}
                  >
                    Seç
                  </AuthButton>
                </Link>
              </TicketButtonBox>
            );
          } else {
            return null;
          }
        })}
        {/* <AuthBox className={styles.authBox}>
          <Title className={styles.opportunityBoxTitleText}>
            {fakeData[0].title} Hakkında
          </Title>
          <Description className={styles.opportunityBoxDescriptionText}>
            {fakeData[0].description}
          </Description>
        </AuthBox> */}
        <AuthBox className={styles.titleBox}>{/* <Title className={styles.bottomTitle}>Detaylar ve Kurallar</Title> */}</AuthBox>
        <AuthBox className={styles.authBox2}>
          <Title className={styles.opportunityBoxTitleText}>Çalışma Saatleri</Title>
          <Description className={styles.opportunityBoxDescriptionText}>{branchWorkingHours?.hours}</Description>
        </AuthBox>
        <AuthBox className={styles.authBox2}>
          <Title className={[styles.opportunityBoxTitleText, "underline"].join(" ")}>Bilet Kullanım Koşulları</Title>

          <Description
            // key={index}
            className={styles.opportunityBoxDescriptionText}
          >
            {branchWorkingHours?.additional_details.split("*").map((item, index) => (
              <span key={index}>
                {item.length > 0 && "-"}
                {item}
                <br />
              </span>
            ))}
          </Description>
        </AuthBox>
        <AuthBox className={styles.titleBox}>
          <Title className={styles.bottomTitle}>{branch?.name} ve Macera Parkı Bileti SSS (Sıkça Sorulan Sorular)</Title>
        </AuthBox>
        <SSSBox data={branchFaqs} className={styles.sssBox} />
        <BgBox className={styles.bgBox} windowsize={640}>
          <div className={"md:grid md:grid-cols-2 sm:grid-cols-1 gap-4"}>
            <AuthBox className={styles.authBox3}>
              <Title className={styles.contactTitle}>
                <Phone className="" width={40} height={40} />
                İletişim
              </Title>
              <Title className={"text-start w-full flex mt-4 text-2xl mb-0 font-medium"}>Adres:</Title>
              <Description className={styles.contactDescription}>{branch?.address}</Description>
              <Title className={"text-start w-full flex mt-8 text-2xl mb-0 font-medium"}>Telefon Numarası:</Title>
              <Description className={styles.contactDescription}>{branch?.phone}</Description>
            </AuthBox>
            <AuthBox className={styles.authBox4}>{branch && <AddressMap location={branch?.location} />}</AuthBox>
          </div>
        </BgBox>
      </Container>
      <Footer />
    </div>
  );
};

export default ChooseTicket;
