import moment from "moment";
import { convertMonthToTurkish } from "../../helpers/months";

const DatePrettier = ({ selectedDate, className }) => {
  return (
    <div className={["text-lg", className].join(" ")}>
      {moment(selectedDate).format("DD")}
      <span className="mx-2">
        {convertMonthToTurkish(moment(selectedDate).format("MMMM"))}
      </span>
      {moment(selectedDate).format("YYYY")}
    </div>
  );
};

export default DatePrettier;
