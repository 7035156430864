import { useState, useEffect } from "react";
import { Header, SecurityDataForm } from "../../../../components/";
import okkologo from "../../../../assets/okkologo.svg";
import userlogo from "../../../../assets/userlogo.svg";
import menu from "../../../../assets/menu.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import MiniLoading from "../../../../components/Loading/MiniLoading";
import { getServerErrorMessage } from "../../../../helpers/auth";
import { axiosClient } from "../../../../api/config";

const ChooseTicketBeyanlar = () => {
  const navigate = useNavigate();
  const { isletmeId } = useParams();

  const [securityData, setSecurityData] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);

  useEffect(() => {
    getBranchInfos();

    return () => {
      setSecurityData([]);
    };
  }, [isletmeId]);

  const getBranchInfos = async () => {
    setApiLoading(true);
    try {
      const response = await axiosClient.get(
        `/users/get-branch/branch=${isletmeId}`
      );

      setSecurityData(response?.data?.data?.beyanlar); // array
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
      setApiLoading(false);
    }
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink="/profil"
        homeLink={"/"}
      />

      {apiLoading && <MiniLoading className="mb-8" />}

      <div className="px-8 lg:px-36 py-8">
        <Link
          to={".."}
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          className="text-primary text-2xl font-bold mb-8"
        >
          GERİ DÖN
        </Link>
        <h1 className="text-3xl font-semibold mt-3">BEYANLAR</h1>

        <SecurityDataForm data={securityData} />
      </div>
    </div>
  );
};

export default ChooseTicketBeyanlar;
