import { ReactComponent as Clock } from "../../../assets/clock.svg";
import { ReactComponent as CheckCircle } from "../../../assets/checkcircle.svg";
import styles from "./style.module.css";
import { API_URL } from "../../../utils/url";
import { Link } from "react-router-dom";

const TechnicalControlsBox = ({ children, className, logo, active, isletmeId, isAdmin = false, ...props }) => {
  return (
    <Link
      to={isAdmin ? `/admin/teknik-kontrol-anketi-gereksinim/${isletmeId}` : `/personel/teknik-kontrol-anketi/`}
      className={[styles.box, className].join(" ")}
      {...props}
    >
      <img src={`${API_URL}${logo}`} className={styles.logo}></img>
      <div className={styles.text}>{children}</div>
      {!active && <Clock className={styles.icon} />}
      {active && <CheckCircle className={styles.icon} />}
    </Link>
  );
};

export default TechnicalControlsBox;
