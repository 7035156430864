import styles from "./style.module.css";
import { Link } from "react-router-dom";

const LinkButton = ({ children, className, ...props }) => {
  return (
    <div className="w-full flex justify-center">
      <Link className={[styles.linkbutton, className].join(" ")} {...props}>
        {children}
      </Link>
    </div>
  );
};

export default LinkButton;
