import AuthBox from "../../Box/AuthBox";
import styles from "./style.module.css";
import { useState, useRef } from "react";
import cn from "classnames";
import ReactPaginate from "react-paginate";
import { ReactComponent as RedArrow } from "../../../assets/redarrowright.svg";
import moment from "moment";
import { ReactComponent as Trash } from "../../../assets/trash.svg";

const CampaignTable = ({
  totalPageCount,
  getHandlePageClick,
  searching,
  setSearching,
  className,
  codes,
  deleteCode = () => {},
  ...props
}) => {
  const inputRef = useRef(null);

  const [paginate, setPaginate] = useState(0);
  const [pageData, setPageData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const handlePageClick = (data) => {
    let selected = data.selected;
    setPaginate(selected);
    setPageData(codes.slice(selected * 10, selected * 10 + 10));
    getHandlePageClick(selected + 1);
  };

  const handleClickKeyDown = (e) => {
    if (e.key === "Enter") {
      setPageData(codes.slice((Number(e.target.value) - 1) * 10, (Number(e.target.value) - 1) * 10 + 10));
      setPaginate(Number(e.target.value) - 1);
      getHandlePageClick(Number(e.target.value));
    }
  };

  /* const handleClick = () => {
    setPageData(
      codes.slice(
        (Number(e.target.value) - 1) * 10,
        (Number(e.target.value) - 1) * 10 + 10
      )
    );
    setPaginate(Number(e.target.value) - 1);
  }; */

  return (
    <div className={[styles.tableCont, className].join(" ")} {...props}>
      <AuthBox className={styles.authBox}>
        <input
          className={styles.input}
          placeholder="Kampanya Numarası, Kullanıcı Adı"
          value={searching == "all" ? "" : searching}
          onChange={(e) => setSearching(e.target.value)}
        />
      </AuthBox>
      <AuthBox className={styles.authBox2}>
        <div className="flex flex-col min-w-[1000px] w-full">
          <div className={styles.titles}>
            <div className={styles.title1}>Kampanya Oluşturucusu</div>
            <div className={styles.title2}>Kampanya Kodu</div>
            <div className={styles.title3}>Kullanım Durumu</div>
            <div className={styles.title4}>Oluşturulma Tarihi</div>
            <div className={styles.title5}>İndirim Miktarı</div>
            <div className={styles.title6}>Sil</div>
          </div>
          <div className="flex flex-col w-full mt-5  gap-2">
            {codes &&
              codes.map((code, index) => (
                <div className={cn(styles.datas, index % 2 === 0 ? styles.datas2 : null)} key={index}>
                  <div className={styles.data1}>{code?.from_user}</div>
                  <div className={styles.data2}>{code?.campaign_code}</div>
                  <div className={styles.data3}>
                    {code?.is_used ? (
                      <span className="text-green-500">Kullanıldı</span>
                    ) : (
                      <span className="text-red-500">Kullanılmadı</span>
                    )}
                  </div>
                  <div className={styles.data4}>{moment(code?.created_at).format("DD.MM.YYYY HH:mm")}</div>
                  <div className={[styles.data5, "flex items-center"].join(" ")}>
                    <span>{code?.indirim_rate}</span>
                    {code?.is_indirim ? "%" : "TL"}
                  </div>
                  <button
                    className={styles.data6}
                    onClick={() => {
                      deleteCode(code?.id);
                    }}
                  >
                    <Trash color="red" />
                  </button>
                </div>
              ))}
          </div>
        </div>
      </AuthBox>

      <div className={styles.paginateContainer}>
        <ReactPaginate
          className={styles.paginate}
          breakLabel="..."
          nextLabel=">"
          previousLabel="<"
          nextLinkClassName="text-primary"
          previousLinkClassName="text-primary"
          nextClassName={styles.pageNextClassname}
          previousClassName={styles.pagePreviousClassname}
          onPageChange={(e) => handlePageClick(e)}
          pageRangeDisplayed={5}
          pageCount={totalPageCount}
          renderOnZeroPageCount={false}
          forcePage={paginate}
          pageClassName={styles.pageClassname}
          activeClassName={styles.activeClassname}
        />
        {
          <div className={styles.paginateGitContainer}>
            <label htmlFor="Git" className="text-primary">
              Git
            </label>
            <input
              autoComplete="off"
              ref={inputRef}
              maxLength="3"
              placeholder="1"
              className={styles.paginateGitInput}
              type="text"
              id="Git"
              onKeyDown={(e) => {
                handleClickKeyDown(e);
              }}
            />
            <button /*onClick={(e)=> handleClick(e)} */>
              <RedArrow width="1.5em" height="1.5em" className="absolute right-1 top-[5px] text-primary" />
            </button>
          </div>
        }
      </div>
    </div>
  );
};

export default CampaignTable;
