import { useState, useEffect } from "react";
import styles from "./style.module.css";
import okkologo from "../../../assets/okkologo.svg";
import userlogo from "../../../assets/userlogo.svg";
import menu from "../../../assets/menu.svg";
import fakeImage1 from "../../../assets/fakeImages/photo1.png";
import logo1 from "../../../assets/okkologo.svg";
import photo1 from "../../../assets/fakeImages/photo4.png";
import {
  Header,
  Title,
  Description,
  BuyTicketUpperBox,
  TechnicalControlsBox,
  Container,
  TechnicianDatabaseTable,
  BusinessRequirementsFormPersonel,
} from "../../../components";

const TechnicalControlSurvey = () => {
  return (
    <div className="grid grid-rows-layoutAuth">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/personel"} />
      <Container className={styles.container}>
        {/* <Title className={styles.upperTitle}>
          Macera, Adrenalin, Mutluluk...
        </Title>
        <Description className={styles.description}>
          Eğlencenin merkezine hoş geldin!
        </Description> */}
        <Description className={styles.description2}>İşletme Gereksinimleri Formu</Description>
        <BusinessRequirementsFormPersonel />
      </Container>
    </div>
  );
};

export default TechnicalControlSurvey;
