import styles from "./style.module.css";

const AuthBox = ({ children, className, ...props }) => {
  return (
    <div className={[styles.authbox, className].join(" ")} {...props}>
      {children}
    </div>
  );
};

export default AuthBox;
