import { useState, useEffect, useContext } from "react";
import {
  Header,
  Container,
  Description,
  Title,
  BuyTicketUpperBox,
  Footer,
  TicketButtonBox,
  SSSBox,
  AuthBox,
  TicketBox,
  AuthButton,
} from "../../../components";
import styles from "./style.module.css";
import fakeImage1 from "../../../assets/fakeImages/photo1.png";
import okkologo from "../../../assets/okkologo.svg";
import userlogo from "../../../assets/userlogo.svg";
import menu from "../../../assets/menu.svg";
import logo1 from "../../../assets/okkologo.svg";
import photo1 from "../../../assets/fakeImages/photo4.png";
import qrCode from "../../../assets/fakeImages/qrCode.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getServerErrorMessage } from "../../../helpers/auth";
import { axiosClient } from "../../../api/config";
import { successMessage } from "../../../helpers/toast";
import InputMask from "react-input-mask";
import OkkoModal from "../../../components/OkkoModal";
import AuthContext from "../../../context/auth";

const SearchPnr = () => {
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);

  const [searchState, setSearchState] = useState({
    phone: "",
    pnr: "",
  });

  const [reason, setReason] = useState("");
  const [ticket, setTicket] = useState(null);
  const [reasonBox, setReasonBox] = useState(false);
  const [reasonText, setReasonText] = useState("");

  const [cancelModal, setCancelModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const searchPnr = async () => {
    // let newPhone = searchState.phone.replace(/[\(\)\s]/g, "");
    // newPhone = "90" + newPhone;

    try {
      const response = await axiosClient.post(
        `/users/search-pnr`,
        JSON.stringify({
          // phone: newPhone,
          pnr: searchState.pnr,
        })
      );

      setTicket(response?.data?.data);

      //navigate(-1);
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  const cancelTicket = async () => {
    let newPhone = searchState.phone.replace(/[\(\)\s]/g, "");
    newPhone = "90" + newPhone;

    try {
      const response = await axiosClient.post(
        `/users/cancel-ticket`,
        JSON.stringify({
          phone: newPhone,
          pnr: searchState.pnr,
          reason: reasonText,
        })
      );
      successMessage("Biletiniz başarıyla iptal edildi.");

      setTimeout(() => {
        navigate(-1);
      }, 1200);
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;

    setSearchState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <OkkoModal
        modalIsOpen={cancelModal}
        approve={cancelTicket}
        closeModal={() => setCancelModal(false)}
        approveButtonText="Onayla"
        declineButtonText="Vazgeç"
        title="İptal Etme Onayı"
        altText="İptal etmek istediğinizden emin misiniz?"
      />
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/"} />
      <Container className={[styles.container, "overflow-hidden"].join(" ")}>
        <Title className={styles.upperTitle}>Macera, Adrenalin, Mutluluk...</Title>
        <Description className={styles.description}>Eğlencenin merkezine hoş geldin!</Description>

        <div className="flex flex-col w-full mx-auto items-center">
          <Title className={styles.upperTitle}>PNR SORGULA</Title>
          {/* <InputMask
            className={styles.maskInput}
            name="phone"
            placeholder="Telefon Numarası"
            value={searchState.phone}
            onChange={handleInputChange}
            mask="(999) 999 99 99"
          /> */}

          <InputMask
            className={styles.maskInput}
            type="text"
            name="pnr"
            placeholder="PNR Numarası"
            value={searchState.pnr}
            onChange={handleInputChange}
            //mask="(999) 999 99 99"
          />

          <AuthButton
            className="px-8"
            onClick={() => {
              searchPnr();
            }}
          >
            PNR Sorgula
          </AuthButton>
        </div>

        {auth.role !== "admin" && auth.role !== "personel_lead" && auth.role !== "personel" && auth.role !== "technical_support" && (
          <Link to="/kayit-ol" className="mt-8">
            OKKO kampanyalarını kaçırmamak ve kampanyalardan haberdar olmak için{" "}
            <span className="text-primary font-semibold">Kayıt Olun!</span>
          </Link>
        )}

        {ticket && (
          <div className="mt-10">
            <TicketBox logo={logo1} photo={photo1} ticket={ticket} />
            <button
              className="w-full"
              onClick={() => {
                setReasonBox(true);
              }}
            >
              {!ticket?.is_completed && <Title className={styles.rejectButton}>İptal Et</Title>}
            </button>
          </div>
        )}

        {reasonBox && (
          // textarea
          <div className="flex flex-col items-center w-full md:w-1/2 mt-4">
            <textarea
              value={reasonText}
              onChange={(e) => setReasonText(e.target.value)}
              placeholder="İptal sebebinizi yazınız"
              className="w-full resize-none px-3 py-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline"
              rows="4"
              maxLength={240}
            />
            <AuthButton
              className="px-8 mt-3"
              onClick={() => {
                setCancelModal(true);
              }}
            >
              Bileti İptal Et
            </AuthButton>
          </div>
        )}

        {/* <Description className={styles.sssDescription}>
            SSS (Sıkça Sorulan Sorular)
          </Description>

          <SSSBox questions={questions} answers={answers} /> */}
      </Container>
      <Footer />
    </div>
  );
};

export default SearchPnr;
