import { useState, useEffect } from "react";
import { BgBox, Header, Container, Title, Description, BusinessesBoxContainer, Footer } from "../../../../components";
import styles from "./style.module.css";
import orangesplash from "../../../../assets/splash/orangesplash3.svg";
import purplesplash from "../../../../assets/splash/purplesplash3.svg";
import okkologo from "../../../../assets/okkoadminlogo.svg";
import userlogo from "../../../../assets/userlogowhite.svg";
import menu from "../../../../assets/menuwhite.svg";
import logo1 from "../../../../assets/okkologo.svg";
import photo1 from "../../../../assets/fakeImages/photo4.png";
import useAxiosClient from "../../../../hooks/useAxiosClient";
import { getServerErrorMessage } from "../../../../helpers/auth";

const AdminAllBusinesses = () => {
  const businessBoxesData = [
    { id: 1, logo: logo1, photo: photo1, name: "Okko Extreme Watergarden" },
    { id: 2, logo: logo1, photo: photo1, name: "Okko Extreme Watergarden" },
    { id: 3, logo: logo1, photo: photo1, name: "Okko Extreme Watergarden" },
    { id: 4, logo: logo1, photo: photo1, name: "Okko Extreme Watergarden" },
    { id: 5, logo: logo1, photo: photo1, name: "Okko Extreme Watergarden" },
    { id: 6, logo: logo1, photo: photo1, name: "Okko Extreme Watergarden" },
    { id: 7, logo: logo1, photo: photo1, name: "Okko Extreme Watergarden" },
  ];

  const [businesses, setBusinesses] = useState([]);

  const authAxiosClient = useAxiosClient();

  useEffect(() => {
    getBusinesses();
  }, []);

  const getBusinesses = async () => {
    try {
      const response = await authAxiosClient.get("/superuser/get-all-branch");
      setBusinesses(response?.data?.data);
    } catch (err) {
      getServerErrorMessage(err);
    } finally {
    }
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header
        className="bg-primary"
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink={"/profil"}
        homeLink={"/admin"}
      />
      <Container className={styles.container}>
        <Title className={styles.upperTitle}>Macera, Adrenalin, Mutluluk...</Title>
        <Description className={styles.description}>Eğlencenin merkezine hoş geldin!</Description>

        <Title className={styles.title}>İşletmeler</Title>
        <BgBox
          className={styles.bgBox}
          img1={orangesplash}
          img2={purplesplash}
          img1Style="bottom-[-20px] right-[-270px]"
          img2Style="xmd:top-[-270px] top-[-200px] left-[-270px] "
          windowsize={640}
        >
          <BusinessesBoxContainer className="my-custom-class" type="admin" businessBoxes={businesses} />
        </BgBox>
      </Container>
      <Footer showBg={true} />
    </div>
  );
};

export default AdminAllBusinesses;
