import React from "react";
import styles from "./style.module.css";
import dropdown from "../../../assets/arrowdown.svg";
import { useState } from "react";
import { useRef } from "react";
import useClickOutside from "../../../hooks/useClickOutside";

const Dropdown = ({ className, gender, changeGender = () => {} }) => {
  const [optionArea, setOptionArea] = useState(false);

  const buttonRef = useRef(null);

  useClickOutside(buttonRef, () => {
    setOptionArea(false);
  });

  const handleToggle = () => {
    setOptionArea(!optionArea);
  };

  const handleGenderSelect = (event) => {
    changeGender(event.target.value);
  };

  return (
    <div className={[styles.dropdown, className].join(" ")}>
      <button onClick={handleToggle} className={styles.toggleButton}>
        <div className={styles.selectedoption}>{gender}</div>

        <img src={dropdown}></img>
      </button>

      {optionArea && (
        <div className={styles.optionarea} ref={buttonRef}>
          <div>
            <button
              onClick={handleGenderSelect}
              value="Erkek"
              className={styles.option}
            >
              Erkek
            </button>
            <button
              onClick={handleGenderSelect}
              value="Kadın"
              className={styles.option}
            >
              Kadın
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
