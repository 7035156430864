import styles from "./style.module.css";

const CheckBox = ({
  children,
  className,
  checkedValue,
  onChangeValue,
  id,
  checkBoxBorderColor = "border-white",
  ...props
}) => {
  return (
    <div className={[styles.checkboxarea, className].join(" ")}>
      <input
        id={id}
        type="checkbox"
        className={[styles.checkbox, checkBoxBorderColor].join(" ")}
        {...props}
        checked={checkedValue}
        onChange={(e) => {
          onChangeValue(e.target.checked);
        }}
      />
      <label htmlFor={id} className={styles.checkboxtext}>
        {children}
      </label>
    </div>
  );
};

export default CheckBox;
