import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import RootLayout from "./layouts/RootLayout";
import NotFound from "./pages/NotFound";
import { StoreProvider } from "./context/store";
import { Toaster } from "react-hot-toast";
import {
  Login,
  Register,
  MailOtp,
  TelOtp,
  AccountCreated,
  ForgotPassword,
  ForgotPasswordOtp,
  NewPassword,
  PasswordCreated,
} from "./pages/Auth/";
import {
  UserHome,
  UserAllBusinesses,
  ChooseTicket,
  MailChanged,
  NewMail,
  NewMailOtp,
  NewTel,
  NewTelOtp,
  TelChanged,
  UserProfile,
  PasswordChanged,
  NewPasswordOtp,
  ChooseDate,
  ChooseHour,
  UserInformationInput,
  CreditCardInput,
  UserAppointments,
  UserTicket,
  SearchAppointment,
  SearchedAppointments,
  CancelTicket,
  ChooseTicketBeyanlar,
} from "./pages/User/";
import {
  AdminHome,
  AdminAllBusinesses,
  AddBusiness,
  BusinessDetail,
  PersonelDatabase,
  BusinessDatabase,
  AddPersonel,
  PersonelDetails,
  AddAgenta,
  AgentaDetails,
  EditBusiness,
  AddOffer,
  EditOffer,
  TechnicalControlSurveyAdmin,
  TotalMoney,
} from "./pages/Admin/";
import { AuthProvider } from "./context/auth";
import { motion, AnimatePresence } from "framer-motion";
import PrivateRoute from "./utils/private-routes/private-route";
import { PersonalHome } from "./pages/Personal/";
import { TechnicalControlSurvey } from "./pages/Technician";
import { PersistLogin, Unauthorized } from "./components";
import { BusinessProvider } from "./context/business";
import PersonalLeader from "./pages/PersonalLeader";
import SearchPnr from "./pages/User/SearchPnr";
import PersonalMoneyHistory from "./pages/Personal/PersonalMoneyHistory";
import PersonelSearchPnr from "./pages/Personal/PersonalSearchPnr";
import PersonalCreateMeeting from "./pages/Personal/PersonalCreateMeeting";
import PersonalCheckin from "./pages/Personal/PersonalCheckin";
import Kvkk from "./pages/Kvkk";
import { PhotoProvider } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import CreateFreeCampaign from "./pages/Admin/CreateFreeCampaign/CreateFreeCampaign";
import TechnicalControlSurveyAdminReq from "./pages/Admin/TechnicalControlSurveyAdminReq/TechnicalControlSurveyAdmin";
import TechnicalControlSurveyDetail from "./pages/Technician/TechnicalControlSurveyDetail";
import Ticket from "./pages/Ticket";
import "react-datepicker/dist/react-datepicker.css";
import Success from "./pages/Success";
import Failure from "./pages/Failure";
import "react-phone-number-input/style.css";
import * as Sentry from "@sentry/react";
import PersonalMonthlyMoney from "./pages/PersonalLeader/PersonalMonthlyMoney";
import PersonalCameraSellings from "./pages/PersonalLeader/PersonalCameraSellings";
import AdminTotalMoney from "./pages/Admin/AdminTotalMoney";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_URL,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/bilet.okko\.com.tr\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// import CouponCodeInput from "./pages/User/UserBusinesses/CouponCodeInput/CouponCodeInput";
// import PayingDoor from "./pages/User/UserBusinesses/PayingDoor/PayingDoor";
// import PersonelAccounter from "./pages/Personal/PersonalAccounter";

const pageVariants = {
  initial: {
    opacity: 0.5,
    x: "100vw",
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.23, // Adjust the duration value to control the speed of the animation
    },
  },
  exit: {
    opacity: 0.5,
    x: "-100vw",
    transition: {
      duration: 0.23, // Adjust the duration value to control the speed of the animation
    },
  },
};

const OkkoRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <motion.div key={location.pathname} initial="initial" animate="animate" exit="exit" variants={pageVariants}>
        <Routes location={location}>
          <Route path="/" element={<RootLayout />}>
            <Route element={<PersistLogin />}>
              {/* USER */}
              <Route element={<PrivateRoute allowedRoles={["normal", "admin", "personel", "technical_support", "personel_lead"]} />}>
                <Route index element={<UserHome />} />

                {/* USER BUSINESS */}
                <Route path="/isletmeler" element={<UserAllBusinesses />} />
                <Route path="/randevu-gecmisi" element={<UserAppointments />} />
                <Route path="/randevu-gecmisi/:id" element={<UserTicket />} />
                <Route path="/pnr-sorgula" element={<SearchPnr />} />

                <Route path="/isletmeler/:isletmeId" element={<ChooseTicket />} />
                <Route path="/isletmeler-beyan/:isletmeId/beyanlar" element={<ChooseTicketBeyanlar />} />
                <Route path="/isletmeler/:isletmeId/:biletId" element={<ChooseDate />} />
                <Route path="/isletmeler/:isletmeId/:biletId/saat-sec" element={<ChooseHour />} />

                <Route path="/isletmeler/:isletmeId/:biletId/saat-sec/iletisim-bilgileri" element={<UserInformationInput />} />
                {/* <Route
                  path="/isletmeler/:isletmeId/:biletId/saat-sec/iletisim-bilgileri/odeme-yontemi"
                  element={<ChoosePaymentType />}
                /> */}
                {/* <Route
                  path="/isletmeler/:isletmeId/:biletId/saat-sec/iletisim-bilgileri/odeme-yontemi/kapida-odeme"
                  element={<PayingDoor />}
                /> */}
                <Route path="/isletmeler/:isletmeId/:biletId/saat-sec/iletisim-bilgileri/banka-karti" element={<CreditCardInput />} />
                {/* <Route
                  path="/isletmeler/:isletmeId/:biletId/saat-sec/iletisim-bilgileri/odeme-yontemi/kupon-kodu"
                  element={<CouponCodeInput />}
                /> */}

                {/* USER APPOINTMENTS */}
                <Route path="/isletmeler/:isletmeId/randevularim" element={<UserAppointments />} />
                <Route path="/isletmeler/:isletmeId/randevularim/:id" element={<UserTicket />} />
                <Route path="/isletmeler/:isletmeId/randevularim/:id/iptal-et" element={<CancelTicket />} />
                <Route path="/isletmeler/:isletmeId/randevu-sorgulama" element={<SearchAppointment />} />
                <Route path="/isletmeler/:isletmeId/randevu-sorgulama/:id" element={<SearchedAppointments />} />

                <Route path="/profil/sifre-dogrula" element={<NewPasswordOtp />} />
                <Route path="/profil/sifre-degistirildi" element={<PasswordChanged />} />

                <Route path="/profil/mail-degistir" element={<NewMail />} />
                <Route path="/profil/mail-degistir/mail-dogrula" element={<NewMailOtp />} />
                <Route path="/profil/mail-degistir/mail-degistirildi" element={<MailChanged />} />

                <Route path="/profil/telefon-degistir" element={<NewTel />} />
                <Route path="/profil/telefon-degistir/telefon-dogrula" element={<NewTelOtp />} />
                <Route path="/profil/telefon-degistir/telefon-degistirildi" element={<TelChanged />} />
              </Route>

              {/* SUPER ADMIN */}
              <Route element={<PrivateRoute allowedRoles={["admin"]} />}>
                <Route path="/admin" element={<AdminHome />} />
                <Route path="/admin/total-toplam-ciro" element={<AdminTotalMoney />} />
                <Route path="/admin/isletmeler" element={<AdminAllBusinesses />} />
                <Route path="/admin/isletmeler/isletme-ekle" element={<AddBusiness />} />
                <Route path="/admin/isletmeler/isletme-duzenle/:isletmeId" element={<EditBusiness />} />
                <Route path="/admin/isletmeler/:isletmeId" element={<BusinessDetail />} />
                <Route path="/admin/personel-veritabanı/:isletmeId" element={<PersonelDatabase />} />
                <Route path="/admin/magaza-veritabanı/:isletmeId" element={<BusinessDatabase />} />
                <Route path="/admin/personel-ekle/:isletmeId" element={<AddPersonel />} />
                <Route path="/admin/personeller/:personelDetay" element={<PersonelDetails />} />

                <Route path="/admin/toplam-ciro/:isletmeId" element={<TotalMoney />} />

                <Route path="/admin/agenta-ekle/:isletmeId" element={<AddAgenta />} />
                <Route path="/admin/agentalar/:agentDetay" element={<AgentaDetails />} />
                <Route path="/admin/teknik-kontrol-anketi/:isletmeId" element={<TechnicalControlSurveyAdmin />} />

                <Route path="/admin/teknik-kontrol-anketi-gereksinim/:isletmeId" element={<TechnicalControlSurveyAdminReq />} />

                <Route path="/admin/kampanya-ekle" element={<AddOffer />} />
                <Route path="/admin/kampanya-duzenle/:offerId" element={<EditOffer />} />
                <Route path="/admin/ucretsiz-kampanya-kodu-olustur" element={<CreateFreeCampaign />} />
              </Route>

              {/* PERSONAL */}
              {/* PERSONAL LEADER */}
              {/* TECHNICIAN */}
              <Route element={<PrivateRoute allowedRoles={["admin", "personel", "technical_support", "personel_lead"]} />}>
                <Route path="/personel" element={<PersonalHome />} />

                <Route path="/personel/teknik-kontrol-anketi" element={<TechnicalControlSurvey />} />

                <Route path="/personel/teknik-kontrol-anketi/:requirementsId" element={<TechnicalControlSurveyDetail />} />
              </Route>

              <Route element={<PrivateRoute allowedRoles={["admin", "personel", "personel_lead"]} />}>
                <Route path="/personel" element={<PersonalHome />} />

                {/* <Route
                  path="/personel/teknik-kontrol-anketi"
                  element={<TechnicalControlSurvey />}
                /> */}
                <Route path="/personel/randevu-olustur" element={<PersonalCreateMeeting />} />
                <Route path="/personel/pnr-sorgula" element={<PersonelSearchPnr />} />
                <Route path="/personel/check-in" element={<PersonalCheckin />} />
                <Route path="/personel/kasa-kapat" element={<PersonalLeader />} />
                <Route path="/personel/faaliyet-gecmisi" element={<PersonalMoneyHistory />} />
                <Route path="/personel/aylik-ciro" element={<PersonalMonthlyMoney />} />
                <Route path="/personel/kamera-satislari" element={<PersonalCameraSellings />} />

                {/* <Route path="/personel/bilet" element={<PersonalLeader />} /> */}
              </Route>

              <Route path="/personel/kasa-kapat" element={<PersonalLeader />} />

              {/* profile */}
              <Route path="/profil" element={<UserProfile />} />
            </Route>

            {/* PUBLIC */}
            {/* AUTH (PUBLIC) */}
            {/* Login */}
            <Route path="/giris" element={<Login />} />
            {/* Register */}
            <Route path="/kayit-ol" element={<Register />} />
            <Route path="/kayit-ol/mail-dogrula" element={<MailOtp />} />
            <Route path="/kayit-ol/mail-dogrula/telefon-dogrula" element={<TelOtp />} />
            <Route path="/kayit-ol/mail-dogrula/telefon-dogrula/hesap-olusturuldu" element={<AccountCreated />} />
            {/* Forgot Password */}
            <Route path="/sifremi-unuttum" element={<ForgotPassword />} />
            <Route path="/sifremi-unuttum/otp" element={<ForgotPasswordOtp />} />
            <Route path="/sifremi-unuttum/otp/sifremi-yenile" element={<NewPassword />} />
            <Route path="/sifremi-unuttum/otp/sifremi-yenile/sifre-olusturuldu" element={<PasswordCreated />} />

            {/* PUBLIC ROUTES */}
            <Route path="*" element={<NotFound />} />
            <Route path="/yetki-yok" element={<Unauthorized />} />
            <Route path="/kvkk-sozlesmesi" element={<Kvkk />} />

            <Route path="/success" element={<Success />} />
            <Route path="/failure" element={<Failure />} />

            <Route path="/bilet" element={<Ticket />} />
          </Route>
        </Routes>
      </motion.div>
    </AnimatePresence>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <StoreProvider>
        <BusinessProvider>
          <PhotoProvider>
            <BrowserRouter>
              <OkkoRoutes />
            </BrowserRouter>
            <Toaster />
          </PhotoProvider>
        </BusinessProvider>
      </StoreProvider>
    </AuthProvider>
  );
};

export default App;
