import { useState, useEffect } from "react";
import {
  BgBox,
  AuthBox,
  Header,
  Logo,
  Container,
  Title,
  OtpInput,
  RegisterLink,
  ForgotLink,
} from "../../../../components";
import styles from "./style.module.css";
import redsplash from "../../../../assets/splash/redsplash.svg";
import cyan2splash from "../../../../assets/splash/cyansplash2.svg";
import okkologo from "../../../../assets/okkologo.svg";
import userlogo from "../../../../assets/userlogo.svg";
import menu from "../../../../assets/menu.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { getServerErrorMessage } from "../../../../helpers/auth";
import { axiosClient } from "../../../../api/config";

const TelOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const email = searchParams.get("email") || "";
  const phone = searchParams.get("phone") || "";

  useEffect(() => {
    sendPhoneVerification();
  }, [email]);

  const sendPhoneVerification = async () => {
    try {
      const response = await axiosClient.post(
        "/users/send_sms_verification",
        JSON.stringify({ phone })
      );

      successMessage("Telefonunuza onay kodu gönderildi");
      return;
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  const controlOtpTel = async (otp) => {
    try {
      const response = await axiosClient.post(
        "/users/sms_verification",
        JSON.stringify({ phone, otp })
      );

      navigate({
        pathname: "/kayit-ol/mail-dogrula/telefon-dogrula/hesap-olusturuldu",
        search: `?email=${email}&phone=${phone}`,
      });
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  const getTelCodeAgain = () => {};

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        homeLink={"/"}
        profileLink="/profil"
      />
      <Container className={styles.container}>
        <BgBox
          className={styles.bgBox}
          img1={cyan2splash}
          img2={redsplash}
          img1Style="bottom-[13%] left-[52%]"
          img2Style="bottom-[48%] right-[57%] "
          windowsize={768}
        >
          <AuthBox className={styles.authBox}>
            <Logo className={styles.logo} />
            <Title className={styles.title}>
              Telefon numaranızı doğrulayın.
            </Title>
            <OtpInput
              type="tel"
              linkText="Farklı telefon numarası ile kayıt ol."
              getCodeAgain={() => {
                getTelCodeAgain();
              }}
              controlOtp={(otp) => {
                controlOtpTel(otp);
              }}
            />
          </AuthBox>
          <RegisterLink link="Giriş Yap" to="/giris">
            Zaten hesabım var?
          </RegisterLink>

          <ForgotLink to="/sifremi-unuttum">Şifremi Unuttum!</ForgotLink>
        </BgBox>
      </Container>
    </div>
  );
};

export default TelOtp;
