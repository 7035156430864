import styles from "./style.module.css";

const TelephoneInput = ({
  name,
  phonePrefix,
  changePhonePrefix,
  className,
  register,
  ...props
}) => {
  return (
    <div className={[styles.telephoneinputs, className].join(" ")} {...props}>
      <input
        className={styles.telephoneinput}
        placeholder="90"
        maxLength={4}
        value={phonePrefix}
        onChange={(e) => {
          changePhonePrefix(e.target.value);
        }}
      />
      <input
        className={styles.telephoneinput1}
        placeholder="- - -  - - -  - -  - -"
        name={name}
        maxLength={10}
        {...register(name)}
      />
    </div>
  );
};

export default TelephoneInput;
