import Container from "../Container";
import styles from "./style.module.css";
import okkologo from "../../assets/okkologo.svg";
import ıaapa from "../../assets/IAAPAlogo.png";
import Description from "../Text/Description";
import cyansplash from "../../assets/splash/cyansplash3.svg";

import { ReactComponent as Instagram } from "../../assets/icons/instagram.svg";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { ReactComponent as Mail } from "../../assets/icons/mail.svg";
import { ReactComponent as Phone } from "../../assets/icons/phone.svg";
import { ReactComponent as Location } from "../../assets/icons/map-pin.svg";
import { ReactComponent as Archive } from "../../assets/icons/archive.svg";

import { Link } from "react-router-dom";

const Footer = ({ showBg }) => {
  return (
    <div className={styles.footer}>
      {showBg && <img className="absolute -z-20 bottom-[30px] max-2xl:bottom-[75px] left-0 max-xl:hidden" src={cyansplash}></img>}

      <Container className={styles.container}>
        <div className="flex flex-col  items-center lg:flex-row max-2xl:px-10 max-2xl:gap-16">
          <div className=" flex flex-col items-center justify-center w-full gap-4">
            <img src={okkologo} alt="" />
            <Description className={styles.description}>
              OKKO, her geçen gün daha geniş kitlelerin ilgi duyduğu eğlence sektörünün; eğlence, proje ve deneyim ihtiyacını
              karşılamak üzere kurulmuştur. Bu nedenle sektörümüze iş birliği yaptığımız tüm kurum ve kuruluşlara büyük değer
              veriyoruz.
              <div className="flex gap-4 text-white">
                <a href="https://www.instagram.com/okko.group/" target="_blank">
                  <Instagram />
                </a>
                <a href="https://www.facebook.com/OkkoGRP/" target="_blank">
                  <Facebook />
                </a>
              </div>
              <div className="justify-start flex w-full">
                © {new Date().getFullYear()} OKKO EĞLENCE HİZMETLERİ LTD.ŞTİ, Tüm Hakları Saklıdır.
              </div>
            </Description>
          </div>
          <div className=" justify-center items-start flex w-full h-full">
            <img src={ıaapa} className="h-fit place-self-center flex"></img>
          </div>
          <div className=" flex flex-col w-full gap-4">
            <div>
              <div className="flex gap-4">
                <Location />
                <Description className={styles.description3}>
                  İçerenköy Mah. Kayışdağı Cad. Engin Sok. No:4/7 Ataşehir / İstanbul Kozyatağı V.D. 638 031 5002
                </Description>
              </div>
            </div>
            <div>
              <div className="flex gap-4 items-center">
                <Phone />
                <Description className={styles.description1}>+90 216 266 23 78</Description>
              </div>
            </div>
            <div>
              <div className="flex gap-4 items-center">
                <Mail />
                <Description className={styles.description1}>info@okko.com .tr</Description>
              </div>
            </div>
            <div>
              <div className="flex gap-4 items-center">
                <Archive color="#fff" width="20" height="20" />
                <a
                  className={[styles.description1, "text-lg border-b"].join(" ")}
                  href="/OKKO-Mesafeli-Satis-Sozlesmesi.pdf"
                  target="_blank"
                >
                  Mesafeli Satış Sözleşmesi
                </a>
              </div>
            </div>
            <div>
              <div className="flex gap-4 items-center">
                <Archive color="#fff" width="20" height="20" />
                <a
                  className={[styles.description1, "text-lg border-b"].join(" ")}
                  href="/OKKO-Gizlilik-Sozlesmesi-ve-kisisel-verilerin-korunmasi.pdf"
                  target="_blank"
                >
                  Gizlilik Sözleşmesi ve Kişisel Verilerin Korunması
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
