import { useState, useEffect } from "react";
import styles from "./style.module.css";
import okkologo from "../../../assets/okkologo.svg";
import userlogo from "../../../assets/userlogo.svg";
import menu from "../../../assets/menu.svg";
import fakeImage1 from "../../../assets/fakeImages/photo1.png";
import logo1 from "../../../assets/okkologo.svg";
import photo1 from "../../../assets/fakeImages/photo4.png";
import {
  Header,
  Title,
  Description,
  BuyTicketUpperBox,
  TechnicalControlsBox,
  Container,
  TechnicianDatabaseTable,
  BusinessRequirementsFormAdmin,
} from "../../../components";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { useParams } from "react-router-dom";
import { errorMessage } from "../../../helpers/toast";

const TechnicalControlSurveyAdmin = () => {
  const axiosClient = useAxiosClient();
  const { isletmeId } = useParams();

  const [thisBusinessData, setThisBusinessData] = useState({});

  useEffect(() => {
    getBusinesses();
  }, []);

  const getBusinesses = async () => {
    try {
      const response = await axiosClient.get(`/superuser/get-branch/branch-id=${isletmeId}`);
      if (response.status === 200) {
        setThisBusinessData(response?.data?.data?.branch);
      }
    } catch (error) {
      errorMessage("İşletmeler getirilirken bir hata oluştu.");
    }
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/personel"} />
      <Container className={styles.container}>
        {/* <Title className={styles.upperTitle}>Macera, Adrenalin, Mutluluk...</Title>
        <Description className={styles.description}>Eğlencenin merkezine hoş geldin!</Description> */}
        <Title className={[styles.upperTitle, "mb-20"].join(" ")}>{thisBusinessData?.name}</Title>

        <Description className={styles.description2}>İşletme Gereksinimleri Formu</Description>
        <BusinessRequirementsFormAdmin />
      </Container>
    </div>
  );
};

export default TechnicalControlSurveyAdmin;
