import { createContext } from "react";

const StoreContext = createContext(null);
import { useState } from "react";

export const StoreProvider = ({ children }) => {
  //AddPersonelStates
  const [addPersonelToBusinessClick, setAddPersonelToBusinessClick] =
    useState(false);
  const [personelPermissions, setPersonelPermissions] = useState({});

  return (
    <StoreContext.Provider
      value={{
        addPersonelToBusinessClick,
        setAddPersonelToBusinessClick,
        personelPermissions,
        setPersonelPermissions,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContext;
