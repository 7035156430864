import { errorMessage } from "./toast";

export const getServerErrorMessage = (err) => {
  if (!err?.response) {
    // return errorMessage("Sunucu ile bağlantı kurulamadı!");
  } else if (err.response?.status === 400) {
    return errorMessage(err.response.data.errorMsg);
  } else if (err.response?.status === 401) {
    return errorMessage("Yetkiniz yok!");
  } else if (err.response?.status === 403) {
    return errorMessage("Yetkiniz yok!");
  } else if (err.response?.status === 404) {
    //return errorMessage("İstek bulunamadı!");
  } else if (err.response?.status === 500) {
    return errorMessage("Sunucu hatası!");
  } else {
    return errorMessage("Başarısız İstek!");
  }
};
