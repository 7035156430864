import { AddBusinessesInput, AuthButton, Description } from "../../index";
import styles from "./style.module.css";
import CheckBox from "../../Checkbox";

const AddAgentaBox = ({
  addOffer,
  deleteOffer,
  setShortOfferDesc,
  shortOfferDesc,
  offerDesc,
  setOfferDesc,
  offerName,
  setOfferName,
  isActiveOffer,
  setIsActiveOffer,
  type = "",
}) => {
  return (
    <div className="flex flex-col w-full">
      <AddBusinessesInput
        className="w-full h-[45px] text-[16px] mt-5"
        value={offerName}
        placeholder="Kampanya Adı Giriniz"
        onChange={(e) => setOfferName(e.target.value)}
      />
      <AddBusinessesInput
        className="w-full h-[45px] text-[16px] mt-5"
        value={shortOfferDesc}
        placeholder="Kampanya Kısa Açıklaması Giriniz"
        onChange={(e) => setShortOfferDesc(e.target.value)}
      />
      <textarea
        className={styles.textAreaStyle}
        value={offerDesc}
        placeholder="Kampanya Detaylı Açıklaması Giriniz"
        onChange={(e) => setOfferDesc(e.target.value)}
      />
      <div className="w-full flex flex-row  h-[45px] text-[16px] mt-5 gap-5 max-xl:flex-wrap">
        <CheckBox
          checked={isActiveOffer}
          onChangeValue={setIsActiveOffer}
          checkedValue={isActiveOffer}
          checkBoxBorderColor="border-[#707376] checked:bg-emerald-500 checked:border-transparent"
        >
          <Description className="text-[#707376] text-lg flex xl:whitespace-nowrap w-full ml-1">
            Kampanya Aktif Mi ?
          </Description>
        </CheckBox>
      </div>

      <div className="flex flex-row gap-3 max-xl:flex-wrap">
        <AuthButton
          className=" flex px-[45px] py-3 max-sm:mt-32 mt-7 font-[400] rounded-[12px] text-[24px] whitespace-nowrap"
          clickButton={() => addOffer()}
        >
          {type === "edit" ? "Kampanya Düzenle" : "Kampanya Ekle"}
        </AuthButton>

        {deleteOffer && (
          <AuthButton
            className=" flex px-[45px] py-3 max-sm:mt-32 mt-7 font-[400] rounded-[12px] text-[24px] whitespace-nowrap"
            clickButton={() => deleteOffer()}
          >
            Kampanyayı Sil
          </AuthButton>
        )}
      </div>
    </div>
  );
};

export default AddAgentaBox;
