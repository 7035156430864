import { useState, useEffect, useRef } from "react";
import styles from "./style.module.css";
import AuthInput from "../../Input/AuthInput";
import Description from "../../Text/Description";
import AuthButton from "../../Button/AuthButton";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { getServerErrorMessage } from "../../../helpers/auth";
import { API_URL } from "../../../utils/url";
import MiniLoading from "../../Loading/MiniLoading";
import { successMessage } from "../../../helpers/toast";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { PhotoView } from "react-photo-view";
import ExportPdf from "../../ExportPdf";

const BusinessRequirementsFormPersonel = ({ children, answers, className, date, time, ...props }) => {
  // const inputRef = useRef(null);
  const authAxiosClient = useAxiosClient();
  const { auth } = useAuth();

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);
  const [articles, setArticles] = useState([]);
  const [requirements, setRequirements] = useState([]);

  // image
  const [isCheckedArray, setIsCheckedArray] = useState(Array(articles.length).fill(false));
  const [selectedImages, setSelectedImages] = useState(Array(articles.length).fill(null));

  const [loadingAdd, setLoadingAdd] = useState(false);

  const navigation = useNavigate();

  useEffect(() => {
    const handleClick = (event) => {
      if (event.target.classList.contains("not")) {
        const clickedQuestionIndex = parseInt(event.target.getAttribute("data-index"));

        setActiveQuestionIndex(activeQuestionIndex === clickedQuestionIndex ? null : clickedQuestionIndex);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [activeQuestionIndex]);

  useEffect(() => {
    getArticles();
    getRequirements();
  }, []);

  useEffect(() => {
    if (requirements.length > 0) {
      let localReq = requirements.map((requirement) => {
        return {
          rule: requirement?.rule,
          image: requirement?.image,
          note: requirement?.note,
          status: requirement?.status,
          priority: requirement?.priority,
        };
      });

      setArticles(localReq);
    }
  }, [requirements]);

  const getArticles = async () => {
    try {
      const response = await authAxiosClient.get(`/personel/get_articles`);

      let localArticles = response?.data?.data;

      localArticles = localArticles.map((article) => {
        return {
          rule: article?.rule,
          image: null,
          note: "",
          status: false,
          priority: article?.priority,
        };
      });

      setArticles(localArticles);
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
    }
  };

  const getRequirements = async () => {
    try {
      const response = await authAxiosClient.get(`/personel/get_requirements_for_branch`);
      setRequirements(response?.data?.data);
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
    }
  };

  const register = () => {};

  const checkControl = (articleObj) => {
    // if (isCheckedArray[id] === true) {
    //   return "bg-black";
    // } else {
    //   return "bg-white";
    // }

    if (articleObj.status === true) {
      return "bg-black";
    }
  };

  const checkBorderControl = (id) => {
    // if (isCheckedArray[id] === true) {
    //   return "border-gray-200";
    // } else {
    //   return "border-[#F80A3A]";
    // }
  };

  const completeRequirements = async (articleObj) => {
    try {
      setLoadingAdd(true);

      let formData = new FormData();
      formData.append("rule", articleObj?.rule);
      formData.append("image", articleObj?.image);
      formData.append("note", articleObj?.note);
      formData.append("status", articleObj?.status);

      const response = await authAxiosClient.post("/personel/add_requirements", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // successMessage("İşletme gereksinimleri başarıyla tamamlandı.");
      // navigation(-1);
    } catch (err) {
      getServerErrorMessage(err);
    } finally {
      setLoadingAdd(false);
    }
  };

  const addArticle = () => {
    const newArticles = [...articles];
    newArticles.push({
      rule: "",
      image: null,
      note: "",
      status: false,
      priority: 1,
    });
    setArticles(newArticles);
  };

  return (
    <div className={[styles.sssbox, className].join(" ")}>
      <ExportPdf
        className="ml-auto"
        fileName="teknik-kontrol-anketi.pdf"
        fileTitle="Teknik Kontrol Anketi"
        data={requirements.map((item) => [
          item.rule,
          item.status === true ? "Uygun" : "Uygun Değil",
          item.note == "null" ? "-" : item.note,
        ])}
        headData={[["Kural", "Durum", "Not"]]}
      />

      {loadingAdd && <MiniLoading className="mb-4" />}

      <div className="flex h-full flex-col gap-4">
        {articles?.map((article, index) => (
          <div key={index} className={`border rounded-xl fill-transparent-[5%]  text-[#959595] ${checkBorderControl(index)} `}>
            <div
              className="question h-full flex sm:flex-row flex-col border border-gray-400  rounded-xl  text-[24px] drop-shadow-md bg-white   py-3 px-3 cursor-pointer md:px-[72px]"
              data-index={index}
            >
              <AuthInput
                placeholder="İşletme kuralları, işletme gereksinimi,"
                className={"w-full flex max-w-full drop-shadow-xl mr-2 "}
                register={register}
                value={article?.rule}
                readOnly={index >= requirements.length ? false : true}
                onChange={(e) => {
                  const newArticles = [...articles];
                  newArticles[index].rule = e.target.value;
                  setArticles(newArticles);
                }}
              />

              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id={`file${index}`}
                onChange={(event) => {
                  const file = event.target.files[0];
                  const newSelectedImage = [...selectedImages];
                  newSelectedImage[index] = file;
                  setSelectedImages(newSelectedImage);

                  setArticles((prev) => {
                    const newArticles = [...prev];
                    newArticles[index].image = file;
                    return newArticles;
                  });
                }}
                //ref={inputRef}
              />
              <button
                className=" bg-white drop-shadow-xl rounded-xl m-2 px-4"
                onClick={() => {
                  //inputRef.current.click();
                  document.getElementById(`file${index}`).click();
                }}
              >
                Görsel
              </button>
              <div>
                {selectedImages[index] ? (
                  <PhotoView src={URL.createObjectURL(selectedImages[index])}>
                    <img className="w-[35px] h-[35px] m-2 mr-10" src={URL.createObjectURL(selectedImages[index])} alt="görsel" />
                  </PhotoView>
                ) : (
                  <PhotoView src={`${API_URL}${article?.image}`}>
                    <img className="w-[35px] h-[35px] m-2 mr-10" src={`${API_URL}${article?.image}`} alt="görsel" />
                  </PhotoView>
                )}
              </div>

              <button
                className="not bg-white drop-shadow-xl rounded-xl m-2 px-4"
                data-index={index} // Add data-index attribute to identify the clicked question
              >
                Not
              </button>

              <div className="h-auto mx-4 flex items-center align-middle">
                {auth?.role !== "personel_lead" && (
                  <button
                    onClick={() => {
                      const newIsCheckedArray = [...isCheckedArray];
                      newIsCheckedArray[index] = !newIsCheckedArray[index];
                      setIsCheckedArray(newIsCheckedArray);

                      const newArticles = [...articles];
                      newArticles[index].status = !newArticles[index].status;
                      setArticles(newArticles);

                      completeRequirements(article);
                    }}
                    className="circle justify-center items-center align-middle bg-white border-2 border-black rounded-full w-[35px] h-[35px]"
                  >
                    <div className="w-full h-full justify-center items-center flex">
                      <div className={`rounded-full flex  border-none w-[22px] h-[22px]  ${checkControl(article)}`}></div>
                    </div>
                  </button>
                )}
              </div>
              <div className="flex items-center gap-2 ml-4">
                <button
                  className={`py-1 px-2 w-20 text-sm rounded-full ${
                    articles[index].priority == 1 ? "bg-red-500 text-white" : "bg-gray-200"
                  }`}
                >
                  Kırmızı Öncelik
                </button>

                <button
                  className={`py-1 px-2 w-20 text-sm rounded-full ${
                    articles[index].priority == 2 ? "bg-yellow-500 text-white" : "bg-gray-200"
                  }`}
                >
                  Sarı Öncelik
                </button>
              </div>
            </div>
            {activeQuestionIndex === index && (
              <div className="flex sm:flex-row flex-col bg-white rounded-b-xl answer py-3 px-3 xss:px-[72px] text-[16px] ">
                <AuthInput
                  placeholder="Not Ekleyin."
                  className={"w-full flex max-w-full drop-shadow-xl mx-2 "}
                  register={register}
                  value={article?.note == "null" ? "" : article?.note}
                  onChange={(e) => {
                    const newArticles = [...articles];
                    newArticles[index].note = e.target.value;
                    setArticles(newArticles);
                  }}
                />
                {/* <button
                className="bg-white border-[#F80A3A] font-medium border text-xl text-[#F80A3A] drop-shadow-xl rounded-xl m-2 sm:px-8"
                onClick={register}
              >
                Onayla
              </button> */}
              </div>
            )}
          </div>
        ))}
      </div>

      {auth?.role !== "technical_support" && auth?.role !== "personel_lead" && (
        <button
          className="bg-primary font-medium border text-xl text-white rounded-full px-8 py-4 ml-auto"
          onClick={() => {
            addArticle();
          }}
        >
          Madde Ekle
        </button>
      )}

      {/* {loadingAdd ? (
        <MiniLoading />
      ) : (
        <button
          className="bg-primary font-medium border text-xl text-white rounded-full px-8 py-4"
          onClick={() => {
            completeRequirements();
          }}
        >
          Tamamla
        </button>
      )} */}
    </div>
  );
};

export default BusinessRequirementsFormPersonel;
