import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const PrivateRoute = ({ allowedRoles = [] }) => {
  const { auth } = useAuth();
  const location = useLocation();

  //return <Outlet />;

  return allowedRoles?.includes(auth?.role) ? (
    <Outlet />
  ) : auth?.token ? ( //changed from user to accessToken to persist login after refresh
    <Navigate to="/yetki-yok" state={{ from: location }} replace />
  ) : (
    <Navigate to="/giris" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
