import { useState, useEffect } from "react";
import {
  Header,
  Container,
  Description,
  Title,
  Footer,
  AuthBox,
  AddBusinessesInput,
  AuthButton,
  BusinessDatabaseTable,
  BusinessPnrBox,
  TicketBox,
} from "../../../components/index";
import styles from "./style.module.css";
import okkologo from "../../../assets/okkoadminlogo.svg";
import userlogo from "../../../assets/userlogowhite.svg";
import menu from "../../../assets/menu.svg";
import logo1 from "../../../assets/okkologo.svg";
import photo1 from "../../../assets/fakeImages/photo4.png";
import { useParams } from "react-router-dom";
import useAxiosClient from "../../../hooks/useAxiosClient";
import OkkoModal from "../../../components/OkkoModal";
import { getServerErrorMessage } from "../../../helpers/auth";
import { getFormattedDate } from "../../../helpers/year";

const BusinessDatabase = () => {
  const [date, setDate] = useState(getFormattedDate(new Date()));
  const [businessData, setBusinessData] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [searching, setSearching] = useState("all");
  const [thisBusinessData, setThisBusinessData] = useState({});
  const [pnr, setPnr] = useState();
  const [phone, setPhone] = useState();
  const [ticket, setTicket] = useState(null);
  const [reasonBox, setReasonBox] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);

  const { isletmeId } = useParams();

  const axiosClient = useAxiosClient();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getBusinesses();
  }, []);

  useEffect(() => {
    if (searching == "" || searching == "all") {
      setSearching("all");
    }
    getBusinessData();
  }, [searching, date]);

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    const todayFormatted = `${day}-${month}-${year}`;
    setDate(todayFormatted);
  }, []);

  const getBusinessData = async () => {
    let parts = date.split("-");
    let newDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

    try {
      const response = await axiosClient.get(
        `/superuser/get-using-details/branch-id=${isletmeId}&date-info=${newDate}&page-num=${1}&page-size=${10}&searching=${searching}`
      );

      setBusinessData(response?.data?.data?.items);
      setTotalPageCount(response?.data?.data?.total_page_count);
    } catch (error) {
      //getServerErrorMessage(error);
    } finally {
    }
  };

  const getHandlePageChange = async (page) => {
    let parts = date.split("-");
    let newDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

    try {
      const response = await axiosClient.get(
        `/superuser/get-using-details/branch-id=${isletmeId}&date-info=${newDate}&page-num=${page}&page-size=${10}&searching=${searching}`
      );

      setBusinessData(response?.data?.data?.items);
      setTotalPageCount(response?.data?.data?.total_page_count);
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
    }
  };

  const getAppointmentData = async () => {
    let newPhone = phone.replace(/[\(\)\s]/g, "");
    newPhone = "90" + newPhone;

    try {
      const response = await axiosClient.post(
        `superuser/get-pnr`,
        JSON.stringify({
          pnr: pnr,
          phone: newPhone,
        })
      );

      setTicket(response?.data?.data);
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
    }
  };

  const getBusinesses = async () => {
    try {
      const response = await axiosClient.get(`/superuser/get-branch/branch-id=${isletmeId}`);
      if (response.status === 200) {
        setThisBusinessData(response?.data?.data?.branch);
      }
    } catch (error) {
      errorMessage("İşletmeler getirilirken bir hata oluştu.");
    }
  };

  const cancelTicket = async () => {
    let newPhone = phone.replace(/[\(\)\s]/g, "");
    newPhone = "90" + newPhone;

    try {
      const response = await axiosClient.post(
        `/users/cancel-ticket`,
        JSON.stringify({
          phone: newPhone,
          pnr: pnr,
          reason: reasonText,
        })
      );
      successMessage("Biletiniz başarıyla iptal edildi.");

      setTimeout(() => {
        navigate(-1);
      }, 1200);
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  return (
    <div className="md:grid grid-rows-layoutAuth">
      <OkkoModal
        modalIsOpen={cancelModal}
        approve={cancelTicket}
        closeModal={() => setCancelModal(false)}
        approveButtonText="Onayla"
        declineButtonText="Vazgeç"
        title="İptal Etme Onayı"
        altText="İptal etmek istediğinizden emin misiniz?"
      />
      <Header className="bg-primary" okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink={"/profil"} homeLink={"/admin"} />
      <Container className={styles.container}>
        {/* <Title className={styles.upperTitle}>Macera, Adrenalin, Mutluluk...</Title>
        <Description className={styles.description}>Eğlencenin merkezine hoş geldin!</Description> */}

        <Title className={[styles.upperTitle, "mb-20"].join(" ")}>{thisBusinessData?.name}</Title>

        {/* <BuyTicketUpperBox
          logo={logo1}
          photo={API_URL + thisBusinessData?.image}
          description={thisBusinessData?.description}
          imageText={thisBusinessData?.name}
          className="mb-20"
        /> */}

        <Title className={styles.midTitle}>Şube Detayı</Title>
        <BusinessPnrBox
          input2={pnr}
          setInput2={setPnr}
          input3={phone}
          setInput3={setPhone}
          button={getAppointmentData}
          className="mb-20"
        ></BusinessPnrBox>

        {ticket && (
          <div className="mt-10">
            <TicketBox logo={logo1} photo={photo1} ticket={ticket} />
            <button
              className="w-full"
              onClick={() => {
                setReasonBox(true);
              }}
            >
              <Title className={styles.rejectButton}>İptal Et</Title>
            </button>
          </div>
        )}

        {reasonBox && (
          // textarea
          <div className="flex flex-col items-center w-full md:w-1/2 mt-4">
            <textarea
              value={reasonText}
              onChange={(e) => setReasonText(e.target.value)}
              placeholder="İptal sebebinizi yazınız"
              className="w-full resize-none px-3 py-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline"
              rows="4"
              maxLength={240}
            />
            <AuthButton
              className="px-8 mt-3"
              onClick={() => {
                setCancelModal(true);
              }}
            >
              Bileti İptal Et
            </AuthButton>
          </div>
        )}

        <Title className={styles.midTitle}>Şube Kullanım Detayı</Title>

        <AuthBox className={styles.authBox}>
          <Title className={styles.midTitle}>Tarih Seçiniz</Title>
          <Description className={styles.desc}>Tarih:</Description>
          <AddBusinessesInput
            placeholder="Tarih Seçiniz  --/--/----"
            className="w-full h-[45px] text-[16px]"
            value={date}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.length === 2 || inputValue.length === 5) {
                if (inputValue.length > date.length) {
                  setDate(inputValue + "-");
                } else {
                  setDate(inputValue.slice(0, -1));
                }
              } else {
                setDate(inputValue);
              }
            }}
          />
          <AuthButton className={styles.button} clickButton={() => getBusinessData()}>
            Ara
          </AuthButton>
        </AuthBox>

        <BusinessDatabaseTable
          totalPageCount={totalPageCount}
          getHandlePageClick={getHandlePageChange}
          searching={searching}
          setSearching={setSearching}
          className={styles.table}
          businesses={businessData}
        />
      </Container>

      <Footer />
    </div>
  );
};

export default BusinessDatabase;
