import { useState, useEffect } from "react";
import { Header, Container, Title, Footer, AuthBox, AddOfferBox } from "../../../components/index";
import styles from "./style.module.css";
import okkologo from "../../../assets/okkoadminlogo.svg";
import userlogo from "../../../assets/userlogowhite.svg";
import menu from "../../../assets/menu.svg";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { errorMessage, successMessage } from "../../../helpers/toast";
import { API_URL } from "../../../utils/url";
import { ReactComponent as Plus } from "../../../assets/plus.svg";

const EditOffer = () => {
  const { offerId } = useParams();

  const navigate = useNavigate();

  const axiosClient = useAxiosClient();

  const [photo, setPhoto] = useState();
  const [shortOfferDesc, setShortOfferDesc] = useState();
  const [offerDesc, setOfferDesc] = useState();
  const [offerName, setOfferName] = useState();
  const [isActiveOffer, setIsActiveOffer] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getOffer();
  }, []);

  const getOffer = async () => {
    try {
      const response = await axiosClient.get(`/superuser/get-news/id=${offerId}`);
      if (response.status === 200) {
        setShortOfferDesc(response?.data?.data?.short_description);
        setOfferDesc(response?.data?.data?.description);
        setOfferName(response?.data?.data?.title);

        setIsActiveOffer(response?.data?.data?.is_active);
        setPhoto(response?.data?.data?.image);
      }
    } catch (error) {
      errorMessage("Kampanya bilgileri getirilirken bir hata oluştu.");
    }
  };

  const editOffer = async () => {
    try {
      const response = await axiosClient.post(`/superuser/edit-news/id=${offerId}`, {
        title: offerName,
        description: offerDesc,
        short_description: shortOfferDesc,
        is_active: isActiveOffer,
        image: photo,
      });
      if (response.status === 200) {
        successMessage("Kampanya başarıyla düzenlendi.");
        navigate(-1);
      }
    } catch (error) {
      errorMessage("Kampanya düzenlenirken bir hata oluştu.");
    }
  };

  const deleteOffer = async () => {
    try {
      const response = await axiosClient.get("/superuser/delete-news/id=" + offerId);
      if (response.status === 200) {
        successMessage("Kampanya Başarıyla Silindi.");
        navigate("/admin");
      }
    } catch (error) {
      errorMessage("Kampanya silinirken bir hata oluştu.");
    }
  };

  return (
    <div className="md:grid grid-rows-layoutAuth">
      <Header className="bg-primary" okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink={"/profil"} homeLink={"/admin"} />
      <Container className={styles.container}>
        <Title className={styles.midTitle}>Kampanya Ekleme</Title>

        <AuthBox className={styles.authBox1}>
          <Title className={styles.midTitle}>Kampanya Bilgisi</Title>
          <div className="flex gap-4 w-full max-md:flex-wrap max-md:justify-center">
            <div className={styles.businessesbox}>
              {photo && (
                <img
                  // src={  URL.createObjectURL(photo)}
                  src={typeof photo === "string" ? API_URL + photo : URL.createObjectURL(photo)}
                  className={styles.photo}
                  onClick={() => {
                    setPhoto();
                  }}
                ></img>
              )}

              {!photo && (
                <label className={styles.photo}>
                  <input
                    type="file"
                    name="photo"
                    id="upload-photo"
                    onChange={(event) => {
                      setPhoto(event.target.files[0]);
                    }}
                    className="hidden"
                    value={photo}
                  ></input>{" "}
                  <Plus className="w-3 h-3" />
                </label>
              )}
            </div>
            <AddOfferBox
              addOffer={editOffer}
              deleteOffer={deleteOffer}
              setShortOfferDesc={setShortOfferDesc}
              shortOfferDesc={shortOfferDesc}
              offerDesc={offerDesc}
              setOfferDesc={setOfferDesc}
              offerName={offerName}
              setOfferName={setOfferName}
              isActiveOffer={isActiveOffer}
              setIsActiveOffer={setIsActiveOffer}
              type="edit"
            />
          </div>
        </AuthBox>
      </Container>

      <Footer />
    </div>
  );
};

export default EditOffer;
