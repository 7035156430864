import { useState } from "react";
import styles from "./style.module.css";
import { ReactComponent as PasswordIcon } from "../../../assets/password-icon.svg";
import { ReactComponent as PasswordIconOpen } from "../../../assets/password-icon-open.svg";

const PasswordInput = ({ name, register, className, ...props }) => {
  const [passwordShown, setPasswordShown] = useState(false); //for password visibility

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div className={styles.passwordinputarea}>
      <input
        {...props}
        name={name}
        type={passwordShown ? "text" : "password"}
        className={[styles.passwordinput, className].join(" ")}
        {...register(name)}
      />
      <button onClick={togglePassword} className={styles.eyeButton}>
        {passwordShown ? (
          <PasswordIconOpen className={styles.openEyeButton} />
        ) : (
          <PasswordIcon />
        )}
      </button>
    </div>
  );
};

export default PasswordInput;
