import { useNavigate } from "react-router-dom";
import useAuth from "./useAuth";
import useAxiosClient from "./useAxiosClient";

const useLogout = () => {
  const { setAuth } = useAuth();
  const authAxiosClient = useAxiosClient();
  const navigate = useNavigate();

  const logout = async () => {
    setAuth({});
    try {
      const response = await authAxiosClient.get("/users/logout", {
        withCredentials: true,
      });
      if (!response.error) {
        navigate("/giris");
      }
    } catch (err) {}
  };

  return logout;
};

export default useLogout;
