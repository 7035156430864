import { useState, useEffect } from "react";
import { BgBox, Header, Container, Title, Description, BusinessesBoxContainer, Footer } from "../../../../components";
import styles from "./style.module.css";
import orangesplash from "../../../../assets/splash/orangesplash3.svg";
import purplesplash from "../../../../assets/splash/purplesplash3.svg";
import okkologo from "../../../../assets/okkologo.svg";
import userlogo from "../../../../assets/userlogo.svg";
import menu from "../../../../assets/menuwhite.svg";
import { getServerErrorMessage } from "../../../../helpers/auth";
import { axiosClient } from "../../../../api/config";
import MiniLoading from "../../../../components/Loading/MiniLoading";

const UserAllBusinesses = () => {
  const [branchsLoading, setBranchsLoading] = useState(false);
  const [branchs, setBranchs] = useState([]);

  useEffect(() => {
    getBranchs();

    return () => {
      setBranchs([]);
    };
  }, []);

  const getBranchs = async () => {
    setBranchsLoading(true);
    try {
      const response = await axiosClient.get("/users/get-all-branchs");
      setBranchs(response?.data?.data);
    } catch (err) {
      getServerErrorMessage(err);
    } finally {
      setBranchsLoading(false);
    }
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/"} />
      <Container className={styles.container}>
        <Title className={styles.upperTitle}>Macera, Adrenalin, Mutluluk...</Title>
        <Description className={styles.description}>Eğlencenin merkezine hoş geldin!</Description>

        <Title className={styles.title}>İşletmeler</Title>
        {branchsLoading && <MiniLoading className="mb-8" />}
        <BgBox
          className={styles.bgBox}
          img1={orangesplash}
          img2={purplesplash}
          img1Style="bottom-[-20px] right-[-270px]"
          img2Style="xmd:top-[-270px] top-[-200px] left-[-270px] "
          windowsize={640}
        >
          <BusinessesBoxContainer className="my-custom-class" type="user" businessBoxes={branchs} />
        </BgBox>
      </Container>
      <Footer showBg={true} />
    </div>
  );
};

export default UserAllBusinesses;
