import AuthBox from "../../Box/AuthBox";
import styles from "./style.module.css";
import { useState, useRef, useEffect } from "react";
import cn from "classnames";
import ReactPaginate from "react-paginate";
import { ReactComponent as RedArrow } from "../../../assets/redarrowright.svg";
import QRCode from "react-qr-code";
import ExcelExport from "../../ExcelExport";

const MoneyHistoryTable = ({ className, moneys, getHandlePageClick, totalPageCount, setSearching, searching, allMoneyData, ...props }) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");

  const [paginate, setPaginate] = useState(0);
  const [pageData, setPageData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const [moneyData, setMoneyData] = useState([]);

  useEffect(() => {
    const moneys = allMoneyData.map((item) => {
      return {
        "QR Code": item.qr_code,
        "İsim Soyisim": item.name,
        "Telefon Numarası": item.phone,
        Tarih: item.meeting_date,
        Saat: item.meeting_time,
        Ücret: item?.ticket_price + item?.camera_price + " TL",
        Bilet: item?.ticket_name,
        Kamera: item?.is_camera_used ? "Evet" : "Hayır",
      };
    });

    setMoneyData(moneys);
  }, [allMoneyData]);

  const handlePageClick = (data) => {
    let selected = data.selected;
    setPaginate(selected);
    setPageData(moneys.slice(selected * 10, selected * 10 + 10));
    getHandlePageClick(selected + 1);
  };

  const handleClickKeyDown = (e) => {
    if (e.key === "Enter") {
      setPageData(moneys.slice((Number(e.target.value) - 1) * 10, (Number(e.target.value) - 1) * 10 + 10));
      setPaginate(Number(e.target.value) - 1);
      getHandlePageClick(Number(e.target.value));
    }
  };

  const dynamicRowData = (date) => {
    // if date first char is number bg green else red

    if (date[0] >= "0" && date[0] <= "9") {
      return styles.datas;
    }
    return styles.datas2;
  };

  return (
    <div className={[styles.tableCont, className].join(" ")} {...props}>
      <ExcelExport excelData={moneyData} fileName="faaliyet-gecmisi" />

      <AuthBox className={styles.authBox}>
        <input
          className={styles.input}
          placeholder="İsim Soyisim, Kullanıcı adı, Telefon Numarası"
          value={searching == "all" ? "" : searching}
          onChange={(e) => setSearching(e.target.value)}
        />
      </AuthBox>
      <AuthBox className={styles.authBox2}>
        <div className={styles.tableInside}>
          <div className={styles.titles}>
            <div className={styles.title1}>Qr Code</div>
            <div className={styles.title2}>İsim Soyisim</div>
            <div className={styles.title3}>Telefon Numarası</div>
            <div className={styles.title4}>Tarih</div>
            <div className={styles.title5}>Saat</div>
            <div className={styles.title6}>Ücret</div>
            <div className={styles.title6}>Bilet</div>
            <div className={styles.title7}>Kamera</div>

            {/* <div className={styles.title5}>Saat</div> */}
          </div>
          <div className="flex flex-col w-full mt-5  gap-2">
            {moneys &&
              moneys.map((money, index) => (
                <div className={cn(styles.datas, dynamicRowData(money?.meeting_date))} key={index}>
                  <div className={styles.data1}>
                    <QRCode value={money?.qr_code} className="w-[60px] h-[60px] min-w-[60px] min-h-[60px] " />
                  </div>
                  <div className={styles.data2}>{money?.name}</div>
                  <div className={styles.data3}>{money?.phone}</div>
                  <div className={styles.data4}>{money?.meeting_date}</div>
                  <div className={styles.data5}>{money?.meeting_time}</div>
                  <div className={styles.data6}>{money?.ticket_price + money?.camera_price} TL</div>
                  <div className={styles.data7}>{money?.ticket_name}</div>
                  <div className={styles.data8}>{money?.is_camera_used ? "Evet" : "Hayır"}</div>
                  {/* <div className={styles.data5}>
                    {technician?.start_time?.substring(0, 5) +
                      "-" +
                      technician?.end_time?.substring(0, 5)}
                  </div> */}
                </div>
              ))}
          </div>
        </div>
      </AuthBox>
      <div className={styles.paginateContainer}>
        <ReactPaginate
          className={styles.paginate}
          breakLabel="..."
          nextLabel=">"
          previousLabel="<"
          nextLinkClassName="text-primary"
          previousLinkClassName="text-primary"
          nextClassName={styles.pageNextClassname}
          previousClassName={styles.pagePreviousClassname}
          onPageChange={(e) => handlePageClick(e)}
          pageRangeDisplayed={5}
          pageCount={totalPageCount}
          renderOnZeroPageCount={false}
          forcePage={paginate}
          pageClassName={styles.pageClassname}
          activeClassName={styles.activeClassname}
        />
        {
          <div className={styles.paginateGitContainer}>
            <label htmlFor="Git" className="text-primary">
              Git
            </label>
            <input
              autoComplete="off"
              ref={inputRef}
              maxLength="3"
              placeholder="1"
              className={styles.paginateGitInput}
              type="text"
              id="Git"
              onKeyDown={(e) => {
                handleClickKeyDown(e);
              }}
            />
            <button /*onClick={(e)=> handleClick(e)} */>
              <RedArrow width="1.5em" height="1.5em" className="absolute right-1 top-[5px] text-primary" />
            </button>
          </div>
        }
      </div>
    </div>
  );
};

export default MoneyHistoryTable;
