import styles from "./style.module.css";

const BorderBox = ({ className, children, ...props }) => {
  return (
    <div className={[styles.borderbox, className].join(" ")} {...props}>
      {children}
    </div>
  );
};

export default BorderBox;
