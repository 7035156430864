import { AddBusinessesInput, BorderBox, Title } from "../../index";
import { ReactComponent as Plus } from "../../../assets/plus.svg";
import { v4 as uuidv4 } from "uuid";

const AddBeyanBox = ({
  children,
  beyanArray,
  setBeyanArray,
  className,
  ...props
}) => {
  return (
    <div
      className="flex flex-col w-full bg-white rounded-lg gap-4 mb-20"
      {...props}
    >
      <Title className=" font-mainFont font-[400] text-[24px]  text-start items-center mt-20 text-gray-900 ml-6 mb-2">
        Beyanlar
      </Title>
      {beyanArray.map((item, index) => {
        if (item.is_delete) return null;
        return (
          <div
            className={`border rounded-xl fill-transparent-[5%] border-gray-400`}
            key={item.created_at}
          >
            <div className="question border border-b-gray-400 rounded-xl drop-shadow-md bg-[#FEF2F5] py-3 px-6">
              <AddBusinessesInput
                className="w-full rounded-full text-[20px] font-[400]"
                placeholder="Beyan Girin"
                value={beyanArray[index].beyan}
                onChange={(e) => {
                  let temp = [...beyanArray];
                  temp[index].beyan = e.target.value;
                  setBeyanArray(temp);
                }}
              ></AddBusinessesInput>
            </div>
          </div>
        );
      })}
      <button
        className="mt-2"
        onClick={() => {
          const tempBeyanArray = [...beyanArray];
          tempBeyanArray.push({
            beyan: "",
            id: null,
            is_delete: false,
            created_at: uuidv4(),
          });

          setBeyanArray(tempBeyanArray);
        }}
      >
        <BorderBox className="py-6 bg-[#F80A3A0D]">
          <Plus></Plus>
        </BorderBox>{" "}
      </button>
      {beyanArray.length > 1 && (
        <button
          onClick={() => {
            if (beyanArray.length == 1) return;

            const reversedBeyanArray = [...beyanArray].reverse();

            const lastItemIndex = reversedBeyanArray.findIndex(
              (item) => !item.is_delete
            );

            if (lastItemIndex === -1) {
              return;
            }

            const actualIndex = beyanArray.length - 1 - lastItemIndex;

            const tempBeyanArray = [...beyanArray];
            tempBeyanArray[actualIndex].is_delete = true;

            setBeyanArray(tempBeyanArray);
          }}
        >
          <BorderBox className="py-8 bg-[#F80A3A0D]">
            <div className="h-1 w-4 bg-[#959595] "></div>
          </BorderBox>{" "}
        </button>
      )}
    </div>
  );
};

export default AddBeyanBox;
