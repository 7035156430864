import axios from "axios";
import { API_URL } from "../utils/url";

const BASE_URL = `${API_URL}/api`;

export const axiosClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    //Accept: "application/json",
  },
  withCredentials: true,
  timeout: 10000,
});
