import {
  Header,
  Container,
  Description,
  Title,
  BuyTicketUpperBox,
  AuthBox,
  Footer,
  TicketButtonBox,
  SSSBox,
  BgBox,
  TechnicalControlsBox,
  Personels,
  Agentas,
} from "../../../../components/index";
import styles from "./style.module.css";
import fakeImage1 from "../../../../assets/fakeImages/photo1.png";
import okkologo from "../../../../assets/okkoadminlogo.svg";
import userlogo from "../../../../assets/userlogowhite.svg";
import menu from "../../../../assets/menu.svg";
import logo1 from "../../../../assets/okkologo.svg";
import photo1 from "../../../../assets/fakeImages/photo4.png";
import redsplash from "../../../../assets/splash/orangesplash2.svg";
import cyansplash from "../../../../assets/splash/cyansplash2.svg";
import { ReactComponent as Phone } from "../../../../assets/phone.svg";
import personel from "../../../../assets/personel.svg";
import agenta from "../../../../assets/agenta.svg";
import AddressMap from "../../../../components/AddressMap";
import { Link } from "react-router-dom";
import { useState } from "react";

const BusinessDetail = () => {
  const [superAdmin, setSuperAdmin] = useState(true); //Fake Data

  const [businesses, setBusinesses] = useState([
    {
      name: "Şile Feneri - Zippline",
      logo: "/src/assets/zipplinelogo.png",
      state: "active",
    },
  ]);

  const [business, setBusiness] = useState({
    name: "Şile Feneri - Zippline",
    logo: "/src/assets/zipplinelogo.png",
    state: "inactive",
  }); //Fake Data

  const personelsData = [
    { id: 1, image: personel, name: "Mehmet Tunç Kardeşboğan" },
    { id: 2, image: personel, name: "Gamze Gamzeli" },
    { id: 3, image: personel, name: "Miguel Bubalaba" },
  ];

  const agentasData = [
    { id: 1, image: agenta, name: "Temel Turizm" },
    { id: 2, image: agenta, name: "Samet Akyürek" },
    { id: 3, image: agenta, name: "Sinop Belediyesi" },
  ];

  {
    /* saatler okunaklı olsun takvimdeki rakamlar büyük olsun */
  }
  {
    /* silik şeyler daha bold olsun */
  }

  return (
    <div className="md:grid grid-rows-layoutAuth">
      <Header
        className="bg-primary"
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink={"/profil"}
        homeLink={"/admin"}
      />
      <Container className={styles.container}>
        <Title className={styles.upperTitle}>Macera, Adrenalin, Mutluluk...</Title>
        <Description className={styles.description}>Eğlencenin merkezine hoş geldin!</Description>

        <BuyTicketUpperBox logo={logo1} photo={photo1} description={fakeData[0].description} imageText={fakeData[0].title} />

        <Title className={styles.midTitle}>Bilet Detayları</Title>
        {fakeData2.map((item, index) => {
          return (
            <TicketButtonBox className={styles.ticketButtonBox}>
              <div className={"flex flex-row justify-between w-full max-sm:flex-wrap"}>
                <Description className={styles.ticketButtonBoxTitle}>{item.title}</Description>
                <Description className={styles.ticketButtonBoxPrice}>{item.price}</Description>
                <div className="max-sm:w-full justify-center items-center flex">
                  <Link className={styles.button} to="">
                    Seç
                  </Link>
                </div>
              </div>
            </TicketButtonBox>
          );
        })}

        <AuthBox className={styles.authBox5}>
          <Title className={styles.opportunityBoxTitleText}>{fakeData[0].title} Hakkında</Title>
          <Description className={styles.opportunityBoxDescriptionText}>{fakeData[0].description}</Description>
        </AuthBox>
        <AuthBox className={styles.titleBox}>
          <Title className={styles.bottomTitle}>Detaylar ve Kurallar</Title>
        </AuthBox>
        <AuthBox className={styles.authBox2}>
          <Title className={styles.timetitle}>Çalışma Saatleri:</Title>
          <Description className={styles.opportunityBoxDescriptionText2}>{fakeData[0].workTime}</Description>
        </AuthBox>
        <AuthBox className={styles.authBox2}>
          {fakeData[0].rules.map((item, index) => {
            return <Description className={styles.opportunityBoxDescriptionText2}>{item}</Description>;
          })}
        </AuthBox>
        <AuthBox className={styles.titleBox}>
          <Title className={styles.bottomTitle}>{fakeData[0].title} ve Macera Parkı Bileti SSS (Sıkça Sorulan Sorular)</Title>
        </AuthBox>
        <SSSBox questions={questions} answers={answers} className="mb-20" />
        <BgBox
          className={styles.bgBox}
          windowsize={1100}
          img1={cyansplash}
          img2={redsplash}
          img2Style="top-[40%] left-[75%]"
          img1Style="-bottom-[25%] right-[75%] rotate-[210deg]"
        >
          <div className={"flex flex-row flex-wrap  justify-center w-full"}>
            <AuthBox className={styles.authBox3}>
              <div className="flex flex-row w-full justify-start gap-4 ">
                <Phone className="" width={40} height={40} />
                <Title className="text-[#454545] font-[500] font-mainFont">İletişim </Title>
              </div>
              <Title className={"text-start w-full flex  text-2xl mb-2 font-[400]"}>Adres:</Title>
              <div className="w-full">{fakeData[0].address}</div>
              <Title className={"text-start w-full flex mt-8 text-2xl mb-2 font-[400]"}>Telefon Numarası:</Title>
              <div className="w-full">{fakeData[0].phone}</div>
            </AuthBox>
            <AuthBox className={styles.authBox4}>
              <AddressMap />
            </AuthBox>
          </div>
        </BgBox>

        <Title className={styles.opportunityBoxTitleText2}>Günlük Teknik Kontrol Durumu</Title>
        {!superAdmin && (
          <TechnicalControlsBox
            className={styles.technicalControlsBox}
            children={business.name}
            logo={business.logo}
            active={business.state}
          />
        )}
        {superAdmin && (
          <>
            <Description className={styles.desc}>Tamamlanmayan İşletmeler</Description>
            {businesses.filter((item) => item.state === "inactive").length > 0 ? (
              businesses.map(
                (item, index) =>
                  item.state === "inactive" && (
                    <TechnicalControlsBox
                      className={styles.technicalControlsBox}
                      children={item.name}
                      logo={item.logo}
                      active="inactive"
                      key={index}
                    />
                  )
              )
            ) : (
              <Description className={styles.desc2}>Tamamlanmayan teknik kontrol bulunmamaktadır...</Description>
            )}
            <Description className={styles.desc}>Tamamlanan İşletmeler</Description>
            {businesses.map(
              (item, index) =>
                item.state === "active" && (
                  <TechnicalControlsBox
                    className={styles.technicalControlsBoxActive}
                    children={item.name}
                    logo={item.logo}
                    active="active"
                    key={index}
                  />
                )
            )}
          </>
        )}

        <Personels type="admin" personelsData={personelsData} className="mt-20" />

        <div className={styles.desc2}>Şube Detayları</div>
        <div className="w-full flex flex-col items-center justify-center gap-5">
          <div className={styles.buttonbox}>
            <Description>Personel Veritabanı</Description>
            <Link className={styles.button} to="/admin/personel-veritabanı">
              Seç
            </Link>
          </div>
          <div className={styles.buttonbox}>
            <Description>İşletme Veritabanı</Description>
            <Link className={styles.button} to="/admin/magaza-veritabanı">
              Seç
            </Link>
          </div>
          <div className={styles.buttonbox}>
            <Description>İşletme Teknik Kontroller</Description>
            <Link className={styles.button} to="">
              Seç
            </Link>
          </div>
        </div>

        <Agentas type="admin" agentasData={agentasData} className="mt-20" />
        {/* <Weather /> */}
      </Container>

      <Footer />
    </div>
  );
};

export default BusinessDetail;
