import {
  BgBox,
  AuthBox,
  Header,
  AuthInput,
  AuthButton,
  Logo,
  Container,
  PasswordInput,
  Title,
  Description,
  AuthInputwithEditLink,
  Footer,
} from "../../../../components/";
import styles from "./style.module.css";
import bluesplash from "../../../../assets/splash/bluesplash2.svg";
import redsplash from "../../../../assets/splash/redsplash.svg";
import okkologo from "../../../../assets/okkologo.svg";
import userlogo from "../../../../assets/userlogo.svg";
import menu from "../../../../assets/menu.svg";
import { useState, useEffect } from "react";
import { getServerErrorMessage } from "../../../../helpers/auth";
import { axiosClient } from "../../../../api/config";
import { useTranslation } from "react-i18next";
import useAxiosClient from "../../../../hooks/useAxiosClient";
import { useNavigate } from "react-router-dom";
import { successMessage } from "../../../../helpers/toast";
import useLogout from "../../../../hooks/useLogout";

const UserProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authAxiosClient = useAxiosClient();

  const logout = useLogout();

  const [profile, setProfile] = useState({
    username: "",
    tc: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    birth_date: "",
    member_id: "",
  });

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const register = () => {};

  useEffect(() => {
    getProfile();

    return () => {
      setProfile(null);
    };
  }, []);

  const getProfile = async () => {
    try {
      const response = await authAxiosClient.get("/users/get-profile");

      setProfile(response?.data?.data);
    } catch (err) {
      //getServerErrorMessage(err);
      navigate("/giris");
    } finally {
    }
  };

  const changePassword = async () => {
    try {
      const response = await axiosClient.post(
        "/users/profile-change-password",
        {
          password: currentPassword,
          password_new: newPassword,
          password_new_again: newPasswordConfirm,
        }
      );
      successMessage("Şifreniz başarıyla değiştirildi.");
      setCurrentPassword("");
      setNewPassword("");
      setNewPasswordConfirm("");
    } catch (err) {
      getServerErrorMessage(err);
    } finally {
    }
  };

  // const logoutSystem = async () => {
  //   try {
  //     const response = await authAxiosClient.get("/users/logout");

  //     navigate("/giris");
  //   } catch (err) {
  //     getServerErrorMessage(err);
  //   }
  //   // navigate("/login")
  // };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink="/profil"
        homeLink={"/"}
      />
      <Container className={styles.container}>
        <BgBox
          className={styles.bgBox}
          img1={bluesplash}
          img2={redsplash}
          img1Style="bottom-[17%] right-[49%]"
          img2Style="top-[10%] left-[54%] rotate-[120deg]"
          windowsize={900}
        >
          <Title className={styles.upperTitle}>
            {t("adventure")} {t("adrenaline")} {t("happiness")}...
          </Title>
          <Description className={styles.upperDescription}>
            {t("welcomeMessage")}
          </Description>
          <AuthBox className={styles.authBox}>
            <Logo className={styles.logo} />
            <AuthInput
              className={styles.authInput}
              placeholder={t("username")}
              register={register}
              value={profile?.username}
              readOnly
            />
            <AuthInput
              className={styles.authInput}
              placeholder={t("firstname")}
              register={register}
              value={profile?.first_name}
              readOnly
            />

            <AuthInput
              className={styles.authInput}
              placeholder={t("lastname")}
              register={register}
              value={profile?.last_name}
              readOnly
            />

            <AuthInputwithEditLink
              placeholder={t("email")}
              link="/profil/mail-degistir"
              register={register}
              value={profile?.email}
              readOnly
            />

            <AuthInput
              className={styles.authInput}
              placeholder={t("phone")}
              register={register}
              value={profile?.phone}
              readOnly
            />
            {/* <AuthInputwithEditLink
              placeholder={t("phone")}
              link="/profil/telefon-degistir"
              register={register}
              value={profile?.phone}
              readOnly
            /> */}

            {/* <AuthInput
              className={styles.authInput}
              placeholder={t("gender")}
              register={register}
              value={profile?.gender === true ? t("male") : t("female")}
              readOnly
            /> */}
            <AuthInput
              className={styles.authInput}
              placeholder={t("birthDate")}
              register={register}
              value={profile?.birth_date}
              readOnly
            />

            <Title className={styles.title}>{t("changePasswordTitle")}</Title>
            <Description className={styles.description}>
              {t("changePasswordDescription")}
            </Description>

            <PasswordInput
              className={styles.authInput}
              placeholder={t("password")}
              register={register}
              value={currentPassword}
              onChange={(e) => {
                setCurrentPassword(e.target.value);
              }}
            />
            <Description className={styles.description}>
              {t("enterNewPassword")}
            </Description>
            <PasswordInput
              className={styles.authInput}
              placeholder={t("password")}
              register={register}
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
            <PasswordInput
              className={styles.authInput}
              placeholder={t("confirmPassword")}
              register={register}
              value={newPasswordConfirm}
              onChange={(e) => {
                setNewPasswordConfirm(e.target.value);
              }}
            />
            <AuthButton
              className={styles.authButton}
              onClick={() => {
                changePassword();
              }}
            >
              {t("changePasswordTitle")}
            </AuthButton>
          </AuthBox>
          <AuthButton
            className={[styles.authButton, "mx-auto w-full md:w-1/2"].join(" ")}
            onClick={async () => {
              await logout();
            }}
          >
            {t("exitProfile")}
          </AuthButton>

          <div className="flex gap-8 w-full mt-20">
            <AuthButton
              className={[styles.authButton, "mx-auto w-full"].join(" ")}
              onClick={() => {
                navigate("/admin");
              }}
            >
              Admin
            </AuthButton>

            <AuthButton
              className={[styles.authButton, "mx-auto w-full"].join(" ")}
              onClick={() => {
                navigate("/personel");
              }}
            >
              Personel
            </AuthButton>
          </div>
        </BgBox>
      </Container>
      <Footer />
    </div>
  );
};

export default UserProfile;
