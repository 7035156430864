import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from "./locales/en.json";
import trTranslation from "./locales/tr.json";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}
// add or remove language detector
i18n.init({
  resources: {
    tr: {
      translation: trTranslation,
    },
    en: {
      translation: enTranslation,
    },
  },
  fallbackLng: "tr",
  debug: true,
  interpolation: {
    escapeValue: false,
  },
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>
);
