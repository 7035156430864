import styles from "./style.module.css";
import {
  AuthBox,
  AuthButton,
  BusinessesBox,
  Description,
  Logo,
  TimeIcon,
} from "../../index";

const BuyTicketUpperBox = ({
  logo,
  photo,
  imageText,
  description,
  phone = "",
  children,
  className,
  ...props
}) => {
  return (
    <div className={[styles.pagesbox, className].join(" ")} {...props}>
      <BusinessesBox
        className={" xss:w-[272px] w-[240px]  xss:h-[400px] h-[352px]"}
        logo={logo}
        photo={photo}
      >
        {imageText}
      </BusinessesBox>

      <AuthBox className={styles.authBox}>
        <Description className={styles.upperBoxTitleText}>
          {imageText}
        </Description>
        <Description className={styles.upperBoxDescriptionText}>
          {description}
        </Description>
        <Description className={styles.upperBoxDescriptionText}>
          {phone}
        </Description>
        <div className="h-full flex items-center justify-center"></div>
      </AuthBox>
    </div>
  );
};

export default BuyTicketUpperBox;
