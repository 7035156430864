import { useState } from "react";
import { AuthButton, ForgotLink, Description } from "../../../components";
import styles from "./style.module.css";
import ReactCodeInput from "react-code-input";

const OtpInput = ({
  className,
  getCodeAgain = () => {},
  controlOtp = () => {},
  linkText,
}) => {
  const [otp, setOtp] = useState("");
  const [isShowAgainCodeText, setIsShowAgainCodeText] = useState(false);

  const props = {
    inputStyle: {
      fontFamily: "Barlow",
      margin: "4px",
      marginTop: "8px",
      width: "44px",
      borderRadius: "12px",
      fontSize: "21px",
      fontWeight: "400",
      height: "52px",
      paddingLeft: "17px",
      backgroundColor: "white",
      color: "#1E1E1E",
    },
  };

  const getCode = () => {
    getCodeAgain();

    setIsShowAgainCodeText(false);
  };

  return (
    <div className={[styles.otpinput, className].join(" ")}>
      <Description className={styles.description}>
        Doğrulama kodunu girin.
      </Description>
      <ReactCodeInput
        className="w-full"
        type="tel"
        fields={6}
        value={otp}
        onChange={(e) => setOtp(e)}
        {...props}
      />

      <form /*onSubmit={forgotingPasswordValidation}*/ className={styles.form}>
        <div className={styles.forminfo}>
          <button
            className={styles.code}
            onClick={(e) => {
              e.preventDefault();
              getCode();
            }}
          >
            {isShowAgainCodeText ? "Yeniden kod iste" : ""}
          </button>
          {/* <div className={styles.time}>
            {!isShowAgainCodeText && (
              <Timer
                initialMinute={2}
                initialSeconds={0}
                doneTimer={() => setIsShowAgainCodeText(true)}
              />
            )}
          </div> */}
        </div>

        <ForgotLink
          className="font-secondaryFont font-normal text-[15px] underline leading-[16px]  text-primary flex justify-center"
          to="/kayit-ol"
        >
          {linkText}
        </ForgotLink>

        <AuthButton
          className={styles.authButton}
          clickButton={() => {
            controlOtp(otp);
          }}
          disabled={otp.length < 6}
        >
          Devam Et
        </AuthButton>
      </form>
    </div>
  );
};

export default OtpInput;
