import styles from "./style.module.css";
import AuthInput from "../AuthInput";
import GenderDropdown from "../../Dropdown/GenderDropdown";

const BirthAndGenderDropdown = ({
  name,
  className,
  register,
  gender,
  changeGender,
  ...props
}) => {
  return (
    <div className={[styles.birthandgenderdropdown, className].join(" ")}>
      <AuthInput
        className={styles.authInput}
        name={name}
        type="date"
        register={register}
        {...props}
        onChange={(e) => {}}
      />
      <GenderDropdown
        changeGender={changeGender}
        gender={gender}
        className={styles.genderDropdown}
      />
    </div>
  );
};

export default BirthAndGenderDropdown;
