import styles from "./style.module.css";
import { AuthBox, Description } from "../../index";
import qrCode from "../../../assets/fakeImages/qrCode.png";
import { useNavigate } from "react-router-dom";

const BusinessPnrBox = ({
  logo,
  photo,
  children,
  input1,
  input2,
  input3,
  setInput1,
  setInput2,
  setInput3,
  button,
  className,
  ...props
}) => {
  const navigate = useNavigate();
  return (
    <div className={[styles.pagesbox, className].join(" ")} {...props}>
      <AuthBox className={styles.authBox1}>
        <Description className={styles.upperBoxTitleText}>QR OKUT</Description>
        <div className={styles.qrBox}>
          <img src={qrCode} alt="qrCode" />
        </div>
      </AuthBox>

      <AuthBox className={styles.authBox}>
        <Description className={styles.upperBoxTitleText1}>
          PNR NUMARASI İLE GİRİŞ YAP
        </Description>
        <div className={styles.secondBoxContainer}>
          <div className={styles.inputContainer}>
            <input
              className={styles.input}
              type="text"
              value={input2}
              onChange={(e) => setInput2(e.target.value)}
              placeholder="PNR NumarasıPNR Numarası giriniz."
            />
            <input
              className={styles.input}
              type="text"
              value={input3}
              onChange={(e) => setInput3(e.target.value)}
              placeholder="Telefon Numarası giriniz."
            />
          </div>

          <button
            onClick={() => {
              // navigate("/isletmeler/randevu-sorgulama/1");
              button();
            }}
            className={styles.searchBox}
          >
            Sorgula
          </button>
        </div>
        <p className={styles.bottomText}>
          Telefon ve PNR Numarası ile sorgula.
        </p>
      </AuthBox>
    </div>
  );
};

export default BusinessPnrBox;
