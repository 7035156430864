import { useState, useContext } from "react";
import {
  BgBox,
  AuthBox,
  Header,
  AuthInput,
  AuthButton,
  Logo,
  Container,
  Checkbox,
  TelephoneInput,
  BirthAndGenderDropdown,
  PasswordInput,
  RegisterLink,
  ForgotLink,
  SubmitButton,
  InputError,
} from "../../../../components";
import styles from "./style.module.css";
import yellowsplash from "../../../../assets/splash/yellowsplash.svg";
import orangesplash from "../../../../assets/splash/orangesplash.svg";
import okkologo from "../../../../assets/okkologo.svg";
import userlogo from "../../../../assets/userlogo.svg";
import menu from "../../../../assets/menu.svg";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { errorMessage } from "../../../../helpers/toast";
import { Link, useNavigate } from "react-router-dom";
import { axiosClient } from "../../../../api/config";
import { getServerErrorMessage } from "../../../../helpers/auth";
import { isUnder18 } from "../../../../helpers/age-control";

// şifreler eşleşmiyor api bağlandıktan sonra yap borderlar rede dönecek
const registerScheme = yup
  .object({
    email: yup.string().email("Geçerli bir e-posta adresi girin").required("Bu alan gerekli"),
    username: yup.string().required("Bu alan gerekli"),
    password: yup.string().required("Bu alan gerekli").min(8, "Şifre en az 8 karakter olmalı"),
    passwordConfirm: yup
      .string()
      .required("Bu alan gerekli")
      .min(8, "Şifre en az 8 karakter olmalı")
      .oneOf([yup.ref("password")], "Şifreler eşleşmiyor"),
    namesurname: yup.string().required("Bu alan gerekli"),
    // surname: yup.string().required("Bu alan gerekli"),
    // tcno: yup
    //   .string()
    //   .min(11, "TC Kimlik Numarası 11 haneli olmalı")
    //   .max(11, "TC Kimlik Numarası 11 haneli olmalı")
    //   .required("Bu alan gerekli"),
    date: yup
      .string()
      .required("Bu alan gerekli")
      .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, "Doğum tarihi formatı hatalı"),
    // gender: yup.string().required("Bu alan gerekli"),
    phone: yup
      .string()
      .min(10, "Lütfen uygun bir telefon numarası giriniz!")
      .max(10, "Lütfen uygun bir telefon numarası giriniz!")
      .required("Bu alan gerekli"),
  })
  .required();

const Register = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(registerScheme),
  });

  const [checkList, setCheckList] = useState({
    kvkkCheck: false,
    dataCheck: false,
    emailCheck: false,
  });

  const [phonePrefix, setPhonePrefix] = useState("90");
  const [gender, setGender] = useState("Erkek");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onSubmit = (data) => {
    let email = data.email;
    let username = data.username;
    let password = data.password;
    let passwordConfirm = data.passwordConfirm;
    let namesurname = data.namesurname;
    // let surname = data.surname;
    let tcno = 11111111111;
    let date = datePrettier(data.date);
    let lastGender = gender;
    let phone = phonePrefix + data.phone;

    let isUnderAge = isUnder18(date);

    if (isUnderAge) {
      errorMessage("18 yaşından küçükler kayıt olamaz!");
      return;
    }

    // checkList.emailCheck -> api düzeltilince bu property newDataya koyulacak!

    let newData = {
      phone: phone,
      firstname: namesurname.split(" ").slice(0, -1).join(" "),
      lastname: namesurname.split(" ").slice(-1).join(" "),
      username: username,
      birthday: date.split("/").reverse().join("-"), // put - instead of / and change the format to yyyy-mm-dd ->
      email: email,
      tc: 11111111111,
      gender: lastGender === "Erkek" ? true : false,
      password: password,
      password_again: passwordConfirm,
    };

    if (!checkAllCheckboxes()) {
      errorMessage("Lütfen ilk iki kutucuğa onay verin!");
      return;
    }

    registerSystem(newData);
  };

  const registerSystem = async (newData) => {
    setLoading(true);
    try {
      const response = await axiosClient.post("/users/register", JSON.stringify(newData));

      navigate({
        pathname: "/kayit-ol/mail-dogrula",
        search: `?email=${newData.email}&phone=${newData.phone}`,
      });
    } catch (err) {
      console.log(err);
      getServerErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const checkAllCheckboxes = () => {
    if (
      checkList.kvkkCheck &&
      checkList.dataCheck
      // checkList.emailCheck === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  const datePrettier = (date) => {
    let newDate = date.split("-");
    let day = newDate[2];
    let month = newDate[1];
    let year = newDate[0];

    return day + "/" + month + "/" + year;
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/"} />
      <Container className={styles.container}>
        <BgBox
          className={styles.bgBox}
          img1={orangesplash}
          img2={yellowsplash}
          img1Style="bottom-[26%] left-[48%]"
          img2Style="top-[3%] right-[52%]"
          windowsize={768}
        >
          <AuthBox className={styles.authBox}>
            <Logo className={styles.logo} />
            <form onSubmit={handleSubmit(onSubmit)} className={styles.formContainer}>
              <div className={styles.inputContainer}>
                <AuthInput className={styles.authInput} placeholder="E-posta Adresi" name="email" register={register} />
                <InputError text={errors.email?.message} />
              </div>

              <div className={styles.inputContainer}>
                <AuthInput className={styles.authInput} placeholder="Kullanıcı Adı" name="username" register={register} />
                <InputError text={errors.username?.message} />
              </div>

              <div className={styles.inputContainer}>
                <PasswordInput className={styles.authInput} placeholder="Şifre" name="password" register={register} />
                <InputError text={errors.password?.message} />
              </div>

              <div className={styles.inputContainer}>
                <PasswordInput className={styles.authInput} placeholder="Şifre Onay" name="passwordConfirm" register={register} />
                <InputError text={errors.passwordConfirm?.message} />
              </div>

              <div className={styles.inputContainer}>
                <AuthInput className={styles.authInput} placeholder="İsim Soyisim" name="namesurname" register={register} />
                <InputError text={errors.namesurname?.message} />
              </div>

              {/* <div className={styles.inputContainer}>
                <AuthInput
                  className={styles.authInput}
                  placeholder="Soyisim"
                  name="surname"
                  register={register}
                />
                <InputError text={errors.surname?.message} />
              </div> */}

              {/* <div className={styles.inputContainer}>
                <AuthInput
                  className={styles.authInput}
                  placeholder="TC Kimlik Numarası"
                  name="tcno"
                  register={register}
                  maxLength="11"
                />
                <InputError text={errors.tcno?.message} />
              </div> */}

              <div className={styles.inputContainer}>
                <BirthAndGenderDropdown
                  placeholder="01/01/01"
                  name="date"
                  register={register}
                  gender={gender}
                  changeGender={setGender}
                />
                <InputError text={errors.date?.message} />
              </div>

              <div className={styles.inputContainer}>
                <TelephoneInput
                  className={styles.telephoneInput}
                  name="phone"
                  register={register}
                  phonePrefix={phonePrefix}
                  changePhonePrefix={setPhonePrefix}
                />
                <InputError text={errors.phone?.message} />
              </div>

              <Checkbox
                id="kvkkCheck"
                className={styles.checkBox1}
                checkedValue={checkList?.kvkkCheck}
                onChangeValue={(value) => {
                  setCheckList((prev) => ({ ...prev, kvkkCheck: value }));
                }}
              >
                <Link to="/kvkk-sozlesmesi" className="underline" target="_blank">
                  Kişisel Verilerin Korunması hakkında bilgilendirme metni
                </Link>
                ni okudum ve içeriğini anladım.
              </Checkbox>
              <Checkbox
                id="dataCheck"
                className={styles.checkBox}
                checkedValue={checkList?.dataCheck}
                onChangeValue={(value) => {
                  setCheckList((prev) => ({ ...prev, dataCheck: value }));
                }}
              >
                Kişisel verilerimin, Okko üyelik kaydı oluşturmak ve üyelik haklarından yararlanmak amacıyla Okko’nun{" "}
                <Link to="/kvkk-sozlesmesi" className="underline" target="_blank">
                  Kişisel Verilerin Korunması hakkında bilgilendirme metni
                </Link>
                nde yer alan hususlarda işlenmesine ve paylaşılmasına aydınlatılmış açık rızam ile onay veriyorum.
              </Checkbox>
              <Checkbox
                id="emailcheck"
                className={styles.checkBox}
                checkedValue={checkList?.emailCheck}
                onChangeValue={(value) => {
                  setCheckList((prev) => ({ ...prev, emailCheck: value }));
                }}
              >
                Okko e-posta ve SMS gönderimleri aracılığıyla güncel etkinliklerden ve kampanyalardan haberdar olmak istiyorum.
              </Checkbox>

              <SubmitButton text="Kayıt Ol" loading={loading} />
            </form>
          </AuthBox>
          <RegisterLink link="Giriş Yap" to="/giris">
            Zaten hesabım var?
          </RegisterLink>
          <ForgotLink to="/sifremi-unuttum">Şifremi Unuttum!</ForgotLink>
        </BgBox>
      </Container>
    </div>
  );
};

export default Register;
