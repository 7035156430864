import { AddBusinessesInput, BorderBox, Title } from "../../index";
import { ReactComponent as Plus } from "../../../assets/plus.svg";
import { v4 as uuidv4 } from "uuid";

const AddSSSBox = ({
  children,
  sssArray,
  setSssArray,
  className,
  ...props
}) => {
  return (
    <div
      className="flex flex-col w-full bg-white rounded-lg gap-4 mb-20"
      {...props}
    >
      <Title className=" font-mainFont font-[400] text-[24px]  text-start items-center mt-20 text-gray-900 ml-6 mb-2">
        FAQ
      </Title>
      {sssArray.map((item, index) => {
        if (item.is_delete) return null;
        return (
          <div
            key={item.created_at}
            className={`border rounded-xl fill-transparent-[5%] border-gray-400  `}
          >
            <div className="question border border-b-gray-400  rounded-xl  drop-shadow-md bg-[#FEF2F5]  py-3 px-6  ">
              <AddBusinessesInput
                className="w-full rounded-full text-[24px] font-[400]"
                placeholder="Soru girin."
                value={sssArray[index].question}
                onChange={(e) => {
                  let temp = [...sssArray];
                  temp[index].question = e.target.value;
                  setSssArray(temp);
                }}
              ></AddBusinessesInput>
            </div>

            <div className="bg-white rounded-b-xl  py-6 px-6 ">
              <AddBusinessesInput
                className="w-full"
                placeholder="Cevap girin."
                value={sssArray[index].answer}
                onChange={(e) => {
                  let temp = [...sssArray];
                  temp[index].answer = e.target.value;
                  setSssArray(temp);
                }}
              ></AddBusinessesInput>
            </div>
          </div>
        );
      })}
      <button
        className="mt-2"
        onClick={() => {
          let tempSssArray = [...sssArray];
          tempSssArray.push({
            question: "",
            answer: "",
            id: null,
            is_delete: false,
            created_at: uuidv4(),
          });
          setSssArray(tempSssArray);
        }}
      >
        <BorderBox className="py-6 bg-[#F80A3A0D]">
          <Plus></Plus>
        </BorderBox>{" "}
      </button>
      {sssArray.length > 1 && (
        <button
          onClick={() => {
            if (sssArray.length == 1) return;

            const reversedSssArray = [...sssArray].reverse();

            const lastItemIndex = reversedSssArray.findIndex(
              (item) => !item.is_delete
            );

            if (lastItemIndex === -1) {
              return;
            }

            const actualIndex = sssArray.length - 1 - lastItemIndex;

            const tempSssArray = [...sssArray];
            tempSssArray[actualIndex].is_delete = true;

            setSssArray(tempSssArray);
          }}
        >
          <BorderBox className="py-8 bg-[#F80A3A0D] ">
            <div className="h-1 w-4 bg-[#959595] "></div>
          </BorderBox>{" "}
        </button>
      )}
    </div>
  );
};

export default AddSSSBox;
