import { useState, useEffect } from "react";
import {
  BgBox,
  AuthBox,
  Header,
  AuthButton,
  Logo,
  Container,
  RegisterLink,
  Title,
  TelephoneInput,
  Description,
  InputError,
  SubmitButton,
} from "../../../../components";
import styles from "./style.module.css";
import purplesplash from "../../../../assets/splash/purplesplash.svg";
import orangesplash from "../../../../assets/splash/orangesplash2.svg";
import okkologo from "../../../../assets/okkologo.svg";
import userlogo from "../../../../assets/userlogo.svg";
import menu from "../../../../assets/menu.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { axiosClient } from "../../../../api/config";
import { getServerErrorMessage } from "../../../../helpers/auth";

const forgotPasswordScheme = yup
  .object({
    phone: yup
      .string()
      .min(10, "Lütfen uygun bir telefon numarası giriniz!")
      .max(10, "Lütfen uygun bir telefon numarası giriniz!")
      .required("Bu alan gerekli"),
  })
  .required();

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgotPasswordScheme),
  });

  const navigate = useNavigate();

  const [phonePrefix, setPhonePrefix] = useState("90");

  const onSubmit = (data) => {
    let phone = phonePrefix + data.phone;

    sendCode(phone);
  };

  const sendCode = async (phone) => {
    try {
      const response = await axiosClient.post(
        "/users/forgot-password",
        JSON.stringify({ phone })
      );

      navigate({ pathname: "/sifremi-unuttum/otp", search: `?phone=${phone}` });
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink="/profil"
        homeLink={"/"}
      />
      <Container className={styles.container}>
        <BgBox
          className={styles.bgBox}
          img1={purplesplash}
          img2={orangesplash}
          img1Style="bottom-[45%] right-[57%]"
          img2Style="top-[33%] left-[52%]"
          windowsize={768}
        >
          <AuthBox className={styles.authBox}>
            <Logo className={styles.logo} />
            <Title className={styles.title}>
              Telefon numaranızı doğrulayın.
            </Title>
            <Description className={styles.description}>
              Telefon numaranızı girin.
            </Description>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.inputContainer}>
                <TelephoneInput
                  className="w-full"
                  name="phone"
                  register={register}
                  phonePrefix={phonePrefix}
                  changePhonePrefix={setPhonePrefix}
                />
                <InputError text={errors.phone?.message} />
              </div>

              <SubmitButton text="Devam Et" className="w-full mt-4" />
            </form>
          </AuthBox>
          <RegisterLink link="Giriş Yap" to="/giris">
            Zaten hesabım var?
          </RegisterLink>
          <RegisterLink link="Kayıt Ol!" to="/kayit-ol">
            Yeni misin?
          </RegisterLink>
        </BgBox>
      </Container>
    </div>
  );
};

export default ForgotPassword;
