import { Header } from "../components";
import okkologo from "../assets/okkologo.svg";
import userlogo from "../assets/userlogo.svg";
import menu from "../assets/menu.svg";
import { Link } from "react-router-dom";

const Kvkk = () => {
  return (
    <div className="grid grid-rows-layoutAuth">
      <Header
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink="/profil"
        homeLink={"/kayit-ol"}
      />

      <div className="px-8 lg:px-28 py-8">
        <Link to="/kayit-ol" className="text-primary text-2xl font-bold mb-8">
          GERİ DÖN
        </Link>
        <h1 className="text-3xl font-semibold mb-4 mt-3">
          GİZLİLİK SÖZLEŞMESİ
        </h1>

        <p className="mb-4">
          1. GENEL BİLGİLER
          <br />
          Bu Gizlilik Sözleşmesi, OKKO olarak, web sitesi, mobil uygulama veya
          diğer dijital platformlarımız üzerinden topladığımız veya işlediğimiz
          kişisel verilerinizi nasıl koruduğumuza dair bilgi vermektedir.
        </p>

        <p className="mb-4">
          2. KİŞİSEL VERİLERİN İŞLENMESİ
          <br />
          Şirket olarak, KVKK'ya uygun olarak kişisel verilerinizi belirli
          amaçlarla toplayabilir ve işleyebiliriz. Kişisel verileriniz, sizinle
          doğrudan iletişime geçmek, taleplerinizi yerine getirmek,
          hizmetlerimizi iyileştirmek ve sizinle daha iyi etkileşim kurmak gibi
          amaçlarla kullanılabilir.
        </p>

        <p className="mb-4">
          3. KİŞİSEL VERİLERİN PAYLAŞIMI
          <br />
          Şirket olarak, kişisel verilerinizi yasal düzenlemelere uygun olarak
          üçüncü taraflarla paylaşabiliriz. Bu tür paylaşımlar, hizmet
          sağlayıcılar, iş ortakları veya yetkili kamu kurumları ile olabilir ve
          amacı dışında kullanılmaması için gerekli önlemler alınacaktır.
        </p>

        <p className="mb-4">
          4. KİŞİSEL VERİLERİN KORUNMASI
          <br />
          Şirket olarak, kişisel verilerinizi korumak ve yetkisiz erişim,
          değişiklik veya yayınlanmaya karşı önlem almak için uygun güvenlik
          önlemleri uyguluyoruz. Ancak, İnternet üzerinden yapılan iletişimlerin
          tamamen güvenli olması garanti edilemez, bu nedenle verilerinizi
          paylaşırken dikkatli olmanız önemlidir.
        </p>

        <p className="mb-4">
          5. ÇEREZLER
          <br />
          Web sitesi ve uygulamalarımız, kullanıcı deneyimini geliştirmek ve
          tercihlerinizi hatırlamak için çerezler kullanabilir. Çerezleri kabul
          etmek veya reddetmek için tarayıcı ayarlarınızı düzenleyebilirsiniz.
          Ancak, bazı çerezlerin devre dışı bırakılması bazı fonksiyonların
          çalışmamasına neden olabilir.
        </p>

        <p className="mb-4">
          6. HAKLARINIZ
          <br />
          KVKK kapsamında, kişisel verilerinizle ilgili bazı haklara sahipsiniz.
          Bu haklar arasında kişisel verilerinize erişim, düzeltme, silme ve
          işleme amaçlarına itiraz etme hakkı bulunmaktadır. Bu haklarınızı
          kullanmak için bizimle iletişime geçebilirsiniz.
        </p>

        <p className="mb-4">
          7. DEĞİŞİKLİKLER
          <br />
          Şirket, bu Gizlilik Sözleşmesini zaman zaman güncelleme hakkını saklı
          tutar. Güncellemeler web sitesinde veya uygulamalarda yayınlandıktan
          sonra geçerli olacaktır. Sözleşmenin en son sürümünü incelemeniz
          önemlidir.
        </p>

        <p className="mb-4">
          8. İLETİŞİM
          <br />
          Kişisel verilerinizle ilgili herhangi bir konuda bizimle iletişime
          geçmek veya haklarınızı kullanmak için info@okko.com.tr adresine
          e-posta gönderebilirsiniz.
        </p>
      </div>
    </div>
  );
};

export default Kvkk;
