import { AddBusinessesInput, AuthButton, Description } from "../../index";

import { useContext } from "react";
import StoreContext from "../../../context/store";

const AddAgentaBox = ({
  personelData,
  addAgenta,
  deleteAgenta,
  personelExist,
  discartRate,
  setDiscartRate,
  discartLimit,
  setDiscartLimit,
  agentaName,
  setAgentaName,
}) => {
  const prettyDate = (date) => {
    const dateArray = date.split("-");
    const year = dateArray[0];
    const month = dateArray[1];
    const day = dateArray[2];
    const monthNames = [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ];
    return day + " " + monthNames[month - 1] + " " + year;
  };

  return (
    <div className="flex flex-col w-full">
      <AddBusinessesInput
        className="w-full h-[45px] text-[16px] mt-10"
        value={personelData.username}
        readOnly
      />
      <AddBusinessesInput
        className="w-full h-[45px] text-[16px] mt-5"
        value={personelData.firstname + " " + personelData.lastname}
        readOnly
      />
      <AddBusinessesInput
        className="w-full h-[45px] text-[16px] mt-5"
        value={agentaName}
        placeholder="Agenta , 3. parti şirket ya da kişi adı giriniz."
        onChange={(e) => setAgentaName(e.target.value)}
      />
      <div className="w-full flex flex-row   text-[16px] mt-5 max-sm:flex-wrap justify-between gap-3">
        <div className="flex flex-row max-sm:flex-wrap w-full">
          <Description className="text-[#707376] text-lg flex xl:whitespace-nowrap w-full ml-1">
            Uygulanacak indirimi seçiniz (Bilet Başı Fiyattan Düşülür):
          </Description>
          <AddBusinessesInput
            className="w-full sm:w-[40px] h-[30px] mx-2"
            placeholder="5"
            value={discartRate}
            onChange={(e) => setDiscartRate(e.target.value)}
          />
          <Description className="text-[#707376] text-lg flex xl:whitespace-nowrap mr-1">
            %
          </Description>
        </div>
        <div className="flex w-full flex-row max-sm:flex-wrap">
          <Description className="text-[#707376] text-lg  xl:justify-end flex  w-full ml-1">
            Yararlanılabilecek indirimli kazanç üst limiti (Aylık) :
          </Description>
          <AddBusinessesInput
            className="w-full sm:w-[50px] h-[30px] mx-2"
            placeholder="500"
            value={discartLimit}
            onChange={(e) => setDiscartLimit(e.target.value)}
          />
          <Description className="text-[#707376] text-lg mr-1 flex">
            TL
          </Description>
        </div>
      </div>
      <div className="w-full flex flex-row  h-[45px] text-[16px] mt-5 gap-5 max-sm:flex-wrap">
        <AddBusinessesInput
          className="w-full sm:w-5/12"
          value={personelData.phone}
          readOnly
        />
        <AddBusinessesInput
          className="w-full sm:w-2/12"
          value={personelData?.gender ? "Erkek" : "Kadın"}
          readOnly
        />
        <AddBusinessesInput
          className="w-full sm:w-5/12"
          value={personelData?.birthday && prettyDate(personelData?.birthday)}
          readOnly
        />
      </div>

      {!personelExist && (
        <AuthButton
          className=" flex px-[45px] py-3 max-sm:mt-32 mt-7 font-[400] rounded-[12px] text-[24px] whitespace-nowrap"
          clickButton={() => addAgenta()}
        >
          Şubeye Ekle
        </AuthButton>
      )}
      {personelExist && (
        <div className="flex justify-center max-sm:flex-wrap">
          {/* <AuthButton className="flex  px-[45px] py-3 max-sm:mt-28 mt-7 font-[400] rounded-[12px] text-[24px] whitespace-nowrap bg-[#959595] text-white">
          Düzenle
        </AuthButton> */}
          <AuthButton
            clickButton={() => deleteAgenta()}
            className="flex  px-[45px] py-3 max-sm:mt-4 mt-7 font-[400] rounded-[12px] text-[24px] whitespace-nowrap"
          >
            Şubeden Çıkar
          </AuthButton>
        </div>
      )}
    </div>
  );
};

export default AddAgentaBox;
