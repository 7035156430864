import React from "react";
import {
  BgBox,
  Header,
  Container,
  Title,
  Description,
  Footer,
  TicketButtonBox,
  AuthButton,
  SearchAppointmentBox,
} from "../../../../components";
import styles from "./style.module.css";
import okkologo from "../../../../assets/okkologo.svg";
import userlogo from "../../../../assets/userlogo.svg";
import menu from "../../../../assets/menuwhite.svg";

const SearchAppointment = () => {
  const fakeData2 = [
    {
      id: 1,
      title: "Watergarden - Zippline (7+ yaş / Saat)",
      date: "01/02/2023",
    },
    {
      id: 2,
      title: "Watergarden - Zippline (7+ yaş / Saat)",
      date: "03/08/2023",
    },
    {
      id: 3,
      title: "Watergarden - Zippline (7+ yaş / Saat)",
      date: "11/11/2023",
    },
  ];
  return (
    <div className="grid grid-rows-layoutAuth">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/"} />
      <Container className={styles.container}>
        <Title className={styles.upperTitle}>Macera, Adrenalin, Mutluluk...</Title>
        <Description className={styles.description}>Eğlencenin merkezine hoş geldin!</Description>

        <Title className={styles.title}>PNR ile Randevu Sorgula</Title>
        <SearchAppointmentBox className={styles.searchAppointmentBox}>
          <Description className={styles.searchAppointmentBoxTitle}>PNR Numarası</Description>
        </SearchAppointmentBox>
      </Container>
      <Footer />
    </div>
  );
};

export default SearchAppointment;
