import { Description } from "../../index";
import styles from "./style.module.css";
import { useContext, useEffect } from "react";
import StoreContext from "../../../context/store";

const PersonelPermissionsCheckBox = ({
  data,
  isPersonel,
  setIsPersonel,
  isTecnicalSupport,
  setIsTecnicalSupport,
  isSalesRepresentative,
  setIsSalesRepresentative,
}) => {
  const { personelPermissions, setPersonelPermissions } =
    useContext(StoreContext);

  useEffect(() => {
    setPersonelPermissions(data);
  }, []);

  const selectRadio = (event, type) => {
    if (type === "personel") {
      setIsPersonel(true);
      setIsTecnicalSupport(false);
      setIsSalesRepresentative(false);
    } else if (type === "tecnicalSupport") {
      setIsPersonel(false);
      setIsTecnicalSupport(true);
      setIsSalesRepresentative(false);
    } else if (type === "salesRepresentative") {
      setIsPersonel(false);
      setIsTecnicalSupport(false);
      setIsSalesRepresentative(true);
    }
  };

  return (
    <div className="flex flex-col w-full gap-3">
      <div className={styles.outerbox}>
        <div className={styles.box}>
          <Description className={styles.desc}>Teknik Sorumlu</Description>
        </div>
        <input
          type="checkbox"
          className={styles.check}
          value={isTecnicalSupport}
          onChange={(event) => {
            selectRadio(event, "tecnicalSupport");
          }}
          checked={isTecnicalSupport}
        />
      </div>
      <div className={styles.outerbox}>
        <div className={styles.box}>
          <Description className={styles.desc}>Kasiyer</Description>
        </div>
        <input
          type="checkbox"
          className={styles.check}
          value={isSalesRepresentative}
          onChange={(event) => {
            selectRadio(event, "salesRepresentative");
          }}
          checked={isSalesRepresentative}
        />
      </div>
      <div className={styles.outerbox}>
        <div className={styles.box}>
          <Description className={styles.desc}> Personel sorumlusu</Description>
        </div>
        <input
          type="checkbox"
          className={styles.check}
          value={isPersonel}
          onChange={(event) => {
            selectRadio(event, "personel");
          }}
          checked={isPersonel}
        />
      </div>
    </div>
  );
};

export default PersonelPermissionsCheckBox;
