import styles from "./style.module.css";
import { Link } from "react-router-dom";

const ForgotLink = ({ className, children, ...props }) => {
  return (
    <Link className={[styles.forgotlink, className].join(" ")} {...props}>
      {children}
    </Link>
  );
};

export default ForgotLink;
