import styles from "./style.module.css";
import { AddBusinessCardBox, BusinessDescCard } from "../../../index";

const AddBusinessUpperBox = ({
  logo,
  setLogo,
  photo,
  setPhoto,
  businessName,
  setBusinessName,
  businessDesc,
  setBusinessDesc,
  className,
  ...props
}) => {
  return (
    <div className={[styles.box, className].join(" ")} {...props}>
      <AddBusinessCardBox
        photo={photo}
        setPhoto={setPhoto}
        businessName={businessName}
        setBusinessName={setBusinessName}
        className={"xss:w-[272px] w-[240px] xss:h-[400px] h-[352px] "}
      />
      <BusinessDescCard
        businessName={businessName}
        setBusinessName={setBusinessName}
        businessDesc={businessDesc}
        setBusinessDesc={setBusinessDesc}
      />
    </div>
  );
};

export default AddBusinessUpperBox;
