import {
  BgBox,
  AuthBox,
  Header,
  AuthButton,
  Logo,
  Container,
  Title,
  Footer,
} from "../../../../../components/";
import styles from "./style.module.css";
import redsplash from "../../../../../assets/splash/redsplash.svg";
import bluesplash from "../../../../../assets/splash/bluesplash2.svg";
import okkologo from "../../../../../assets/okkologo.svg";
import userlogo from "../../../../../assets/userlogo.svg";
import menu from "../../../../../assets/menu.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const TelChanged = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink="/profil"
        homeLink={"/"}
      />
      <Container className={styles.container}>
        <BgBox
          className={styles.bgBox}
          img1={bluesplash}
          img2={redsplash}
          img1Style="top-[38%] left-[42%] rotate-[240deg]"
          img2Style="bottom-[12%] right-[63%]"
          windowsize={900}
        >
          <AuthBox className={styles.authBox}>
            <Logo className={styles.logo} />
            <Title className={styles.title}>{t("phoneChangedTitle")}</Title>
            <AuthButton
              clickButton={() => {
                navigate("/profil");
              }}
              className={styles.authButton}
            >
              {t("okayButton")}
            </AuthButton>
          </AuthBox>
        </BgBox>
      </Container>
      <Footer />
    </div>
  );
};

export default TelChanged;
