import {
  BgBox,
  AuthBox,
  Header,
  AuthButton,
  Logo,
  Container,
  RegisterLink,
  PasswordInput,
  Title,
  Description,
  SubmitButton,
  InputError,
} from "../../../../components";
import styles from "./style.module.css";
import purplesplash from "../../../../assets/splash/purplesplash.svg";
import orangesplash from "../../../../assets/splash/orangesplash2.svg";
import okkologo from "../../../../assets/okkologo.svg";
import userlogo from "../../../../assets/userlogo.svg";
import menu from "../../../../assets/menu.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { getServerErrorMessage } from "../../../../helpers/auth";
import { axiosClient } from "../../../../api/config";

const newPasswordScheme = yup
  .object({
    password: yup
      .string()
      .required("Bu alan gerekli")
      .min(8, "Şifre en az 8 karakter olmalı"),
    passwordConfirm: yup
      .string()
      .required("Bu alan gerekli")
      .min(8, "Şifre en az 8 karakter olmalı")
      .oneOf([yup.ref("password")], "Şifreler eşleşmiyor"),
  })
  .required();

const NewPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(newPasswordScheme),
  });

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const phone = searchParams.get("phone") || "";

  const onSubmit = (data) => {
    let password = data.password;
    let passwordConfirm = data.passwordConfirm;

    let newData = {
      password: password,
      password_again: passwordConfirm,
    };

    changePassword(newData);
  };

  const changePassword = async (newData) => {
    navigate("/sifremi-unuttum/otp/sifremi-yenile/sifre-olusturuldu");
    try {
      const response = await axiosClient.post(
        "/users/change-password",
        JSON.stringify({
          phone,
          password: newData.password,
          password_again: newData.password_again,
        })
      );
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink="/profil"
        homeLink={"/"}
      />
      <Container className={styles.container}>
        <BgBox
          className={styles.bgBox}
          img1={orangesplash}
          img2={purplesplash}
          img1Style="bottom-[16%] left-[52%]"
          img2Style="bottom-[45%] right-[57%]"
          windowsize={768}
        >
          <AuthBox className={styles.authBox}>
            <Logo className={styles.logo} />

            <Title className={styles.title}>Yeni şifrenizi girin.</Title>
            <Description className={styles.description}>
              Oluşturmak istediğiniz şifreyi girin.
            </Description>

            <form
              onSubmit={handleSubmit(onSubmit)}
              className={styles.formContainer}
            >
              <div className={styles.inputContainer}>
                <PasswordInput
                  className={styles.authInput}
                  placeholder="Şifre"
                  name="password"
                  register={register}
                />
                <InputError text={errors.password?.message} />
              </div>
              <div className={styles.inputContainer}>
                <PasswordInput
                  className={styles.authInput}
                  placeholder="Şifre Onay"
                  name="passwordConfirm"
                  register={register}
                />
                <InputError text={errors.passwordConfirm?.message} />
              </div>

              <SubmitButton text="Devam Et" />
            </form>
          </AuthBox>
          <RegisterLink link="Giriş Yap" to="/giris">
            Zaten hesabım var?
          </RegisterLink>
          <RegisterLink link="Kayıt Ol!" to="/kayit-ol">
            Yeni misin?
          </RegisterLink>
        </BgBox>
      </Container>
    </div>
  );
};

export default NewPassword;
