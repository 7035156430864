import { useState, useEffect } from "react";
import styles from "./style.module.css";
import AuthInput from "../../Input/AuthInput";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { getServerErrorMessage } from "../../../helpers/auth";
import MiniLoading from "../../Loading/MiniLoading";
import { successMessage } from "../../../helpers/toast";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import ExportPdf from "../../ExportPdf";

const BusinessRequirementsFormAdmin = ({ children, answers, className, date, time, ...props }) => {
  // const inputRef = useRef(null);
  const authAxiosClient = useAxiosClient();
  const { auth } = useAuth();
  const { isletmeId } = useParams();

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);
  const [articles, setArticles] = useState([]);

  // image
  const [isCheckedArray, setIsCheckedArray] = useState(Array(articles.length).fill(true));
  const [selectedImages, setSelectedImages] = useState(Array(articles.length).fill(null));

  const [loadingAdd, setLoadingAdd] = useState(false);

  const navigation = useNavigate();

  useEffect(() => {
    const handleClick = (event) => {
      if (event.target.classList.contains("not")) {
        const clickedQuestionIndex = parseInt(event.target.getAttribute("data-index"));

        setActiveQuestionIndex(activeQuestionIndex === clickedQuestionIndex ? null : clickedQuestionIndex);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [activeQuestionIndex]);

  useEffect(() => {
    getArticles();
  }, []);

  const getArticles = async () => {
    try {
      const response = await authAxiosClient.get(`/superuser/get-articles/branch-id=${isletmeId}`);

      let localArticles = response?.data?.data;

      localArticles = localArticles.map((article) => {
        return {
          rule: article?.rule,
          //id: article?.id || null,
          id: null,
          is_delete: false,
          priority: article?.priority,
        };
      });

      setArticles(localArticles);
    } catch (error) {
      getServerErrorMessage(error);
    } finally {
    }
  };

  const register = () => {};

  const checkControl = (articleObj) => {
    // if (isCheckedArray[id] === true) {
    //   return "bg-black";
    // } else {
    //   return "bg-white";
    // }

    if (articleObj.status === true) {
      return "bg-black";
    }
  };

  const checkBorderControl = (id) => {
    // if (isCheckedArray[id] === true) {
    //   return "border-gray-200";
    // } else {
    //   return "border-[#F80A3A]";
    // }
  };

  const completeRequirements = async () => {
    try {
      setLoadingAdd(true);
      const response = await authAxiosClient.post(
        `/superuser/add-or-edit-or-delete-articles/branch-id=${isletmeId}`,
        { rules: articles },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // articles.map(async (article) => {
      //   let formData = new FormData();
      //   formData.append("rule", article?.rule);
      //   formData.append("image", article?.image);
      //   formData.append("note", article?.note);
      //   formData.append("status", article?.status);

      //   const response = await authAxiosClient.post(
      //     "/personel/add_requirements",
      //     formData,
      //     {
      //       headers: {
      //         "Content-Type": "multipart/form-data",
      //       },
      //     }
      //   );
      // });
      successMessage("İşletme gereksinimleri başarıyla tamamlandı.");
      navigation(-1);
    } catch (err) {
      getServerErrorMessage(err);
    } finally {
      setLoadingAdd(false);
    }
  };

  const addArticle = () => {
    const newArticles = [...articles];
    newArticles.push({
      rule: "",
      is_delete: false,
      id: null,
      priority: 1, // kırmızı, sari
    });
    setArticles(newArticles);
  };

  const deleteArticle = (index) => {
    const newArticles = [...articles];
    newArticles.pop(index, 1);
    setArticles(newArticles);
  };

  return (
    <div className={[styles.sssbox, className].join(" ")}>
      <ExportPdf
        className="ml-auto"
        fileName="teknik-kontrol-anketi.pdf"
        fileTitle="Teknik Kontrol Anketi"
        data={articles.map((item) => [item.rule, item.status === true ? "Uygun" : "Uygun Değil", item.note == "null" ? "-" : item.note])}
        headData={[["Kural", "Durum", "Not"]]}
      />

      <div className="flex flex-col gap-4">
        {articles?.map((article, index) => (
          <div key={index} className={`border rounded-xl fill-transparent-[5%]  text-[#959595] ${checkBorderControl(index)} `}>
            <div
              className="question flex sm:flex-row flex-col border border-gray-400  rounded-xl  text-[24px] drop-shadow-md bg-white  py-3 px-3 cursor-pointer md:px-[72px]"
              data-index={index}
            >
              <AuthInput
                placeholder="İşletme kuralları, işletme gereksinimi,"
                className={"w-full flex max-w-full drop-shadow-xl mr-2 "}
                register={register}
                value={article?.rule}
                //readOnly={index >= requirements.length ? false : true}
                onChange={(e) => {
                  const newArticles = [...articles];
                  newArticles[index].rule = e.target.value;
                  setArticles(newArticles);
                }}
              />
              <div className="flex items-center gap-2 ml-4">
                <button
                  className={`py-1 px-2 w-20 text-sm rounded-full ${
                    articles[index].priority == 1 ? "bg-red-500 text-white" : "bg-gray-200"
                  }`}
                  onClick={() => {
                    const newArticles = [...articles];
                    newArticles[index].priority = 1;
                    setArticles(newArticles);
                  }}
                >
                  Kırmızı Öncelik
                </button>

                <button
                  className={`py-1 px-2 w-20 text-sm rounded-full ${
                    articles[index].priority == 2 ? "bg-yellow-500 text-white" : "bg-gray-200"
                  }`}
                  onClick={() => {
                    const newArticles = [...articles];
                    newArticles[index].priority = 2;
                    setArticles(newArticles);
                  }}
                >
                  Sarı Öncelik
                </button>
              </div>

              {/* <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              id={`file${index}`}
              onChange={(event) => {
                const file = event.target.files[0];
                const newSelectedImage = [...selectedImages];
                newSelectedImage[index] = file;
                setSelectedImages(newSelectedImage);

                setArticles((prev) => {
                  const newArticles = [...prev];
                  newArticles[index].image = file;
                  return newArticles;
                });
              }}
              //ref={inputRef}
            />
            <button
              className=" bg-white drop-shadow-xl rounded-xl m-2 px-4"
              onClick={() => {
                //inputRef.current.click();
                document.getElementById(`file${index}`).click();
              }}
            >
              Görsel
            </button>
            <div>
              {selectedImages[index] ? (
                <img
                  className="w-[35px] h-[35px] m-2 mr-10"
                  src={URL.createObjectURL(selectedImages[index])}
                  alt="görsel"
                />
              ) : (
                <img
                  className="w-[35px] h-[35px] m-2 mr-10"
                  src={`${API_URL}${article?.image}`}
                  alt="görsel"
                />
              )}
            </div> */}

              {/* <button
              className="not bg-white drop-shadow-xl rounded-xl m-2 px-4"
              data-index={index} // Add data-index attribute to identify the clicked question
            >
              Not
            </button> */}
              {/* <div className="h-full pt-3 ">
              <button
                onClick={() => {
                  const newIsCheckedArray = [...isCheckedArray];
                  newIsCheckedArray[index] = !newIsCheckedArray[index];
                  setIsCheckedArray(newIsCheckedArray);

                  const newArticles = [...articles];
                  newArticles[index].status = !newArticles[index].status;
                  setArticles(newArticles);
                }}
                className="circle justify-center bg-white border-2 border-black rounded-full w-[35px] h-[35px]"
              >
                <div className="w-full h-full justify-center items-center flex">
                  <div
                    className={`rounded-full flex  border-none w-[22px] h-[22px]  ${checkControl(
                      article
                    )}`}
                  ></div>
                </div>
              </button>
            </div> */}
            </div>
            {activeQuestionIndex === index && (
              <div className="flex sm:flex-row flex-col bg-white rounded-b-xl answer py-3 px-3 xss:px-[72px] text-[16px] ">
                <AuthInput
                  placeholder="Not Ekleyin."
                  className={"w-full flex max-w-full drop-shadow-xl mx-2 "}
                  register={register}
                  value={article?.note == "null" ? "" : article?.note}
                  onChange={(e) => {
                    const newArticles = [...articles];
                    newArticles[index].note = e.target.value;
                    setArticles(newArticles);
                  }}
                />
                {/* <button
                className="bg-white border-[#F80A3A] font-medium border text-xl text-[#F80A3A] drop-shadow-xl rounded-xl m-2 sm:px-8"
                onClick={register}
              >
                Onayla
              </button> */}
              </div>
            )}
          </div>
        ))}
      </div>

      {auth.role !== "technical_support" && (
        <div className="flex w-full justify-between">
          <button
            className="bg-primary font-medium border text-xl text-white rounded-full px-8 py-4 mr-auto"
            onClick={() => {
              addArticle();
            }}
          >
            Madde Ekle
          </button>
          <button
            className="bg-primary font-medium border text-xl text-white rounded-full px-8 py-4 ml-auto"
            onClick={() => {
              deleteArticle();
            }}
          >
            Madde Çıkar
          </button>
        </div>
      )}

      {loadingAdd ? (
        <MiniLoading />
      ) : (
        <button
          className="bg-primary font-medium border text-xl text-white rounded-full px-8 py-4"
          onClick={() => {
            completeRequirements();
          }}
        >
          Tamamla
        </button>
      )}
    </div>
  );
};

export default BusinessRequirementsFormAdmin;
