import React from "react";
import { Link } from "react-router-dom";
import styles from "./style.module.css";

const Agentas = ({ className, type, agentasData, ...props }) => {
  return (
    <div className={[styles.agentascont, className].join(" ")} {...props}>
      <div className={styles.agentatitle}>
        Agenta ve 3. Parti Anlaşmalı Kişi ve Şirketler
      </div>
      {agentasData.map((agenta) => (
        <div className={styles.agentabox} key={agenta.id}>
          <div className={styles.leftside}>
            <img
              src={agenta.image}
              className={styles.agentaimg}
              alt="agenta-image"
            />
            <div className={styles.agentaname}>{agenta.name}</div>
          </div>
          <div className={styles.buttonbox}>
            <Link
              className={styles.button}
              to={`/admin/agentalar/${agenta.id}`}
            >
              Seç
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Agentas;
