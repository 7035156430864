import styles from "./style.module.css";
import { ReactComponent as Plus } from "../../assets/plus.svg";
import { AddBusinessesInput, BorderBox, Title, AuthBox, AddBusinessesTextArea } from "../index";
import { v4 as uuidv4 } from "uuid";

const TicketDetails = ({
  businessInputsArray,
  setBusinessInputsArray,
  businessTitle,
  setBusinessTitle,
  businessExtraDesc,
  setBusinessExtraDesc,
  workingHours,
  setWorkingHours,
}) => {
  return (
    <div className="flex flex-col w-full">
      <Title className=" font-mainFont font-[400] text-[34px]  text-center items-center text-gray-900 mt-12 mb-8">
        Bilet Detayları
      </Title>

      {businessInputsArray.map((item, index) => {
        if (item.is_delete) return null;
        return (
          <BorderBox key={item.created_at} className="flex flex-row max-lg:flex-wrap gap-4 mt-6">
            <div className="flex flex-col gap-1 w-full">
              <label htmlFor="biletname" className="ml-1.5">
                Bilet İsmi
              </label>
              <AddBusinessesInput
                id="biletname"
                placeholder="Bilet ismini giriniz"
                className="w-full"
                value={businessInputsArray[index].name}
                onChange={(e) => {
                  const newValue = e.target.value; // Store the value in a variable
                  let temp = [...businessInputsArray];
                  temp[index].name = newValue;
                  setBusinessInputsArray(temp);
                }}
              />
            </div>

            <div className="flex flex-col gap-1 w-full">
              <label htmlFor="biletfiyat" className="ml-1.5">
                Bilet Fiyatı
              </label>
              <AddBusinessesInput
                id="biletfiyat"
                placeholder="Bilet Fiyatı Giriniz"
                value={businessInputsArray[index].price}
                onChange={(e) => {
                  let temp = [...businessInputsArray];
                  temp[index].price = e.target.value;
                  setBusinessInputsArray(temp);
                }}
              />
            </div>

            <div className="flex flex-col gap-1 w-full">
              <label htmlFor="acentefiyat" className="ml-1.5">
                Acente/Kampanyalı Fiyat
              </label>
              <AddBusinessesInput
                id="acentefiyat"
                placeholder="Acente/Kampanyalı Fiyat Giriniz"
                value={businessInputsArray[index].campaign_price}
                onChange={(e) => {
                  let temp = [...businessInputsArray];
                  temp[index].campaign_price = e.target.value;
                  setBusinessInputsArray(temp);
                }}
              />
            </div>

            <div className="flex flex-col gap-1 w-full">
              <label htmlFor="kisisayisi" className="ml-1.5">
                Kişi Sayısı
              </label>
              <AddBusinessesInput
                id="kisisayisi"
                placeholder="Kişi Sayısı"
                value={businessInputsArray[index].slots}
                onChange={(e) => {
                  let temp = [...businessInputsArray];
                  temp[index].slots = e.target.value;
                  setBusinessInputsArray(temp);
                }}
              />
            </div>

            <div className="flex flex-col gap-1 w-full">
              <label htmlFor="dakikaaraligi" className="ml-1.5">
                Dakika Aralığı
              </label>
              <AddBusinessesInput
                id="dakikaaraligi"
                placeholder="Dakika Aralığı"
                value={businessInputsArray[index].time_interval}
                onChange={(e) => {
                  let temp = [...businessInputsArray];
                  temp[index].time_interval = e.target.value;
                  setBusinessInputsArray(temp);
                }}
              />
            </div>
          </BorderBox>
        );
      })}
      <button
        onClick={() => {
          let tempBusinessInputsArray = [...businessInputsArray];
          tempBusinessInputsArray.push({
            name: "",
            price: "",
            campaign_price: "",
            slots: "",
            time_interval: "",
            is_delete: false,
            id: null,
            created_at: uuidv4(),
          });
          setBusinessInputsArray(tempBusinessInputsArray);
        }}
        className="mt-6"
      >
        <BorderBox className="py-6">
          <Plus></Plus>
        </BorderBox>
      </button>

      {businessInputsArray.length > 1 && (
        <button
          onClick={() => {
            if (businessInputsArray.length == 1) return;

            const reversedBusinessInputsArray = [...businessInputsArray].reverse();

            const lastItemIndex = reversedBusinessInputsArray.findIndex((item) => !item.is_delete);

            if (lastItemIndex === -1) {
              return;
            }

            const actualIndex = businessInputsArray.length - 1 - lastItemIndex;

            const tempBusinessArray = [...businessInputsArray];
            tempBusinessArray[actualIndex].is_delete = true;

            setBusinessInputsArray(tempBusinessArray);
          }}
          className="mt-6"
        >
          <BorderBox className="py-8">
            <div className="h-1 w-4 bg-[#959595]"></div>
          </BorderBox>
        </button>
      )}

      <Title className=" font-mainFont font-[400] text-[24px]  text-start items-center mt-20 text-gray-900 ml-6 mb-2">
        Şube Bilgileri
      </Title>

      <AuthBox className={styles.authBox1}>
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="subebasligi" className="ml-1.5">
            Şube Başlığı
          </label>
          <AddBusinessesInput
            id="subebasligi"
            placeholder="Şube Başlığı Giriniz"
            className="w-full h-[70px]"
            value={businessTitle}
            onChange={(e) => {
              setBusinessTitle(e.target.value);
            }}
          />
        </div>

        <div className="flex flex-col gap-1 w-full mt-6">
          <label htmlFor="subeaciklamasi" className="ml-1.5">
            Şube Açıklaması
          </label>
          <AddBusinessesTextArea
            id="subeaciklamasi"
            placeholder="Şube ek detay ve açıklaması giriniz."
            className="w-full h-[140px]"
            value={businessExtraDesc}
            onChange={(e) => {
              setBusinessExtraDesc(e.target.value);
            }}
          />
        </div>
      </AuthBox>
      <Title className=" font-mainFont font-[400] text-[24px]  text-start items-center text-gray-900 ml-6 mb-2">
        Detaylar ve Kurallar
      </Title>
      <AuthBox className={styles.authBox2}>
        <Title className=" font-mainFont font-[600] text-[16px]  text-start place-self-start text-gray-900 mb-2">
          Çalışma saatleri:
        </Title>
        <AddBusinessesInput
          placeholder="Çalışma saatlerini yazınız."
          className="w-full"
          value={workingHours}
          onChange={(e) => {
            setWorkingHours(e.target.value);
          }}
        />
      </AuthBox>
    </div>
  );
};

export default TicketDetails;
