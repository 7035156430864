import { useState, useEffect } from "react";
import styles from "./style.module.css";
import okkologo from "../../../assets/okkologo.svg";
import userlogo from "../../../assets/userlogo.svg";
import menu from "../../../assets/menu.svg";
import { Header, Description, Container } from "../../../components";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { getServerErrorMessage } from "../../../helpers/auth";

const PersonalCameraSellings = () => {
  const authAxiosClient = useAxiosClient();

  const [sellingsData, setSellingsData] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const [cameraData] = await Promise.all([authAxiosClient.get(`/personel/get-monthly-selled-camera-for-personel`)]);
      console.log("cameradaa: ", cameraData?.data?.data);
      setSellingsData(cameraData?.data?.data);
    } catch (err) {
      getServerErrorMessage(err);
    }
  };

  return (
    <div className="grid grid-rows-layoutAuth">
      <Header okkoLogo={okkologo} userIcon={userlogo} menuIcon={menu} profileLink="/profil" homeLink={"/"} />
      <Container className={styles.container}>
        <Description className={[styles.description, "text-4xl font-bold"].join(" ")}>Aylık Kamera Satışları</Description>

        <div className="w-full max-w-96 flex flex-col gap-4">
          {sellingsData?.map((item, index) => (
            <div key={index} className="flex justify-between">
              <p className="text-xl">{item?.personel_name}</p>
              <p className="text-2xl font-bold">{item?.camera_count}</p>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default PersonalCameraSellings;
