import {
  Header,
  Container,
  Description,
  Title,
  BuyTicketUpperBox,
  Footer,
  AuthBox,
  AddBusinessesInput,
  AuthButton,
  AddPersonelBox,
  PersonelPermissionsCheckBox,
  AddAgentaBox,
} from "../../../components/index";
import styles from "./style.module.css";
import fakeImage1 from "../../../assets/fakeImages/photo1.png";
import okkologo from "../../../assets/okkoadminlogo.svg";
import userlogo from "../../../assets/userlogowhite.svg";
import menu from "../../../assets/menu.svg";
import logo1 from "../../../assets/okkologo.svg";
import photo1 from "../../../assets/fakeImages/photo4.png";
import { useState, useContext } from "react";
import StoreContext from "../../../context/store";
import { Link } from "react-router-dom";

const AgentaDetails = () => {
  const fakeData = [
    {
      id: 1,
      title: "Zippline - Watergarden",
      description:
        "İstanbul’un merkezinde, ailecek gidebileceğiniz benzersiz bir eğlence parkuru var!Zippline, dev salıncak, macera parkuru, tırmanma duvarı, futbol sahası, atlı karınca, bowling ve trambolin ile WaterGarden; İstanbul eğlence anlayışına yepyeni bir soluk getiriyor.",
      img: fakeImage1,
      workTime: "Hafta içi : 13:00 - 21:00, Hafta sonu: 12:00 - 21:00",
      rules: [
        "Satın alınan biletleri dilediğiniz gün kullanabilirsiniz.",
        " Bilet satın aldıktan sonra rezervasyon nnumaranız veya QR kodu ile giriş yapabilirsiniz.",
        " Randevu için alınan biletler sadece randevu saatinde geçerlidir. İptali için önceden mutlaka haber vermelisiniz.",
      ],
      address: "Kuzguncuk, Gümüşyolu Çk Sk No:4, 34674 Üsküdar/İstanbul",
      phone: "+90 (536) 692 65 93",
    },
  ];

  const fakePersonelData = {
    id: 1,
    username: "yunusemre32",
    name: "Yunus Emre",
    phone: "+905654325543",
    gender: "Erkek",
    date: "Nis 23, 1975",
    permissions: {
      technicalSupport: true,
      salesRepresentative: false,
      reception: false,
      cleaningStaff: false,
    },
    jobrole: "Teknik Destek",
  };
  return (
    <div className="md:grid grid-rows-layoutAuth">
      <Header
        className="bg-primary"
        okkoLogo={okkologo}
        userIcon={userlogo}
        menuIcon={menu}
        profileLink={"/profil"}
        homeLink={"/admin"}
      />
      <Container className={styles.container}>
        <Title className={styles.upperTitle}>Macera, Adrenalin, Mutluluk...</Title>
        <Description className={styles.description}>Eğlencenin merkezine hoş geldin!</Description>

        <BuyTicketUpperBox
          logo={logo1}
          photo={photo1}
          description={fakeData[0].description}
          imageText={fakeData[0].title}
          className="mb-20"
        />

        <Title className={styles.midTitle}>Personel Bilgileri</Title>

        <AuthBox className={styles.authBox1}>
          <Title className={styles.midTitle}>Kişi Bilgileri</Title>

          <AddAgentaBox personelData={fakePersonelData} personelExist={true} />
        </AuthBox>
      </Container>

      <Footer />
    </div>
  );
};

export default AgentaDetails;
