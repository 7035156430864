import styles from "./style.module.css";

const Description = ({ children, className, type = "h3", ...props }) => {
  const Tag = type;

  return (
    <Tag className={[styles.description, className].join(" ")} {...props}>
      {children}
    </Tag>
  );
};

export default Description;
