import styles from "./style.module.css";

const SecurityDataForm = ({ children, data, className, ...props }) => {
  return (
    <div className={[styles.sssbox, className].join(" ")} {...props}>
      <div className="w-full flex flex-col justify-between mt-8 mx-0 px-0">
        {data?.map((item, index) => (
          <div
            key={index}
            className="w-full flex flex-col justify-between mt-2 mx-0"
          >
            <div className="w-full text-xl rounded-full py-2 lg:py-3">
              <span>{index + 1}- </span>
              <span> {item?.beyan}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SecurityDataForm;
